'use strict';

// var dialog = require('../dialog');
var selectors = {
    'shopList': '.js-geo-shop-list',
    'shopSwitchBtn': '.js-geo-switch-btn',
    'languageList': '.js-geo-shop-lang-list'
};

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function initializeEvents() {

     $(document).on('click', selectors.shopSwitchBtn, function (e) {
        e.preventDefault();
        var url = $(selectors.shopList).find('option:selected').attr('data-country-url') +
            $(selectors.languageList).find('option:selected').val();

        if (url && url !== '') {
            window.location = url;
        }
    });

    $(document).on('change', selectors.shopList, function (e) {
        e.preventDefault();
        var isGlobal = $(this).find('option:selected').val().toLowerCase() === 'us';

        if (isGlobal) {
            $(selectors.languageList).prop('disabled', 'disabled');
            $(selectors.languageList).find('option:selected').removeAttr('selected');
            $(selectors.languageList).find('option[value="en"]').attr('selected', true);
        } else {
            $(selectors.languageList).prop('disabled', false);
        }
    });

}

exports.init = function () {
    initializeEvents();
};