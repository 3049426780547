'use strict';

var ajax = require('../../ajax'),
	formPrepare = require('./formPrepare'),
	progress = require('../../progress'),
	tooltip = require('../../tooltip'),
	util = require('../../util'),
	dialog = require('../../dialog'),
	TPromise = require('promise'),
	account = require('../account');

var shippingMethods;
/**
 * @function
 * @description Initializes gift message box, if shipment is gift
 */
function giftMessageBox() {
	// show gift message box, if shipment is gift
	$('.gift-message-text').toggleClass('hidden', $('input[name$="_shippingAddress_isGift"]:checked').val() !== 'true');

	// limit number of new lines based on site preference, remove extra from the beginning
	var newLineRE = new RegExp(/[\r\n\v]/g);
	var maxNewLineCount = SitePreferences.NEWLINESLIMIT;
	if (maxNewLineCount && maxNewLineCount > 0) {
		$('.gift-message-text textarea').on('change, keydown', function () {
			var text = $(this).val(),
			newLinesCount = text.match(newLineRE); // array or null
			if (newLinesCount && newLinesCount.length > maxNewLineCount) {
				var match = newLineRE.exec(text), count = 0,
				removeNumber = newLinesCount.length - maxNewLineCount;
				while (match && removeNumber > 0) {
					match = newLineRE.exec(text);
					removeNumber--;
				}
				$(this).val(text.substring(0, match.index).replace(newLineRE, ' ') + text.substring(match.index));
			}
		});
	} else if (maxNewLineCount == 0) {
		$('.gift-message-text textarea').on('change, keydown', function () {
			$(this).val($(this).val().replace(newLineRE, ' '));
		});
	}
}

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary() {
	var $summary = $('#secondary.summary');
	// indicate progress
	progress.show($summary);

	// load the updated summary area
	$summary.load(Urls.summaryRefreshURL + '?step=3', function () {
		// hide edit shipping method link
		$summary.fadeIn('fast');
		$summary.find('.checkout-mini-cart .minishipment .header a').hide();
		$summary.find('.order-totals-table .order-shipping .label a').hide();
	});
}

/**
 * @function
 * @description Helper method which constructs a URL for an AJAX request using the
 * entered address information as URL request parameters.
 */
function getShippingMethodURL(url, extraParams) {
	var $form = $('.address');
	var params = {
		address1: $form.find('input[name$="_address1"]').val(),
		address2: $form.find('input[name$="_address2"]').val(),
		countryCode: $form.find('select[id$="_country"]').val(),
		stateCode: $form.find('select[id$="_stateShipping"]').val(),
		postalCode: $form.find('input[name$="_postal"]').val(),
		city: $form.find('input[name$="_city"]').val(),
		shipmentId: $form.find('input[name="shipmentId"]').val()
	};
	return util.appendParamsToUrl(url, $.extend(params, extraParams));
}

/**
 * @function
 * @description selects a shipping method for the default shipment and updates the summary section on the right hand side
 * @param
 */
function selectShippingMethod(shippingMethodID, shipmentId, shippingCost) {
	// nothing entered
	if (!shippingMethodID) {
		return;
	}
	// attempt to set shipping method
	var url = getShippingMethodURL(Urls.selectShippingMethodsList, {shippingMethodID: shippingMethodID, shipmentID: shipmentId, shippingCost: shippingCost});
	ajax.getJson({
		url: url,
		callback: function (data) {
			updateSummary();
			if (!data || !data.shippingMethodID) {
				window.alert('Couldn\'t select shipping method.');
				return false;
			}
			// display promotion in UI and update the summary section,
			// if some promotions were applied
			$('.shippingpromotions').empty();

			// TODO the for loop below isn't doing anything?
			// if (data.shippingPriceAdjustments && data.shippingPriceAdjustments.length > 0) {
			// 	var len = data.shippingPriceAdjustments.length;
			// 	for (var i=0; i < len; i++) {
			// 		var spa = data.shippingPriceAdjustments[i];
			// 	}
			// }
		}
	});
}

function checkEmail($input) {
	var url = Urls.checkEmailURL;
	ajax.getJson({
		url: url,
		data: {'email': $input.val(), 'format': 'json'},
		callback: function (data) {
			if (data) {
				dialog.open({
					url: Urls.checkEmailPopupURL,
					options: {
						title: Resources.CHECK_EMAIL_POPUP_TITLE,
						open: function () {
							$input.val('');
							$('.premium-membership-required-removal').on('click', 'button.close-dialog', function (e) {
								e.preventDefault();
								$('.ui-dialog-content').dialog('close');
							});
						}
					}
				});
			}
		}
	});
}

/**
 * @function
 * @description Make an AJAX request to the server to retrieve the list of applicable shipping methods
 * based on the merchandise in the cart and the currently entered shipping address
 * (the address may be only partially entered).  If the list of applicable shipping methods
 * has changed because new address information has been entered, then issue another AJAX
 * request which updates the currently selected shipping method (if needed) and also updates
 * the UI.
 */
function updateShippingMethodList() {
	var $shippingMethodList = $('#shipping-method-list');
	if (!$shippingMethodList || $shippingMethodList.length === 0) { return; }
	var url = getShippingMethodURL(Urls.shippingMethodsJSON);

	ajax.getJson({
		url: url,
		callback: function (data) {
			if (data === null) {
				return false;
			}
			if (!data) {
				window.alert('Couldn\'t get list of applicable shipping methods.');
				return false;
			}
			if (shippingMethods && shippingMethods.toString() === data.toString()) {
				//if FEDEX is enabled, the list might have changed (FedEx rates)
				if (!SitePreferences.FEDEX_ENABLED) {
					// No need to update the UI.  The list has not changed.
					return true;
				}
			}

			// We need to update the UI.  The list has changed.
			// Cache the array of returned shipping methods.
			shippingMethods = data;
			// indicate progress
			progress.show($shippingMethodList);

			// load the shipping method form
			var smlUrl = getShippingMethodURL(Urls.shippingMethodsList);
			$shippingMethodList.load(smlUrl, function () {
				$shippingMethodList.fadeIn('fast');
				// rebind the radio buttons onclick function to a handler.
				$shippingMethodList.find('[name$="_shippingMethodID"]').click(function () {
					selectShippingMethod($(this).val(), $(this).data('shipmentId'), $(this).data('shippingCost'));
				});
				//if nothing is selected in the shipping methods select the first one
				if ($shippingMethodList.find('.input-radio:checked').length === 0) {
					$shippingMethodList.find('.input-radio:first').prop('checked', 'checked').triggerHandler('click');
				}


				// update the summary
				updateSummary();
				progress.hide();
				tooltip.init();
			});
		}
	});
}

/**
 * @function
 * @description Initialize typeahead.js and bloodhound.js for Preferred Store
 */
function initializePreferredStoreTypeahead() {
	if (!SitePreferences.PREFERRED_STORE_CHECKOUT) {
		return;
	}

	var stores = new Bloodhound({
		datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
		queryTokenizer: Bloodhound.tokenizers.whitespace,
		remote: {
			url: Urls.searchPreferredStores + '?q=%QUERY',
			wildcard: '%QUERY',
			transform: function (response) {
				return response.suggestions;
			}
		}
	});

	$('#preferred-store .typeahead').typeahead(null, {
		name: 'best-pictures',
		display: function (suggestion) {
			return suggestion.storeLabel + ' (' + suggestion.storeCity + ', ' + suggestion.storeState + ')';
		},
		minLength: 3,
		highlight: true,
		hint: true,
		source: stores
	}).on('typeahead:select', function (e, suggestion) {
		$('#typeahead-selected').val(suggestion.storeID);
	});
}
function sendOrderForm(uuid, email) {
	var url = Urls.wedgeWorksEmailOrderForm;
	return TPromise.resolve($.ajax({
		type: 'POST',
		url: Urls.wedgeWorksEmailOrderForm,
		data: {'email': email, 'UUID': uuid}
	}));
}

function applyTooltipAttributes() {
	if ($('#usablenet-footer-sr').length > 0) {
		var tooltipElement = $('a.tooltip');
		tooltipElement.each(function () {
			$(this).attr('role', 'tooltip');
			$(this).attr('tabindex', '0');
		});
	}
}

exports.init = function () {
	formPrepare.init({
		continueSelector: '[name$="shippingAddress_save"]',
		formSelector:'[id$="singleshipping_shippingAddress"]',
		canDisable: false
	});
	$('input[name$="_shippingAddress_isGift"]').on('click', giftMessageBox);
	$('.form-row input').each(function () {
		if (!$(this).hasClass('required')) {
			$(this).attr('placeholder', Resources.OPTIONAL_FIELD);
		}
	});
	$('.form-row textarea').each(function () {
		if ($(this).hasClass('placeholder')) {
			$(this).attr('placeholder', Resources.OPTIONAL_FIELD);
		}
	});
	$('.address').on('change',
		'input[name$="_addressFields_address1"], input[name$="_addressFields_address2"], select[name$="_addressFields_statesShipping_stateShipping"], input[name$="_addressFields_city"], input[name$="_addressFields_postal"]',
		updateShippingMethodList
	);
	$('.checkout-shipping .form-row').each(function () {
		if ($(this).hasClass('label-inline')) {
			$(this).find('input').clone().insertBefore($(this).find('label'));
			$(this).find('.field-wrapper').remove();
			$(this).find('.form-caption').remove();
		}
	});
	$('#dwfrm_singleshipping_shippingAddress_addressFields_address1').parent('.field-wrapper').next('div').addClass('address1');
	$('form.checkout-shipping.address').find('.form-row .tooltip').attr('tabindex', '-1');
	$('#dwfrm_singleshipping_shippingAddress.no-multi-country').find('#dwfrm_singleshipping_shippingAddress_addressFields_country').attr('tabindex', '-1');
	$('.login-box.login-account form div').each(function () {
		if ($(this).hasClass('error-form')) {
			$(this).closest('.login-box.login-account').find('h2').removeClass('close');
			$(this).closest('.login-box.login-account').find('.login-box-content').removeClass('close');
		}
	});
	$('.login-box.login-account h2').click(function () {
		$(this).toggleClass('close');
		$(this).next('.login-box-content').toggleClass('close');
	});
	account.initCartLogin();
	if (!(SitePreferences.ENABLE_SHIPPING_COUNTRY_LIST)) {
        var $countrySelect = $('.checkout-shipping .input-select.country');
        var countryText = $('.checkout-shipping .input-select.country option:selected').text();
        $('<span>' + countryText + '</span>').addClass('selected-country').insertAfter($countrySelect);
        $countrySelect.addClass('visually-hidden');

		$('.checkout-shipping .input-select.country').on('mousedown', function (e) {
			e.preventDefault();
			this.blur();
			window.focus();
		});
	} else {
		$('.checkout-shipping .input-select.country').on('change', function () {
            util.updateState($(this));
			if ($(this).val() !== 'US' && $(this).val() !== 'CA') {
				$('select[name$="_stateShipping"]').parents('.form-row').show();
				$('select[name$="_stateCA"]').parents('.form-row').hide();
				$('select[name$="_stateShipping"]').val('OTHER');
			} else {
				if ($(this).val() === 'US') {
					$('select[name$="_stateShipping"]').parents('.form-row').show();
					$('select[name$="_stateCA"]').parents('.form-row').hide();
				} else {
					//CANADA
					$('select[name$="_stateShipping"]').parents('.form-row').hide();
					$('select[name$="_stateCA"]').parents('.form-row').show();
				}
			}
		});
		$('.checkout-shipping .input-select.country').trigger('change');
	}

	if (!SitePreferences.ENABLE_MILITARY_BASE_SHIPPING || SitePreferences.IS_MYJOYS_IN_CART) {
		$('select[name$="_stateShipping"]').find('option[value="AA"]').remove();
		$('select[name$="_stateShipping"]').find('option[value="AE"]').remove();
		$('select[name$="_stateShipping"]').find('option[value="AP"]').remove();
	}
	if (!SitePreferences.APO_FPO) {
		$('.checkout-shipping .form-field-tooltip').hide();
	}

    if ($('.continue-btn button').hasClass('wedge')) {
        $('form.checkout-shipping').on('submit', function () {
            if ($('.checkout-shipping .input-select.country').val() != 'US') {
                dialog.open({
					url: Urls.wedgeWorksInternational,
					options: {
						open: function () {
						}
					}
				});
                return false;
            }
        });
    }

	var sendAlert = true;

	$('#international-intercept').on('submit', 'form[name$="_internationalintercept"]', function () {
		if (sendAlert && $('button[name$="_jumpToBilling"]').length > 0) {
			return confirm('If you continue, all WedgeWorks products will be removed from your cart. Would you like to continue?');
		}
	});

	$('#international-intercept').on('click', '#email-form button', function () {
		var numProducts = $('input[name="UUID"]').length;
		for (var i = 0; i < numProducts; i++) {
			sendOrderForm($('input[name="UUID"]').eq(i).val(), $('#ofrmEmailAddress').val());
		}
		$('#international-intercept #email-order-forms .response').text('Your order forms have been emailed.');
		sendAlert = false;
	});

	applyTooltipAttributes();

	$(document).on('keydown', function (e) {
		if (e.which == 9) {
			applyTooltipAttributes();
		}
	});

	$('.phone').mask('(999) 999-9999');
	giftMessageBox();
	updateShippingMethodList();
	initializePreferredStoreTypeahead();
	util.birthdayInit();
};

exports.updateShippingMethodList = updateShippingMethodList;
