'use strict'

var ajax = require('./ajax'),
	progress = require('./progress'),
	util = require('./util'),
	_ = require('lodash'),
	billing = require('./pages/checkout/billing');

/**
 * @function
 * @description Initializes open invoice events
 */
function initializeEvents() {
    var $continueBtn = $('button[name$="_submitAddress"]');

    $continueBtn.on('click', function (e) {
        e.preventDefault();
        if (!$(this).closest('form').valid()) {
            return;
        }
        progress.show($('#main'));
        var url = util.appendParamsToUrl(Urls.verifyAddress, getAddressParams());
        ajax.getJson({
            url: url,
            callback: function (data) {
                if (!data || !data.success) {
                    updatePaymentMethods(false);
                } else if (data.success) {
                    if (data.location) {
                        window.location.href = data.location;
                    } else {
                        openInvoiceCheck(getAddressParams());
                    }
                }
            }
        });
    });
}

/**
 * @function
 * @description Checks if the provided form has empty required fields
 */
function hasFormEmptyRequired(form) {
	var requiredValues = $('.required', form).find(':input').filter(':visible').map(function () {
		return $(this).val();
	});
	return _(requiredValues).includes('');
};

/**
 * @function
 * @description Returns address parameters
 * @param {Object} address parameters object
 */
function getAddressParams() {
	return {
        'firstName': $('input[name$="_firstName"]').val(),
        'lastName': $('input[name$="_lastName"]').val(),
        'address1': $('input[name$="_address1"]').val(),
        'address2': $('input[name$="_address2"]').val(),
        'birthday': $('input[name$="_birthday"]').val(),
        'city': $('input[name$="_city"]').val(),
        'postal': $('input[name$="_postal"]').val(),
        'country': $('select[name$="_country"]').val(),
        'phone': $('input[name$="_phone"]').val(),
        'format': 'ajax'
    };
}

/**
 * @function
 * @description Checks if the Open Invoice payment method is available
 * @param {Object} address billing address
 */
function openInvoiceCheck(address) {
	var url = util.appendParamsToUrl(Urls.openInvoiceCheck, address);
    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data || !data.success) {
                updatePaymentMethods(false);
            }
            if (data.success) {
                updatePaymentMethods(data.openInvoiceAvailable);
            }
        }
    });
}

/**
 * @function
 * @description Updates the payment methods section with available payment methods
 * @param {Boolean} openInvoiceAvailable indicates if the Open Invoice payment method should be available
 */
function updatePaymentMethods(openInvoiceAvailable) {
	var url = util.appendParamToURL(Urls.updatePaymentMethods, 'openInvoiceAvailable', openInvoiceAvailable);
    ajax.load({
        url: url,
        callback: function (data) {
            if (data) {
                $('div.payment-methods').html(data);
                $('.form-row.form-row-button').removeClass('close');
                updateBillingAddress();
            }
        }
    });
}

/**
 * @function
 * @description Updates the billing address section
 */
function updateBillingAddress() {
	var url = Urls.updateBillingAddress;
    ajax.load({
        url: url,
        callback: function (data) {
            if (data) {
                $('.fieldset.billing-address-static').remove();
                $('.fieldset.address').replaceWith(data);
                billing.init();
                var url = Urls.updateMiniBillingInfo;
                ajax.load({
                    url: url,
                    callback: function (data) {
                        if (data) {
                            $('.mini-billing-address').replaceWith(data);
                        }
                    }
                });
            }
        }
    });
}

exports.init = function () {
    initializeEvents();
};