'use strict';

var registerProductDetails = {
    init: function () {
        $('.remove-registered-product').on('click', function (e) {
            e.preventDefault();

            $('.success-form').hide();
            $('.error-form').hide();
            var $this = $(this);

            $.ajax({
                dataType: 'html',
                url: $this.attr('href'),
                success: function () {
                    $this.closest('ul').prev().remove();
                    $this.closest('ul').remove();
                    $('.success-form').show();
                    if ($('.remove-registered-product').length === 0) {
                        $('.error-form').show();
                    }
                }
            });
        });
    }
};

module.exports = registerProductDetails;