'use strict';
var util = require('../../util'),
	ajax = require('../../ajax');
/**
 * @function
 * @description Melissa service call for list of address
 */
function getAddressAutocomplete() {
	window.globalTimeout = null;
	var $form = $('.address');
	var address1 = $form.find('input[name$="_address1"]').val();
	var country = $form.find('select[name$="_country"]').val();
	if (address1.length < 4) {
		$('.melissa-address').remove();
		return;
	}
	var url = Urls.findAddresForAutocomplete;
	var address1 = $.trim(address1);
	var urlParam = address1.replace(/\s/g, '+');
	util.appendParamsToUrl(url);
	ajax.getJson({
		url: url,
		data: {address1: urlParam, country: country},
		callback: function (data) {
			if (data) {
				$('.address .melissa-address').remove();
				if (data.response && Array.isArray(data.response)) {
					$('.address input[name$="_address1"]').after('<div class="melissa-address"><ul class="melissa-address-list"></ul></div>');
					$.each(data.response, function () {
						$('.address .melissa-address-list').append(
							'<li class="melissa-address-list-item"'
							+ 'data-address-street="' + this.address1 + '"'
							+ 'data-address-city="' + this.city + '"'
							+ 'data-address-state="' + this.state + '"'
							+ 'data-address-country="' + this.country + '"'
							+ 'data-address-postalcode="' + this.postalCode + '">' + this.addressName + '</li>'
						);
					});
				}
			}
			if (!data) {
				console.log('Couldn\'t get list of address');
				return false;
			}
		}
	});
}
exports.init = function init () {
	if (!SitePreferences.MELISSA_ENABLED) {
		return;
	}

	window.melissaAddressEditTimeout = null;
	$(document).on('keyup', '.address input[name$="_address1"]', function () {
		if (window.melissaAddressEditTimeout != null) {
			clearTimeout(window.melissaAddressEditTimeout);
		}
		window.melissaAddressEditTimeout = setTimeout(function () {
			window.melissaAddressEditTimeout = null;
			getAddressAutocomplete();
		}, 500);
	});
	$(document).on('click touch', '.melissa-address-list .melissa-address-list-item', function () {
		var stateValue = '';
		if ($('.address [name$="_state"]').length > 0) {
			stateValue = $('.address [name$="_state"]').val();
		} else if ($('.address [name$="_stateShipping"]').length > 0) {
			stateValue = $('.address [name$="_stateShipping"]').val();
		}
		var needUpdate = $('.address input[name$="_address1"]').val() != $(this).data('address-street')
		|| $('.address input[name$="_city"]').val() != $(this).data('address-city')
		|| $('.address input[name$="_postal"]').val() != $(this).data('address-postalcode')
		|| stateValue != $(this).data('address-state');

		$('.address input[name$="_address1"]').val($(this).data('address-street'));
		$('.address input[name$="_city"]').val($(this).data('address-city'));
		$('.address input[name$="_postal"]').val($(this).data('address-postalcode'));
		if ($('.address [name$="_state"]').length > 0) {
			$('.address [name$="_state"]').val($(this).data('address-state'));
		} else if ($('.address [name$="_stateShipping"]').length > 0) {
			$('.address [name$="_stateShipping"]').val($(this).data('address-state'));
		}
		$('.melissa-address').remove();

		if (needUpdate) {
			$('.address input[name$="_postal"]').trigger('change');
		}
	});
	$(document).on('click touch', function (e) {
		var dropdownElement = $('.melissa-address');
		if (!dropdownElement.is(e.target) && dropdownElement.has(e.target).length === 0) {
			dropdownElement.remove();
		}
	});
};
