'use strict';
var util = require('../../util'),
    imagesLoaded = require('imagesloaded');
var homepage = {
    init: function () {
        // jscs:disable
        /***** Get The Stack Cranking *****/
        if ( typeof FJ_Homepage == 'undefined' ) {
            //object for organizing code
            var	FJ_Homepage = {
                breakpoint: util.isMobile() ? $(window).width() + 1 : 769,
                mode: '',

                /**
                 * debounce - Use this like $( window ).resize( canvas.debounce( function(){}, 500 ) );
                 *
                 * @param function 	fn 		Pass in the function to call after the delay
                 * @param integer 	delay 	The time (in miliseconds) to delay the function from running
                 *
                 * @return function 		returns a function which calls passed in function when the delay has run out
                 */
                debounce: function( fn, delay ) {
                    var timer = null;

                    return function() {
                        var context = this,
                            args = arguments;

                        clearTimeout( timer );

                        timer = setTimeout( function() {
                            fn.apply( context, args );
                        }, delay );
                    };
                },

                init: function() {
                    FJ_Homepage.reflowMainSlider();
                    if (window.location.hash) {
                        var hash = window.location.hash;
                        window.location.hash = '';
                        window.location.hash = hash;
                    }
                },

                reflowMainSlider: function() {
                    if ( $( window ).width() >= FJ_Homepage.breakpoint ) {
                        // mobile-to-desktop transition
                        if ( FJ_Homepage.mode !== 'desktop' ) {
                            // Destroy slick (if already created)
                            if ( $( '.fj-main-slider.slick-initialized' ).length > 0 ) {
                                $( '.fj-main-slider.slick-initialized' ).slick( 'unslick' );
                            }

                            // Move content slide inside main-slider
                            $( '.fj-main-slide.main-content' )
                                .remove()
                                .appendTo( '.fj-main-slider' );

                            // Init scrolljacking
                            if ($('.pt_storefront.home').length > 0) {
                                $( '.fj-main-slider' ).fullpage( {
                                    licenseKey: '40A2A936-D5C34F3F-B28E9BC7-35C60BE9',
                                    fitToSection: false,
                                    hybrid: true,
                                    navigation: true,
                                    keyboardScrolling: true,
                                    scrollBar: true,
                                    normalScrollElements: '#home-normal-scroll',
                                    bigSectionsDestination: top,
                                    onLeave: function( index, nextIndex, direction ) {
                                        if ( nextIndex >= $( '.fj-main-slide' ).length ) {
                                            $( '#fp-nav' ).addClass( 'hidden' );
                                        } else {
                                            $( '#fp-nav' ).removeClass( 'hidden' );
                                        }
                                    },
                                    afterRender: function () {
                                        imagesLoaded('.home-bottom-slots').on('always', function () {
                                            $('#home-normal-scroll').css('opacity', '1');
                                            if (window.location.hash) {
                                                var hash = window.location.hash;
                                                if ($('.pt_storefront.home').length && $(hash).length) {
                                                    var scrollPos = $(hash).offset().top - $('.header-banner').height() - $('.header-main').height() ;
                                                    $(window).scrollTop(scrollPos);
                                                }
                                            }
                                        });
                                    }
                                });
                            }
                        }


                        // set desktop mode
                        FJ_Homepage.mode = 'desktop';
                    } else {
                        // desktop-to-mobile transition
                        if ( FJ_Homepage.mode !== 'mobile' ) {

                            // Destroy scrolljacking (if already created)
                            if ( $("html").hasClass("fp-enabled") && $( '.fj-main-slider.fullpage-wrapper' ).length > 0 ) {
                                $.fn.fullpage.destroy( 'all' );
                            }

                            // Move content slide outside main-slider
                            $( '.fj-main-slide.main-content' )
                                .remove()
                                .insertAfter( '.fj-main-slider' );

                            // Init hero slick
                            $('.fj-main-slider').on('init', function () {
                                imagesLoaded('.home-bottom-slots').on('always', function () {
                                    $('#home-normal-scroll').css('opacity', '1');
                                    if (window.location.hash) {
                                        var hash = window.location.hash;
                                        if ($('.pt_storefront.home').length && $(hash).length) {
                                            var scrollPos = $(hash).offset().top - $('.header-banner').height() - $('.header-main').height() ;
                                            $(window).scrollTop(scrollPos);
                                        }
                                    }
                                });
                            });
                            $( '.fj-main-slider' ).slick( {
                                arrows: true,
                                dots: true,
                                infinite: true,
                                slide: '.fj-main-slide',
                                autoplay: SitePreferences.HERO_SLIDER_AUTO_PLAY || false,
                                speed: SitePreferences.HERO_SLIDER_SLIDE_SPEED || 200,
                                autoplaySpeed: SitePreferences.HERO_SLIDER_ROTATION_DELAY || 7000,
                                fade: SitePreferences.HERO_SLIDER_FADE_EFFECT || true,
                                cssEase: 'linear'
                            } );
                        }

                        // set mobile mode
                        FJ_Homepage.mode = 'mobile';
                    }
                }
            };

            $(window).on( 'resize', FJ_Homepage.debounce( function() {
                        FJ_Homepage.reflowMainSlider();
                        FJ_Homepage.breakpoint = util.isMobile() ? $(window).width() + 1 : 769;
                    }, 50 )
                );

            $(document).ready(function() {
                if (location.hash) {
                    setTimeout(function() {
                        window.scrollTo(0, 0);
                        FJ_Homepage.init();
                    }, 1);
                } else {
                    FJ_Homepage.init();
                }
            });
        }
    }
};

module.exports = homepage;
