'use strict';
var carousel = require('./carousel');

/**
 * @private
 * @function
 * @description Addresses timing of carousel init due to Einstein being loaded dynamically after load
 */
function initRecs() {
    var targets = $('*[id^="cq_recomm_slot-"]');
    if (!targets.length) return;
    var config = {attributes: false, childList: true, characterData: false};
    targets.each(function () {
        var observer = new MutationObserver(function () {
			carousel.init();
            observer.disconnect();
        });
        observer.observe($(this)[0], config);
    });
}

exports.init = function () {
    initRecs();
};
