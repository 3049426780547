'use strict';

require('../../touch')(); //touch events

var dialog = require('../../dialog'),
	util = require('../../util');

const r = document.querySelector(':root');
r.style.setProperty('--imagePositionLeft', '0px');


var event360view = function () {
    $('.view-360-button').on('click', function (e) {
        e.preventDefault();
        if ($(this).data('sprite').length) {
            var imageSize = $(this).closest('.product-image-container').find('.primary-image').eq(0).width();
            var $spriteContainer = $('.spritespin.desktop');
            if (!$spriteContainer.hasClass('hidden')) {
                $spriteContainer.addClass('hidden');
            } else {
                $spriteContainer.removeClass('hidden');
                loadSprite360($(this).data('sprite'), imageSize, 'desktop');
            }
        }
    });
};

	/**
 * @description Sets the main image attributes and the href for the surrounding <a> tag
 * @param {Object} atts Object with url, alt, title and hires properties
 */
var setMainImage = function (atts) {
	$('#pdpMain .product-primary-image .slick-current .primary-image').attr({
		src: atts.url,
		alt: atts.alt,
		title: atts.title
	});
	if (!dialog.isActive() && !util.isMobile()) {
		$('#pdpMain .main-image').attr('href', atts.hires);
	}
};

/**
 * @description Replaces the images in the image container, for eg. when a different color was clicked.
 */
var replaceImages = function () {
	var $newImages = $('#update-images'),
		$imageContainer = $('#pdpMain .product-image-container');
	if ($newImages.length === 0) { return; }

	$imageContainer.html($newImages.html());
	$newImages.remove();
};

/**
 * @description Replaces the action images in the action-gallery-section, for eg. when a different color was clicked.
 */
var replaceActionImages = function () {
	var $newImages = $('#update-action-images'),
		$imageContainer = $('.action-gallery-section');
	if ($newImages.length === 0) { return; }

	$imageContainer.html($newImages.html());
	$newImages.remove();
};

var actionImagesInitialize = function () {
	var $actionCarousel = $('.action-gallery .action-gallery-list');
	// do not initialize twice
	if ($actionCarousel.hasClass('slick-slider')) {
		return;
	}
	var $slideItems = $actionCarousel.find('.action-gallery-item').length;
	var $slidesToShow;
	if (window.innerWidth < 880) {
		$slidesToShow = 1;
		$('.progress').addClass('mobile').removeClass('desktop');
	}
	if (window.innerWidth > 879) {
		$slidesToShow = 2;
		$('.progress').addClass('desktop').removeClass('mobile');
	}

	if ($slideItems > 1) {
		$actionCarousel.slick({
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
		});

		$('.action-gallery .action-gallery-list').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
			if (nextSlide >= currentSlide && nextSlide != (slick.slideCount - 1)) {
				$('.action-progress').slider('option', 'value', (currentSlide + 1));
			} else {
				$('.action-progress').slider('option', 'value', nextSlide);
			}
		});
		$('.action-gallery .action-gallery-list').on('afterChange', function (event, slick, currentSlide) {
			if (currentSlide !== $('.action-progress').slider("value")) {
				$('.action-progress').slider('option', 'value', currentSlide);
			}
		});

		function initProgressSlider($slideItems, $slidesToShow) {
			$('.action-progress').slider({
				min: 0,
				max: ($slideItems - $slidesToShow),
				slide: function (event, ui) {
					var slick = $('.action-gallery .action-gallery-list').slick('getSlick');
					var goTo = ui.value * slick.slideCount / $slideItems;
					$('.action-gallery .action-gallery-list').slick('goTo', goTo);
				}
			});
		}

		initProgressSlider($slideItems, $slidesToShow);

		$(window).on('resize', function () {
            $slideItems = $('.action-gallery .action-gallery-list').find('.action-gallery-item:not(.slick-cloned)').length;
			if (window.innerWidth < 880) {
				$slidesToShow = 1;
			} else {
				$slidesToShow = 2;
			}
            initProgressSlider($slideItems, $slidesToShow);
		});
	}
}

var thumbAdjustment = function () {
	var thumbPos = $('.product-image-container').height() - $('.product-primary-image').height() + 16;
	$('.thumbnails-container').css('bottom', thumbPos);
};

var getLeftPosition = function () {
    var parentPos = document.querySelector('.product-primary-image').getBoundingClientRect(),
        childPos = document.querySelector('.regular').getBoundingClientRect(),
        relativePosLeft = childPos.left - parentPos.left + 'px';
    r.style.setProperty('--imagePositionLeft', relativePosLeft);
}

/**
 * @description Creates a carousel out of the thumbnails that are setup for each product
 */
var setupCarousel = function () {
    var $mainImageSlider = $('.regular'),
		$thumbnailsSlider = $('.product-thumbnails .carousel'),
		$thumbSlides = $thumbnailsSlider.find('.thumb'),
		arrows = false;

    $mainImageSlider.on('init', function () {
        getLeftPosition();
    });

    $mainImageSlider.slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
        dots: false,
		infinite: false,
		fade: false
	});


	// Setup the carousel of thumbnails near the main image
	if(util.isMobile()) {
		if($thumbSlides.length > 5) {
			arrows = false;
		}
	} else {
		if($thumbSlides.length > 4) {
			arrows = false;
		}
	}
	$thumbnailsSlider.on('init', function () {
		$('.product-thumbnails .carousel .slick-slide.slick-current').addClass('is-active');
	});

	$thumbnailsSlider.slick({
		vertical: true,
		infinite: false,
		slidesToShow: 5,
		slidesToScroll: 1,
		swipe: true,
		arrows: true,
		dots: false,
		touchMove: true,
		focusOnSelect: false,
		responsive: [
			{
				breakpoint: 880,
				settings: {
					vertical: false,
					slidesToShow: 4,
					arrows: true
				}
			}
		]
	});

    $('.vimeo-modal').on('click', function () {
        //var vidDialog = $(this).data('count');
        //vidDialog = 'vimeo-frame-' + vidDialog;
        //vidDialog.dialog({
            //resizable: false,
            //autoOpen: true,
            //modal: true
        //});
        vimeoDialog();
    });

    function vimeoDialog() {
        $(".vimeo-frame").dialog({
            closeOnEscape: false,
            dialogClass: 'vim-video',
            fluid: true,
            modal: true,
            width: 'auto', // overcomes width:'auto' and maxWidth bug
            maxWidth: 760,
            position: {
                my: "center",
                at: "center",
                of: window
            }
        });
    }

    $mainImageSlider
        .on('beforeChange', function () {
            var $spriteContainer = $('.spritespin.desktop');
            if (!$spriteContainer.hasClass('hidden')) {
                $spriteContainer.addClass('hidden');
            }
        })
        .on('afterChange', function (event, slick, currentSlide) {
            $thumbnailsSlider.slick('slickGoTo', currentSlide);
            var currentNavSlideElem = '.product-thumbnails .carousel .slick-slide[data-slick-index="' + currentSlide + '"]';
            $('.product-thumbnails .carousel .slick-slide.is-active').removeClass('is-active');
            $(currentNavSlideElem).addClass('is-active');
        });

	$thumbnailsSlider.on('click', '.slick-slide', function (event) {
		event.preventDefault();
		var goToSingleSlide = $(this).data('slick-index');
		$mainImageSlider.slick('slickGoTo', goToSingleSlide);
	});

	// Carousel for Mobile
	var $pdpMobileSlider = $('.pdp-mobile-slider');
	$pdpMobileSlider.on('init', function () {
		// refresh on load first image
		$pdpMobileSlider.find('.slick-active img').on('load', function () {
			$('.pdp-mobile-slider').resize();
		});

		// init 360view functionality
		event360view();

		// show slider and hide preloader
		$pdpMobileSlider.removeClass('hide-on-load');
		$('.mobile-slider-loader').hide();
	});

	$pdpMobileSlider.slick({
		lazyload: 'progressive',
		arrows: false,
		dots: true,
		infinite: true,
		touchThreshold: 5,
		appendDots: $('.pdp-mobile-slider-dots')
	});

    event360view();

	var timeout;

	window.addEventListener('resize', function () {
		clearTimeout(timeout);
		timeout = setTimeout(function () {
			$mainImageSlider.slick('refresh');
            $thumbnailsSlider.slick('refresh');
            getLeftPosition();
		}, 200);
	});
};

var loadSprite360 = function (url, imageSize, viewport) {
    var $viewport = (viewport === 'mobile' ? $('.spritespin.mobile') : $('.spritespin.desktop'));
    $viewport.spritespin({
        // Set a single image url as source
        source: url,
        // Define the size of the display.
        width: imageSize,
        height: imageSize,
        // The total number of frames to show
        frames: SitePreferences.SPRITE_360_FRAMES,
        // The number of frames in one row of the sprite sheet
        framesX: SitePreferences.SPRITE_360_FRAMES_X,
        // Time in ms between updates. 40 is exactly 25 FPS
        frameTime: SitePreferences.SPRITE_360_FRAMETIME,
        // Auto-rotate on load
        animate: SitePreferences.SPRITE_360_ANIMATE,
        sense: -1
    });
};
/**
 * @description by default, this function sets up zoom and event handler for thumbnail click
 **/
module.exports = function () {
	setupCarousel();
	if (dialog.isActive() || util.isMobile()) {
		$('#pdpMain .main-image').removeAttr('href');
	}

	//prevent finger scroll for swipeup/down
    $('#pdpMain').on('touchstart, click', '.product-primary-image a:not(.plus-image-link)', function (e) {e.preventDefault();});

    $('#pdpMain').on('click', '.model-specs-cta, .model-specs', function () {
		if (window.innerWidth < 768) {
			$('.model-specs').toggleClass('show-tooltip');
		}
	});

	$('#pdpMain').on('click', '.primary-image', function () {
        var $zoomContent = $('#js-zoom-content');
        $('body').addClass('fixedPosition');
        $zoomContent.dialog({
            dialogClass: 'zoom-image-dialog',
            modal: true,
            draggable: false,
            resizable: false,
            width: $(window).width(),
            height: $(window).height(),
            left: 0,
            top: 0,
            open: function () {
                $('#js-zoom-content').removeClass('hidden');
            },
            close: function () {
				$('#js-zoom-content').addClass('hidden');
                $(this).dialog('destroy');
                $('body').removeClass('fixedPosition');
            }
        });
    });

};

module.exports.setMainImage = setMainImage;
module.exports.replaceImages = replaceImages;
module.exports.replaceActionImages = replaceActionImages;
module.exports.setupCarousel = setupCarousel;
module.exports.actionImagesInitialize = actionImagesInitialize;
