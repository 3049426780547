'use strict';

module.exports.init = function () {
    $(document).on('change', '#reason, .js-subcategory', function () {
        var value = [];

        value.push($('#reason').val());

        if ($('.js-subreason__select:visible').length) {
            value.push($('.js-subreason__select:visible').val());
        }

        $('#subject').val(value.join(' | '));
    });

    $('#reason').on('change', function () {
        var $subcategory = $('.js-subcategory');
        var selectedCategory = $(this).val();
        $subcategory.hide();
        $subcategory.find('.js-subreason__select').attr('disabled', 'disabled');

        var $subreason;

        switch (selectedCategory) {
            case 'Warranty/Registration': {
                $subreason = $('#footjoy-warranty-registration');
                break;
            }
            case 'Product Care': {
                $subreason = $('#footjoy-product-care');
                break;
            }
            case 'Product Questions': {
                $subreason = $('#footjoy-product-questions');
                break;
            }
            case 'Existing Order': {
                $subreason = $('#footjoy-existing-order');
                break;
            }
            case 'Return': {
                $subreason = $('#footjoy-return');
                break;
            }
            case 'Website issues': {
                $subreason = $('#footjoy-website-issues');
                break;
            }
            case 'Fitting': {
                $subreason = $('#footjoy-fitting');
                break;
            }
            case 'Company information': {
                $subreason = $('#footjoy-company-information');
                break;
            }
            case 'Account': {
                $subreason = $('#footjoy-account');
                break;
            }
            default: {
                break;
            }
        }

        if ($subreason) {
            $subreason.show();
            $subreason.find('.js-subreason__select').removeAttr('disabled');
        }
    });

    $(document).on('submit', '.contact-us-form', function (e) {
        $('.recaptcha-custom-error-message').hide();
        $('.g-recaptcha').parent().removeClass('recaptcha-custom-error');

        var response = grecaptcha.getResponse();
        if (response.length === 0) {
            e.preventDefault();
            $('.recaptcha-custom-error-message').show();
            $('.g-recaptcha').parent().addClass('recaptcha-custom-error');
        }
    });

    window.onRecaptchaSuccess = function (response) {
        $('.recaptcha-custom-error-message').hide();
        $('.g-recaptcha').parent().removeClass('recaptcha-custom-error');
    };
}
