'use strict';
var productTile = require('../product-tile');
exports.init = function () {
	//top of homepage carousel
	$('#homepage-slides').slick({
		dots: true,
		autoplay: SitePreferences.HERO_SLIDER_AUTO_PLAY || false,
		autoplaySpeed: SitePreferences.HERO_SLIDER_ROTATION_DELAY || 7000,
		fade: SitePreferences.HERO_SLIDER_FADE_EFFECT || false,
		cssEase: 'linear',
		speed: SitePreferences.HERO_SLIDER_SLIDE_SPEED || 300
	});
	//playerCarousel at bottom of homepage
	$('#playerCarousel').slick({
		slidesToScroll: 2,
		slidesToShow: 5,
		swipeToSlide: true,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3
				}
			}
		]
	});
	// product content slot horizonal-carousels
	// added to window for CQuotient, self-executing for fallback
	(window.horizontalCarouselInit = function () {
		var $hCarousel = $('.horizontal-carousel');
		// do not initialize twice
		if ($hCarousel.hasClass('slick-slider')) {
			return;
		}
		$hCarousel.slick({
			slidesToScroll: 2,
			slidesToShow: 4,
			swipeToSlide: true,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2
					}
				}
			]
		});
		productTile.showMoreSwatch();
	})();
	//traditional/athletic/spikeless/casual tabs on homepage
	$('.tabSelector a').on('click', function (e) {
		e.preventDefault();
		if ($(this).hasClass('selected')) {
			return;
		}
		//clear current
		$('.tabSelector a.selected').removeClass('selected');
		$('.tabContent').removeClass('selected');
		$(this).addClass('selected');
		var $ct = $($(this).attr('href'));
		$ct.addClass('selected');

		if (!$ct.find('.leftCarousel ul').hasClass('slick-slider')) {
			$ct.find('.leftCarousel ul').slick({
				slidesToShow: 1
			});
		}
	});
	//carousels within the tabs
	$('#traditional .leftCarousel ul').slick({
		slidesToShow: 1
	});
	var myjoysTickerInfo = {
		tickerName: SitePreferences.TICKERNAME,
		customize_url: Urls.customize,
		ws_url: Urls.myJoysEndpoint,
		imageWidth: 300,
		callback: function () {
			$('.myjoys-ticker > ul').slick({
				autoplay: false,
				autoplaySpeed: 1000,
				infinite: true,
				slidesToShow: 3,
				slidesToScroll: 1,
				responsive: [{
					breakpoint: 768,
					settings: {
						slidesToShow: 1
					}
				}]
			});
			//hack to force slides to scroll to 2.  not sure why the init setting doesn't work for this caro.
			//$('.myjoys-ticker > ul').slick('slickSetOption', 'slidesToScroll', 2);
		}
	};
	productTile.showMoreSwatch();
	productTile.swatchImageCkick();
};
