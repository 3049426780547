'use strict';

var addProductToCart = require('./product/addToCart'),
	page = require('../page'),
    account = require('./account'),
	util = require('../util');

exports.init = function () {
	addProductToCart();
    account.init();
	$('#editAddress').on('change', function () {
		page.redirect(util.appendParamToURL(Urls.wishlistAddress, 'AddressID', $(this).val()));
	});

	//add js logic to remove the , from the qty feild to pass regex expression on client side
	$('.option-quantity-desired input').on('focusout', function () {
		$(this).val($(this).val().replace(',', ''));
	});
	$('.login-box-content form .form-row').each(function () {
		if ($(this).hasClass('label-inline')) {
			$(this).find('input').clone().insertBefore($(this).find('label'));
			$(this).find('.field-wrapper').remove();
			$(this).find('.form-caption').remove();
		}
	});
};
