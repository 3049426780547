'use strict';

var page = require('../../page'),
util = require('../../util');

const myFJDesignsKey = 'myFJDesigns';

/**
Saves custom FJ designs to local storage and handles expiration.
@param {Object} configObject - Configuration object containing design details.
@param {string} configObject.custom_instance_id - Custom instance ID of the design.
@param {string} configObject.originalMasterId - Original master ID of the design.
@param {string} configObject.original_instance_id - Original instance ID of the design (if updating an existing design).
@param {string} configObject.myJoysImageUrl0 - URL of the design image.
@param {number} maxDays - Number of days the designs should be kept in local storage.
@returns {void}
@description This function saves custom FJ designs to local storage, and checks for expiration of existing designs.
If the designs have expired, they are removed from storage. If a design is new, it is added to the storage. If a design
is being updated, the old design is removed and the new design is added.
*/
function saveMyFJDesignsToLocalStorage(configObject, maxDays) {
	const myFJDesignsMaxDays = parseInt(maxDays, 10);
	const myFJDesigns = JSON.parse(window.localStorage.getItem(myFJDesignsKey)) || {lastUpdated: null, designs: {}};

	// Check if myFJDesigns is expired
	const currentDate = new Date();
	const lastUpdated = new Date(myFJDesigns.lastUpdated);
	const expirationDate = new Date(lastUpdated.getTime() + (myFJDesignsMaxDays * 24 * 60 * 60 * 1000));

	if (currentDate > expirationDate) {
		myFJDesigns.designs = {};
		myFJDesigns.lastUpdated = currentDate.toISOString();
	} else {
		myFJDesigns.lastUpdated = currentDate.toISOString();
	}

	// Check if this is a new design or an update to an existing design
	const custom_instance_id = configObject.custom_instance_id;
	const originalMasterId = configObject.pdpMasterProduct || configObject.originalMasterId;
	const originalInstanceId = configObject.originalInstanceId;
	const myJoysImageUrl = configObject.myJoysImageUrl0 || '';
	const skuDesigns = myFJDesigns.designs[originalMasterId] || [];
	const newDesignObj = {instance: custom_instance_id, originalMasterId: originalMasterId, myJoysImageUrl: myJoysImageUrl}

	if (!originalInstanceId) {
		// This is a new design
		if (!skuDesigns.some(function (design) { return 'instance' in design && design.instance === custom_instance_id})) {
			skuDesigns.push(newDesignObj);
		}
	} else {
		// This is an update to an existing design
		const existingDesignIndex = skuDesigns.findIndex(function (design) { return design.instance === originalInstanceId});

		if (existingDesignIndex !== -1) {
			skuDesigns.splice(existingDesignIndex, 1);
		}

		skuDesigns.push(newDesignObj);
	}

	myFJDesigns.designs[originalMasterId] = skuDesigns;

	window.localStorage.setItem(myFJDesignsKey, JSON.stringify(myFJDesigns));
}

/**
Renders the FJ designs saved in local storage for a given master ID.
@param {string} masterID - The master ID of the product to render FJ designs for.
@returns {void}
**/
function renderSwatchesFromLocalStorage(masterID) {
	const myFJDesigns = JSON.parse(window.localStorage.getItem(myFJDesignsKey)) || {lastUpdated: null, designs: {}};
	// Find the swatch element container
	const designSwatches = $('.pdp-my-designs');
	const swatchContainer = designSwatches.length ? designSwatches.find('.swatches') : null;

	const customProductDesigns = myFJDesigns.designs[masterID] || [];

	if (customProductDesigns.length === 0 || !swatchContainer) {
		designSwatches.addClass('visually-hidden');
		return;
	} else {
		designSwatches.removeClass('visually-hidden');
	}

	// Add new swatches based on the saved designs
	swatchContainer.empty();
	customProductDesigns.forEach(function (design) {
		const li = document.createElement('li');
		const span = document.createElement('span');
		const img = document.createElement('img');

		li.classList.add('custom-design-swatch');
		li.setAttribute('data-master', masterID);
		li.setAttribute('data-instance', design.instance);
		img.classList.add('custom-design-swatch-img');
		img.setAttribute('alt', design.instance);
		img.setAttribute('src', design.myJoysImageUrl);
		span.setAttribute('role', 'button');

		span.appendChild(img);
		li.appendChild(span);
		swatchContainer.append(li);
	});
}

// Append params to redirect URL for Saved Designs
function appendParamToURL(url, name, value) {
	var separator = url.indexOf('?') !== -1 ? '&' : '?';
	return url + separator + name + '=' + encodeURIComponent(value);
}

$('body').on('customize:saveMyFJDesigns', function (event, data) {
	saveMyFJDesignsToLocalStorage(data.instance, data.myJoysSavedDesignMaxDays);
});

/**
* @description Handler to handle the customize event
*/
var customize = function (e) {
	e.preventDefault();
	var $el = $(e.target).closest('#design-your-own-swatch').length ? $(e.target) : $('#design-your-own-swatch span');
	if ($(e.target).closest('[id^="design-your-blank-swatch"]').length) {
		$el = $(e.target).closest('[id^="design-your-blank-swatch"]').find('span');
	}
	var prodId = $el.data('prodId');
	var standardProdId = $el.data('standardProdId');
	var url = util.appendParamToURL(Urls.customize, 'prodId', prodId);
	url = util.appendParamToURL(url, 'standardProdId', standardProdId);
	var myJoysVendorKey = $el.data('customizerVendor');
	var myJoysCustomizerDCAPIKey = $el.data('customizerKey');
	var myJoysCustomizerDCEnvironment = $el.data('customizerEnv');
	var myJoysCustomizerDCdisableCaching = $el.data('customizerCache');
	var myJoysCustomizerDCCartURL = $el.data('customizerCart');
	var myJoysCustomizerDCWishlistURL = $el.data('customizerWishlist');
	var customizerType = $el.data('customizerType');
	var myJoysSavedDesignMaxDays = $el.attr('data-saved-designs-max-days');
	var myJoysSavedDesignInstance = ($(e.currentTarget).attr('data-instance'));
	var selectBothSize = $('#chkDifferentSize').is(":checked");
	var myJoysWidth = $('.select-width').find('li.selected').length ? $('.select-width').find('li.selected').text().trim().toUpperCase() : '';
	var myJoysSize = $('.select-size, .select-sizeW').find('li.selected').length ? $('.select-size, .select-sizeW').find('li.selected').data('size').toString().trim() : '';

	if (myJoysWidth == '' || myJoysWidth == null) {
		myJoysWidth = undefined;
	}

	switch (myJoysWidth) {
		case "NARROW":
		myJoysWidth = "N"
		break;
		case "MEDIUM":
		myJoysWidth = "M"
		break;
		case "WIDE":
		myJoysWidth = "W"
		break;
		case "EXTRA WIDE":
		myJoysWidth = "XW"
		break;
		default:
		myJoysWidth = undefined
		break;
	}

	if (myJoysSize == '' || myJoysSize == null) {
		myJoysSize = undefined;
	} else {
		myJoysSize = (myJoysSize * 10) + '';
		if (myJoysSize.length == 2) {
			myJoysSize = '0' + myJoysSize;
		}
	}

    if (customizerType == 'DC') {
        CustomizerWidget.createWidget({
            apiKey: myJoysCustomizerDCAPIKey,
            products: prodId,
            environment: myJoysCustomizerDCEnvironment,
            vendor: myJoysVendorKey,
			package: '',
			originalMasterId: standardProdId,
			instance: myJoysSavedDesignInstance || '',
			disableCaching: myJoysCustomizerDCdisableCaching,
			cartServiceType: 'JSONP',
			cartUrl: myJoysCustomizerDCCartURL,
			wishlistUrl: myJoysCustomizerDCWishlistURL,
			widthLeft: myJoysWidth,
			widthRight: myJoysWidth,
			sizeLeft: myJoysSize,
			sizeRight: myJoysSize,
			preferDifferentSizes: selectBothSize,
			onSaveDesign: function (instance) {
				$('body').trigger('customize:saveMyFJDesigns', {
					instance: instance,
					myJoysSavedDesignMaxDays: myJoysSavedDesignMaxDays
				});
				var url = appendParamToURL(Urls.getProductUrl, 'pid', instance.pdpMasterProduct || instance.originalMasterId);
				location.href = url;
			}
		}).then(function (instance) {
			// create new URL and append a new parameter
			var urlSearchParams = new URLSearchParams(window.location.search);
			urlSearchParams.set("customizeropen", "true");
			// add customizerOpen parameter to existing parameters
			var existingParams = urlSearchParams.toString();
			var newParams = existingParams ? "?" + existingParams : "";
			// update pushstate with new URL
			var newUrl = window.location.pathname + newParams;
			var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
			if (isSafari) {
				sessionStorage.setItem("reloadUrl", newUrl);
            }
			history.pushState(null, "", newUrl);
			// capture the back button event and close the widget
			$(window).on('popstate', function (e) {
				window.location.reload();
			});
		});
	} else {
		page.redirect(url);
	}
};

$(window).bind("pageshow", function (event) {
	var reloadUrl = sessionStorage.getItem("reloadUrl");
	var historyTraversal = event.persisted || (typeof window.performance !== "undefined" && window.performance.navigation.type === 2);

    if (historyTraversal && reloadUrl) {
		location.href = reloadUrl;
		sessionStorage.removeItem("reloadUrl");
    }
});

/**
* @description Handler to handle the add to wishlist event
*/
var addToWishlist = function () {
	if ($('.add-to-cart').hasClass('disabled') || $('.add-to-cart').is(':disabled')) {
		var $unselectedVariants = $('.must-select:not(.selected)');

		if ($unselectedVariants.length > 0) {
			$unselectedVariants.addClass('not-selected');
			// adding margin for error
			for (var i = 0; i < $unselectedVariants.length; i++) {
				$($unselectedVariants[i]).parents('.custom-select-field').first().addClass('has-error');
			}
			$unselectedVariants.first().find('.header').focus();
		}

		return false;
	} else {
		return true;
	}
};

/**
* @description Handler to handle the manage design click event
*/
var handleManageDesignsClick = function (e) {
	var $this = $(e.target);
	var $myDesigns = $this.closest('.pdp-my-designs');
	var $myDesignsSave = $myDesigns.find('.js-custom-designs-save');
	var $swatches = $myDesigns.find('.custom-design-swatch');

	// On click of each swatches , add class name delete-candidate to the swatch element, only one swatch can be selected at a time
	$swatches.on('click', function (e) {
		e.stopImmediatePropagation();

		if ($(this).hasClass('delete-candidate')) {
			$(this).removeClass('delete-candidate');
		} else {
			$(this).addClass('delete-candidate');
		}
	});

	$myDesigns.addClass('manage-active');

	$myDesignsSave.on('click', function () {
		// Find swatch element by class name delete-candidate , remove it from DOM and remove it from the local storage myFJDesigns property as well
		var $deleteCandidate = $myDesigns.find('.delete-candidate');

		if ($deleteCandidate.length > 0) {
			var myFJDesigns = JSON.parse(localStorage.getItem('myFJDesigns'));
			var masterID = $($deleteCandidate[0]).data('master');

			$deleteCandidate.each(function () {
				var $el = $(this);
				var instance = $el.data('instance').toString();
				const existingDesignIndex = myFJDesigns.designs[masterID].findIndex(function (design) { return design.instance === instance});

				if (existingDesignIndex !== -1) {
					myFJDesigns.designs[masterID].splice(existingDesignIndex, 1);
					if (myFJDesigns.designs[masterID].length === 0) {
						delete myFJDesigns.designs[masterID];
					}
				}
			});

			const currentDate = new Date();
			myFJDesigns.lastUpdated = currentDate.toISOString();

			window.localStorage.setItem(myFJDesignsKey, JSON.stringify(myFJDesigns));
			renderSwatchesFromLocalStorage(masterID);
		}

		$myDesigns.removeClass('manage-active');
	});
};

/**
* @function
* @description Binds the click event to a given target for the customize handling
* @param {Element} target The target on which a customize event-handler will be set
*/
module.exports = function (target) {
	$('#design-your-own').click(function () {
		$('#design-your-own-swatch').click();
	});

	renderSwatchesFromLocalStorage($('#design-your-own-swatch span').attr('data-standard-prod-id'));

	$('body').on('click', '.js-custom-designs-manage', handleManageDesignsClick);
	$('body').on('click', '.custom-design-swatch', customize);

	$('.add-to-wishlist').on('click', addToWishlist);

	if (target) {
		target.on('click', '#design-your-own-swatch, [id^="design-your-blank-swatch"]', customize);
	} else {
		$('#design-your-own-swatch, [id^="design-your-blank-swatch"]').on('click', customize);
	}
};

exports.addToWishList = addToWishlist;
