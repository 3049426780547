'use strict';

/**
 * @description Launch the members only login/register modal
 */
module.exports = function () {
	var $membersOnlyModal = $('#product-content').find('.members-only-modal');
	var $selectedSwatch = $('.product-variations').find('.swatches .selected .swatchanchor');

	if ($membersOnlyModal.length == 0) return;

	$membersOnlyModal.dialog({
		height: 'auto',
		modal: true,
		overlay: {
			opacity: 0.5,
			background: 'black'
		},
		dialogClass: 'members-only-dialog',
		draggable: false,
		resizable: false,
		width: '775',
		position: {
			my: 'center',
			at: '250',
			of: window,
			collision: 'flipfit'
		},
		close: function () {
			$membersOnlyModal.dialog('destroy');
		}
	});

	$membersOnlyModal.find('.members-only-account').accordion({
		active: $membersOnlyModal.find('.account-register.active').length
	});

	if ($selectedSwatch.length > 0) {
		$membersOnlyModal.find('input[name="returnUrl"]').val($selectedSwatch.data('product-url'));
	}
};
