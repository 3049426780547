'use strict';

var addToCart = require('../product/addToCart'),
	imagery = require('./imagery'),
	controls = require('./controls');

/**
 * @description Initialize event handlers on product detail page
 */
function init() {
	addToCart();
	controls.init();
	imagery.init(removeModalBarrier);
}

function removeModalBarrier () {
	$('.modal-barrier').addClass('done-loading').fadeOut({
		duration: 500,
		always: function () {
			$(this).remove();
		}
	});
}

module.exports = {
	init: init
};
