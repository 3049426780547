'use strict';
var util = require('./util'),
    page = require('./page'),
    ajax = require('./ajax');
/**
 * @function
 * @description Initializes the embroidery scripts
 */

function checkEmbroideryStatus () {
    if (!$('#customizedConfiguration').length) {
        $('#add-to-cart').attr('disabled', 'disabled');
    } else {
        $('#add-to-cart').removeAttr('disabled');
    }
}

var embroideryInit = function () {
    var embEnabled;

    if ($('#embroideryFilteringError').length) {
        $('#embroideryFilteringError').hide();
    }

    // Init embroidery option in modal with existing of ProductLineItem on PDP in "Edit" mode after loading PDP only
    if (window.IsEmbroideryUpdate) {
        var styleOption = $('.embroidery-options input.emb-style:checked').val();
        var threadColor = $('.option-color select#embroiderycolor').val();
        var text = $('.option-text input#embroiderytext').val();
        var text2 = $('.option-text input#embroiderytext2').val();

        if (styleOption && threadColor && (text || text2)) {
            $('#customizedConfiguration').remove();
            $('#pdpMain').prepend('<div id="customizedConfiguration" style="display:none;visibility:hidden" />');
            $('#customizedConfiguration').attr('styleOption', styleOption);
            $('#customizedConfiguration').attr('threadColor', threadColor);
            $('#customizedConfiguration').attr('text', text);
            $('#customizedConfiguration').attr('text2', text2);

            if ($('#embroiderystyleoptioninput').length > 0) {
                $('#embroiderystyleoptioninput').val(styleOption);
            }
            if ($('#embroiderythreadcolorinput').length > 0) {
                $('#embroiderythreadcolorinput').val(threadColor);
            }
            if ($('#embroiderytextinput').length > 0) {
                $('#embroiderytextinput').val(text);
            }
            if ($('#embroiderytext2input').length > 0) {
                $('#embroiderytext2input').val(text2);
            }

            $('#editEmbroidery').show();
            $('#personalize').addClass('checkedAddEmbroidery');

            var $swatchClone = $('.embroidery-image').clone();
            if ($swatchClone) {
                $swatchClone.appendTo('#swatch-preview');
                if (styleOption = 'diamond') {
                    $('#swatch-preview, .product-col-2').addClass('diamond-wrap');
                } else {
                    $('#swatch-preview, .product-col-2').removeClass('diamond-wrap');
                }
                if ($("#embroiderytext2").val() != '') {
                    $('#swatch-preview').addClass('embroidery-2lines');
                }
                $('.product-col-2').addClass('has-embroidery');
                $('.emb-overlay.emb-selected, #swatch-preview').show();
            }
        }

        window.IsEmbroideryUpdate = false;
    }

    if ($('#personalize').length) {
        var embEnabled = 1;
        var maxCharLen = $('input[name="embroidery-style"]:checked').data('characterlimit');
        $('#embroiderytext').attr('maxlength', maxCharLen);
        $('#embroiderytext2').attr('maxlength', maxCharLen);
    }

    if ($('.pdp-promo-container').val().trim() == '') {
        $('.pdp-promo-container').addClass('no-promos');
    }

    if ($('#customizedConfiguration').length) {
        $('#personalize').prop('checked', true);
    } else {
        $('#personalize').prop('checked', false);
    }

	if (embEnabled == 1) {
		var embType = "";
		var embText = $("#embroiderytext").val();
        var embText2 = $("#embroiderytext2").val();

		// Get the embroidery type
		embType = $("input[name='embroidery-style']:checked").val();
        $('#embBlankError, #embCharError, #embRegError, #embCharError2, #embRegError2').hide();
        if (embType != '') {
            embType = embType.toLowerCase();
        }

        if (embType != 'diamond') {
            $('.emb-max1').hide();
            $('.emb-max2').show();
        } else {
            $('.max1-second, .emb-max2:first-of-type, #embroiderytext2').hide();
        }

        if (embText2) {
            $('.embroidery-image').addClass('emb-2lines');
        }

		// Get thread color
		var embColor = $('#embroiderycolor option:selected').text();

        if (embColor != '') {
			embColor = embColor.toLowerCase();
        }

		// Change the colors
		var color = 'FFFFFF';
		var colorFolder = '/White/';

		switch (embColor) {
			case "white":
				color = "FFFFFF";
				colorFolder = '/White/';
			break;

			case "black":
				color = "000000";
				colorFolder = '/Black/';
			break;

			case "pink":
				color = "fca5ff";
				colorFolder = '/Pink/';
			break;

			case "cinnamon":
				color = "542d24";
				colorFolder = '/Cinnamon/';
			break;

			case "red":
				color = "be0f34";
				colorFolder = '/Red/';
			break;

			case "grey":
				color = "333f48";
				colorFolder = '/Gray/';
			break;

			case "blue":
				color = "3f51ba";
				colorFolder = '/Blue/';
			break;

			case "gold":
				color = "e6ae4e";
				colorFolder = '/GOLD/';
			break;

			case "copper":
				color = "d56900";
				colorFolder = '/Copper/';
			break;

			case "bronze":
				color = "763a00";
				colorFolder = '/Bronze/';
			break;

			case "silver":
				color = "d0c4c5";
				colorFolder = '/Silver/';
			break;
		}

		// Update all colors
		$(".embarial, .embarial2").css('color', '#' + color);
		$(".embscript, .embscript2").css('color', '#' + color);
		$(".embdiamond").css('color', '#' + color);

		// Hide font displays
		$(".embarial, .embscript, .embdiamond, .embarial2, .embscript2").hide();

		// Update the text in each
		$(".embarial").html(embText);
		$(".embscript").html(embText);

		$(".embarial2").html(embText2);
		$(".embscript2").html(embText2);

        // Show the appropriate embroidery in the selected color
        $('.embarial, .embscript, .embdiamond, .embarial2, .embscript2').removeClass('emb-selected');

		switch (embType) {
			case "script":
				$(".embscript").show().addClass('emb-selected');
                if (embText2) {
                    $(".embscript2").show().addClass('emb-selected');
                }
			break;

			case "diamond":
				if (embText.length == 3) {
					// Split up the text
					var initialFirst = embText.charAt(0);
					var initialMiddle = embText.charAt(1);
					var initialLast = embText.charAt(2);
                    var colorDir = $('.embdiamond').data('imagedir');
					initialFirst = initialFirst.toUpperCase();
					initialMiddle = initialMiddle.toUpperCase();
					initialLast = initialLast.toUpperCase();

					// Load the appropriate color/script
					var diamondBorder = colorDir + '/diamond' + colorFolder + '1-Diamond.png';
					var diamond1 = colorDir + colorFolder + colorFolder.replace(/\//g, '') + '_' + initialFirst + '1.png';
					var diamond2 = colorDir + colorFolder + colorFolder.replace(/\//g, '') + '_' + initialMiddle + '2.png';
					var diamond3 = colorDir + colorFolder + colorFolder.replace(/\//g, '') + '_' + initialLast + '3.png';

					// Load images
					// diamondBorder = '<img src="/embDiamond' + diamondBorder + '" alt="" style="position: absolute; margin-left: -36px; margin-top: -10px;" />';
                    diamondBorder = "<div style='background: url(\"" + diamondBorder + "\") no-repeat; width: 275px; height: 167px; margin: 0 auto; text-align: center;' >";
					// var diamondBorder = '<div class="emb-diamondbg">';
					diamond1 = '<img style="position: relative;" src="' + diamond1 + '" alt="" />';
					diamond2 = '<img style="position: relative;" src="' + diamond2 + '" alt="" />';
					diamond3 = '<img style="position: relative;" src="' + diamond3 + '" alt="" />';

					var diamondEnd = '</div>';

                    if ($('#embCharError').length) {
                        $('#embCharError').hide();
                    }

                    if ($('#embCharError2').length) {
                        $('#embCharError2').hide();
                    }

					// Update contents
					$(".embdiamond").html(diamondBorder + diamond1 + diamond2 + diamond3 + diamondEnd);
					$(".embdiamond").show().addClass('emb-selected');
				} else {
                    if (!$('.product-col-2').hasClass('has-embroidery')) {
                        $(".embdiamond").html('<br>');
                        $(".embdiamond").show().addClass('emb-selected');
                    }
				}

			break;

			case "block":
				$(".embarial").show().addClass('emb-selected');
                if (embText2) {
                    $(".embarial2").show().addClass('emb-selected');
                }
			break;
		}
	}
};

var profanityCheck = function () {
    var embTypeA = $("input[name='embroidery-style']:checked").val();
    var url = Urls.CGCustomizerValidateText;
    var params = {
        embroideryInputText: $('.option-text input#embroiderytext').val(),
        embroideryInputText2: $('.option-text input#embroiderytext2').val()
    };
    url = util.appendParamsToUrl(url, params);
    ajax.getJson({
        url: url,
        async: false,
        callback: function (data) {
            if (!data) {
                return false;
            }
            if (!data.success) {
                $('#embroideryFilteringError').show();
                return false;
            }

            embroideryInit();

            var styleOption = $('.embroidery-options input.emb-style:checked').val();
            var threadColor = $('.option-color select#embroiderycolor').val();
            var text = $('.option-text input#embroiderytext').val();
            var text2 = $('.option-text input#embroiderytext2').val();

            if (embTypeA == 'diamond') {
                text = $('.option-text input#embroiderytext').val().toUpperCase();
            }

            $('#customizedConfiguration').remove();
            $('#pdpMain').prepend('<div id="customizedConfiguration" style="display:none;visibility:hidden" />');
            $('#customizedConfiguration').attr('styleOption', styleOption ? styleOption : '');
            $('#customizedConfiguration').attr('threadColor', threadColor ? threadColor : '');
            $('#customizedConfiguration').attr('text', text ? text : '');
            $('#customizedConfiguration').attr('text2', text2 ? text2 : '');

            if ($('#embroiderystyleoptioninput').length > 0) {
                var embroiderystyleoption =  $('#customizedConfiguration').attr('styleOption');
                $('#embroiderystyleoptioninput').val(embroiderystyleoption);
            }
            if ($('#embroiderythreadcolorinput').length > 0) {
                var embroiderythreadcolor =  $('#customizedConfiguration').attr('threadColor');
                $('#embroiderythreadcolorinput').val(embroiderythreadcolor);
            }
            if ($('#embroiderytextinput').length > 0) {
                var embroiderytext =  $('#customizedConfiguration').attr('text');
                $('#embroiderytextinput').val(embroiderytext);
            }
            if ($('#embroiderytext2input').length > 0) {
                var embroiderytext2 =  $('#customizedConfiguration').attr('text2');
                $('#embroiderytext2input').val(embroiderytext2);
            }

            $('#editEmbroidery').show();
            $('#personalize').addClass('checkedAddEmbroidery');
        }
    });
};

var embroideryActions = function () {
    $('#embroiderytext, #embroiderytext2').off('keyup');
    $('#embroiderytext, #embroiderytext2').on('keyup', function () {
        profanityCheck();
    });

    $('#embroiderytext').on('click', function () {
        $('#embBlankError, #embCharError, #embRegError, #embCharError2, #embRegError2').hide();
    });

    $('.style-rads #diamond').on('click', function () {
        $('#embroiderytext, #embroiderytext2').val('');
        $('.emb-max2, #embroiderytext2').hide();
        $('.emb-max1').show();
    });

    $('.emb-style').on('click', function () {
        var maxChar = $(this).data('characterlimit');
        embroideryInit();
        $('#embroiderytext').attr('maxlength', maxChar);
    });

    $('.style-rads #script, .style-rads #block').on('click', function () {
        $('.emb-max1').hide();
        $('.emb-max2').show();
        $('#embroiderytext2').show();
    });

    $('#embroiderycolor').off('change');
    $('#embroiderycolor').on('change', function () {
        embroideryInit();
    });

    $(document).off('click', '.embroidery-save-button');
    $(document).on('click', '.embroidery-save-button', function (e) {
        e.preventDefault();
        var valEmb = $('#embroiderytext').val();
        var valEmb2 = $('#embroiderytext2').val();
        var embType = $("input[name='embroidery-style']:checked").val();
        var regexLetDia = /^[a-zA-Z]+$/;
        var regexLet = /^[a-zA-Z0-9!`'"@=#$%^&*()_+{}\[\]:;<>,.?~\\/-]+(?:\s[a-zA-Z0-9!`'"@=#$%^&*()_+{}\[\]:;<>,.?~\\/-]+)*$/;

        if ((valEmb == '' || valEmb == ' ' || valEmb == '  ') && (valEmb2 == '' || valEmb2 == ' ' || valEmb2 == '  ')) {
            $('#embBlankError').show();
            return;
        }

        if ($('#embroideryFilteringError').is(':visible')) {
            return;
        }

        if (embType == 'diamond') {
            if (valEmb.length < 3) {
                $('#embCharError').show();
                return;
            }

            if (!regexLetDia.test(valEmb)) {
                $('#embCharError').show();
                return;
            }
        } else {
            if (!regexLet.test(valEmb) && (valEmb2 == '' || valEmb2 == ' ' || valEmb2 == '  ')) {
                $('#embRegError').show();
                return;
            }
            if ((valEmb == '' || valEmb == ' ' || valEmb == '  ') && !regexLet.test(valEmb2)) {
                $('#embRegError2').show();
                return;
            }
            if ((valEmb == '' || valEmb == ' ' || valEmb == '  ') && regexLet.test(valEmb2)) {
                $('.embroidery-image').addClass('line2-only');
            }
        }

        // updated inputs after saving embroidery values in modal
        var styleOption = $('.embroidery-options input.emb-style:checked').val();
        var threadColor = $('.option-color select#embroiderycolor').val();
        var text = $('.option-text input#embroiderytext').val();
        var text2 = $('.option-text input#embroiderytext2').val();

        $('#customizedConfiguration').remove();
        $('#pdpMain').prepend('<div id="customizedConfiguration" style="display:none;visibility:hidden" />');
        $('#customizedConfiguration').attr('styleOption', styleOption ? styleOption : '');
        $('#customizedConfiguration').attr('threadColor', threadColor ? threadColor : '');
        $('#customizedConfiguration').attr('text', text ? text : '');
        $('#customizedConfiguration').attr('text2', text2 ? text2 : '');

        if ($('#embroiderystyleoptioninput').length > 0) {
            var embroiderystyleoption =  $('#customizedConfiguration').attr('styleOption');
            $('#embroiderystyleoptioninput').val(embroiderystyleoption);
        }
        if ($('#embroiderythreadcolorinput').length > 0) {
            var embroiderythreadcolor =  $('#customizedConfiguration').attr('threadColor');
            $('#embroiderythreadcolorinput').val(embroiderythreadcolor);
        }
        if ($('#embroiderytextinput').length > 0) {
            var embroiderytext =  $('#customizedConfiguration').attr('text');
            $('#embroiderytextinput').val(embroiderytext);
        }
        if ($('#embroiderytext2input').length > 0) {
            var embroiderytext2 =  $('#customizedConfiguration').attr('text2');
            $('#embroiderytext2input').val(embroiderytext2);
        }

        $('#editEmbroidery').show();
        $('#personalize').addClass('checkedAddEmbroidery');

        $('#embRegError, #embCharError, #embRegError2, #embCharError2').hide();

        if (!$('#embroiderytext-error').length) {
            $('.modal-embroidery').dialog('close');
            $('#personalize').prop('checked', true);
        }
    });

    $(document).off('click', '.embroidery-remove-button');
    $(document).on('click', '.embroidery-remove-button', function (e) {
        e.preventDefault();
        if ($('#embroiderystyleoptioninput').length > 0) {
            $('#embroiderystyleoptioninput').val('');
        }
        if ($('#embroiderythreadcolorinput').length > 0) {
            $('#embroiderythreadcolorinput').val('');
        }
        if ($('#embroiderytextinput').length > 0) {
            $('#embroiderytextinput').val('');
        }
        if ($('#embroiderytext2input').length > 0) {
            $('#embroiderytext2input').val('');
        }
        if ($('#swatch-preview').length > 0) {
            $('#swatch-preview').html('').removeClass('diamond-wrap');
        }
        $('#customizedConfiguration').remove();
        $('#editEmbroidery').hide();

        $('#personalize').removeClass('checkedAddEmbroidery');
        $('.modal-embroidery').dialog('close');
    });
};

function preparePayload(editFlow) {
    var payload = JSON.parse($('#widgetObj').val());
    if ($('#uuid').val()) {
        payload.lineItemId = $('#uuid').val();
    }
    payload.initial = {
        embroiderystyleoptioninput: $('#embroiderystyleoptioninput').val(),
        embroiderytextinput: $('#embroiderytextinput').val(),
        embroiderytext2input: $('#embroiderytext2input').val(),
        embroiderythreadcolorinput: $('#embroiderythreadcolorinput').val()
    };
    payload.onCancel = function () {
        $('.cz3').remove();
        $('body').removeClass();
        $('html').removeClass('cz3__overlay-frame');
        if (!editFlow) {
            $('#personalize').prop('checked', false);
        } else {
            $('#thumbnails').find('.thumb.selected').click();
        }
        return true;
    };
    payload.onAddToCart = function (payload, recipe) {
        $('#editEmbroidery').show();
        var imageUrls = [];

        for (var key in payload) {
            if (payload.hasOwnProperty(key) && key.startsWith('imageUrl')) {
                imageUrls.push(payload[key]);
            }
        }
        if ($('#embroiderystyleoptioninput').length > 0) {
            $('.embroidery-options input.emb-style:checked').val(payload.embroiderystyleoptioninput);
        }
        if ($('#embroiderythreadcolorinput').length > 0) {
            $('.option-color select#embroiderycolor').val(payload.embroiderythreadcolorinput);
        }
        if ($('#embroiderytextinput').length > 0) {
            $('.option-text input#embroiderytext').val(payload.embroiderytextinput);
        }
        if ($('#embroiderytext2input').length > 0) {
            $('.option-text input#embroiderytext2').val(payload.embroiderytext2input);
        }
        var swatchTrigger = false;

        // Redirect to cart page after A2C AJAX event is complete
        $(document).ajaxComplete(function(event, xhr, settings) {
            if (swatchTrigger) {
                swatchTrigger = false;
                if ($('#driveCommerceImageUrl').length > 0) {
                    $('#driveCommerceImageUrl').val(imageUrls.join(','));
                }
                if ($('#driveCommerceRecipeId').length > 0) {
                    $('#driveCommerceRecipeId').val(payload.recipeId);
                }
                $('#add-to-cart').trigger('click');
            }
            if (settings.url.includes('Configurator-AddToCart')) {
                page.redirect(Urls.cartShow);
            }
        });
        // Trigger swatch selection only if not currently selected
        if (!$('ul.swatches li[data-customproductid="' + payload.id + '"]').hasClass('selected')) {
            swatchTrigger = true;
            $('ul.swatches li[data-customproductid="' + payload.id + '"] a.swatchanchor').trigger('click');
        }

        window.IsEmbroideryUpdate = true;

        embroideryInit();

        if ($('#driveCommerceImageUrl').length > 0) {
            $('#driveCommerceImageUrl').val(imageUrls.join(','));
        }
        if ($('#driveCommerceRecipeId').length > 0) {
            $('#driveCommerceRecipeId').val(payload.recipeId);
        }
        // Trigger add to cart event
        if (!swatchTrigger) {
            $('#add-to-cart').trigger('click');
        }
    };
    return payload;
}

module.exports.embroideryInit = embroideryInit;
module.exports.embroideryActions = embroideryActions;
module.exports.preparePayload = preparePayload;