'use strict';

var util = require('../../util');
var shipping = require('./shipping');

/**
 * @function
 * @description Selects the first address from the list of addresses
 */
exports.init = function () {
	var $form = $('.address');
	// select address from list
	$('select[name$="_addressList"]', $form).on('change', function () {
		var selected = $(this).children(':selected').first();
		var selectedAddress = $(selected).data('address');
		if (!selectedAddress) { return; }
		util.fillAddressFields(selectedAddress, $form);
		shipping.updateShippingMethodList();
		// re-validate the form
		if ($form.validate().form()) {
			$('button[name$="_shippingAddress_save"]').removeClass('disabled');
		}
		$('.phone').mask('(999) 999-9999');
	});
	shipping.updateShippingMethodList();
	if (util.getViewport() < 1200) {
		$('input[id*="firstName"], input[id*="lastName"], input[id*="city"]').on('keyup', function (event) {
			var inputValue = $(this).val();
			if (inputValue.charAt(0) != inputValue.charAt(0).toUpperCase()) {
				$(this).val(inputValue.charAt(0).toUpperCase() + inputValue.slice(1));
			}
		});
	}
};
