'use strict';

var ajax = require('../../ajax'),
	progress = require('../../progress'),
	util = require('../../util');

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary() {
	var $summary = $('#secondary.summary');
	// indicate progress
	progress.show($summary);

	// load the updated summary area
	$summary.load(Urls.summaryRefreshURL + '?step=3', function () {
		// hide edit shipping method link
		$summary.fadeIn('fast');
		$summary.find('.checkout-mini-cart .minishipment .header a').hide();
		$summary.find('.order-totals-table .order-shipping .label a').hide();
	});
}

/**
 * @function
 * @description Helper method which constructs a URL for an AJAX request using the
 * entered address information as URL request parameters.
 */
function getShippingMethodURL(url, extraParams) {
	var params = {};
	return util.appendParamsToUrl(url, $.extend(params, extraParams));
}

/**
 * @function
 * @description selects a shipping method for the given shipment and updates the summary section on the right hand side
 * @param
 */
function selectMultiShipmentShippingMethod(shipmentID, shippingMethodID, shippingCost, expectedDeliveryDateValue) {
	// nothing entered
	if (!shipmentID || !shippingMethodID) {
		return;
	}
	// attempt to set shipping method
	var url = getShippingMethodURL(Urls.selectShippingMethodsList, {shipmentID: shipmentID, shippingMethodID: shippingMethodID, shippingCost: shippingCost});
    // set expected delivery date
	if (expectedDeliveryDateValue) {
        url = util.appendParamsToUrl(url, {expectedDeliveryDateValue: expectedDeliveryDateValue});
    }
	ajax.getJson({
		url: url,
		callback: function (data) {
			updateSummary();
			if (!data || !data.shippingMethodID) {
				window.alert('Couldn\'t select shipping method.');
				return false;
			}
			// display promotion in UI and update the summary section,
			// if some promotions were applied
			$('.shippingpromotions').empty();

			// TODO the for loop below isn't doing anything?
			// if (data.shippingPriceAdjustments && data.shippingPriceAdjustments.length > 0) {
			// 	var len = data.shippingPriceAdjustments.length;
			// 	for (var i=0; i < len; i++) {
			// 		var spa = data.shippingPriceAdjustments[i];
			// 	}
			// }
		}
	});
}

/**
 * @function
 * @description ripped off this function from shipping.js - updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary() {
	var $summary = $('#secondary.summary');
	// indicate progress
	progress.show($summary);

	// load the updated summary area
	$summary.load(Urls.summaryRefreshURL + '?step=3', function () {
		// hide edit shipping method link
		$summary.fadeIn('fast');
		$summary.find('.checkout-mini-cart .minishipment .header a').hide();
		$summary.find('.order-totals-table .order-shipping .label a').hide();
	});
}

/**
 * @function
 * @description Make an AJAX request to the server to retrieve the list of applicable shipping methods for multi shipment checkout
 * based on the merchandise in the cart and the currently entered shipping address
 * (the address may be only partially entered).  If the list of applicable shipping methods
 * has changed because new address information has been entered, then issue another AJAX
 * request which updates the currently selected shipping method (if needed) and also updates
 * the UI.
 */
function updateMultiShipmentShippingMethodList() {
	var $shippingMethodList = $('#multi-shipment-shipping-method-list');
    var $expectedDeliveryDate = $('select[id$=shippingAddress_expectedDeliveryDate]');

    if ($expectedDeliveryDate.length > 0) {
        $expectedDeliveryDate.on('change', function () {
            var $selectedShipping = $(this).closest('.shipping-method-list').find('[name*="_shippingMethodID"]:checked');
            if ($selectedShipping.length > 0) {
                selectMultiShipmentShippingMethod($selectedShipping.data('shipmentId'), $selectedShipping.val(), $selectedShipping.data('shippingCost'), $(this).val());
            }
        });
    }

	if (!$shippingMethodList || $shippingMethodList.length === 0) { return; }
	$shippingMethodList.find('[name*="_shippingMethodID"]').click(function () {
        var expectedDeliveryDateValue = $(this).closest('.shipping-method-list').find('select[id$=shippingAddress_expectedDeliveryDate]').find(':selected').val();
		selectMultiShipmentShippingMethod($(this).data('shipmentId'), $(this).val(), $(this).data('shippingCost'), expectedDeliveryDateValue);
	});

	if (SitePreferences.FEDEX_ENABLED) {
		updateSummary();
	}
}

exports.init = function () {
	updateMultiShipmentShippingMethodList();
};
