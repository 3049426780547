'use strict';

var _ = require('lodash');

var util = {
	/**
	 * @function
	 * @description initialize parameters in page URL
	 */
    initializeParamsInURL: function () {
        var params = new URLSearchParams(window.location.search);
        if (params && params.has('adyenComponentRedirect')) {
            // remove all params from Adyen Component redirect url
            var state = {
                id: 'adyenComponentRedirect'
            };
            history.pushState(state, '', location.pathname);
        }
    },

	/**
	 * @function
	 * @description remove the parameter and its value from the given url and returns the changed url
	 * @param {String} url the url from which the parameter will be removed
	 * @param {String} name the name of parameter that will be removed from url
	 */
	removeParamFromURL: function (url, name) {
		if (url.indexOf('?') === -1 || url.indexOf(name + '=') === -1) {
			return url;
		}
		var hash;
		var params;
		var domain = url.split('?')[0];
		var paramUrl = url.split('?')[1];
		var newParams = [];
		// if there is a hash at the end, store the hash
		if (paramUrl.indexOf('#') > -1) {
			hash = paramUrl.split('#')[1] || '';
			paramUrl = paramUrl.split('#')[0];
		}
		params = paramUrl.split('&');
		for (var i = 0; i < params.length; i++) {
			// put back param to newParams array if it is not the one to be removed
			if (params[i].split('=')[0] !== name) {
				newParams.push(params[i]);
			}
		}
		return domain + '?' + newParams.join('&') + (hash ? '#' + hash : '');
	},

	/**
	 * @function
	 * @description appends the parameter with the given name and value to the given url and returns the changed url
	 * @param {String} url the url to which the parameter will be added
	 * @param {String} name the name of the parameter
	 * @param {String} value the value of the parameter
	 * @param {Boolean} overwrite replace existing param or not
	 */
	appendParamToURL: function (url, name, value, overwrite) {
		var overwriteParam = (overwrite === true); // must be explicitly true
		// if overwrite == false, quit if the param already exists
		if (url.indexOf(name + '=') !== -1) {
			if (!overwriteParam) {
				return url;
			} else {
				url = this.removeParamFromURL(url, name);
			}
		}
		var separator = url.indexOf('?') !== -1 ? '&' : '?';
		return url + separator + name + '=' + encodeURIComponent(value);
	},

	/**
	 * @function
	 * @description appends the parameters to the given url and returns the changed url
	 * @param {String} url the url to which the parameters will be added
	 * @param {Object} params
	 */
	appendParamsToUrl: function (url, params) {
		var _url = url;
		_.each(params, function (value, name) {
			_url = this.appendParamToURL(_url, name, value);
		}.bind(this));
		return _url;
	},
	/**
	 * @function
	 * @description extract the query string from URL
	 * @param {String} url the url to extra query string from
	 **/
	getQueryString: function (url) {
		var qs;
		if (!_.isString(url)) { return; }
		var a = document.createElement('a');
		a.href = url;
		if (a.search) {
			qs = a.search.substr(1); // remove the leading ?
		}
		return qs;
	},
	/**
	 * @function
	 * @description
	 * @param {String}
	 * @param {String}
	 */
	elementInViewport: function (el, offsetToTop) {
		var top = el.offsetTop,
			left = el.offsetLeft,
			width = el.offsetWidth,
			height = el.offsetHeight;

		while (el.offsetParent) {
			el = el.offsetParent;
			top += el.offsetTop;
			left += el.offsetLeft;
		}

		if (typeof(offsetToTop) !== 'undefined') {
			top -= offsetToTop;
		}

		if (window.pageXOffset !== null) {
			return (
				top < (window.pageYOffset + window.innerHeight) &&
				left < (window.pageXOffset + window.innerWidth) &&
				(top + height) > window.pageYOffset &&
				(left + width) > window.pageXOffset
			);
		}

		if (document.compatMode === 'CSS1Compat') {
			return (
				top < (window.document.documentElement.scrollTop + window.document.documentElement.clientHeight) &&
				left < (window.document.documentElement.scrollLeft + window.document.documentElement.clientWidth) &&
				(top + height) > window.document.documentElement.scrollTop &&
				(left + width) > window.document.documentElement.scrollLeft
			);
		}
	},

	/**
	 * @function
	 * @description Appends the parameter 'format=ajax' to a given path
	 * @param {String} path the relative path
	 */
	ajaxUrl: function (path) {
		return this.appendParamToURL(path, 'format', 'ajax');
	},

	/**
	 * @function
	 * @description
	 * @param {String} url
	 */
	toAbsoluteUrl: function (url) {
		if (url.indexOf('http') !== 0 && url.charAt(0) !== '/') {
			url = '/' + url;
		}
		return url;
	},
	/**
	 * @function
	 * @description Loads css dynamically from given urls
	 * @param {Array} urls Array of urls from which css will be dynamically loaded.
	 */
	loadDynamicCss: function (urls) {
		var i, len = urls.length;
		for (i = 0; i < len; i++) {
			this.loadedCssFiles.push(this.loadCssFile(urls[i]));
		}
	},

	/**
	 * @function
	 * @description Loads css file dynamically from given url
	 * @param {String} url The url from which css file will be dynamically loaded.
	 */
	loadCssFile: function (url) {
		return $('<link/>').appendTo($('head')).attr({
			type: 'text/css',
			rel: 'stylesheet'
		}).attr('href', url); // for i.e. <9, href must be added after link has been appended to head
	},
	// array to keep track of the dynamically loaded CSS files
	loadedCssFiles: [],

	/**
	 * @function
	 * @description Removes all css files which were dynamically loaded
	 */
	clearDynamicCss: function () {
		var i = this.loadedCssFiles.length;
		while (0 > i--) {
			$(this.loadedCssFiles[i]).remove();
		}
		this.loadedCssFiles = [];
	},
	/**
	 * @function
	 * @description Extracts all parameters from a given query string into an object
	 * @param {String} qs The query string from which the parameters will be extracted
	 */
	getQueryStringParams: function (qs) {
		if (!qs || qs.length === 0) { return {}; }
		var params = {},
			unescapedQS = decodeURIComponent(qs);
		// Use the String::replace method to iterate over each
		// name-value pair in the string.
		unescapedQS.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
			function ($0, $1, $2, $3) {
				params[$1] = $3;
			}
		);
		return params;
	},

	fillAddressFields: function (address, $form) {
		for (var field in address) {
			if (field === 'ID' || field === 'UUID' || field === 'key' || field === 'type') { // excluded 'type' because the 'payment method' field is filled
				continue;
			}
			// if the key in address object ends with 'Code', remove that suffix
			// keys that ends with 'Code' are postalCode, stateCode and countryCode
			$form.find('[name$="' + field.replace('Code', '') + '"]').val(address[field]);
			// update the state fields
			if (field === 'countryCode') {
				$form.find('[name$="country"]').trigger('change');
				// retrigger state selection after country has changed
				// this results in duplication of the state code, but is a necessary evil
				// for now because sometimes countryCode comes after stateCode
				if (address[field].toUpperCase() === 'CA') {
					$form.find('[name$="stateCA"]').val(address.stateCode);
				} else {
					$form.find('[name$="state"]').val(address.stateCode);
				}
			}
			if (field === 'stateCode') {
				if (address.countryCode.toUpperCase() === 'CA') {
					$form.find('[name$="stateCA"]').val(address.stateCode);
				} else {
					$form.find('[name$="state"]').val(address.stateCode);
				}
			}
			// catch-all for modified states field name identifiers
			if (field === 'stateCode') {
				$form.find('[name$="stateShipping"], [name$="stateCA"]').val(address[field]);
			}
			// catch-all for modified states field name identifiers
			if (field === 'stateCode') {
				$form.find('[name$="stateShipping"], [name$="stateCA"]').val(address[field]);
			}
		}
	},
	/**
	 * @function
	 * @description Updates the number of the remaining character
	 * based on the character limit in a text area
	 */
	limitCharacters: function () {
		$('form').find('textarea[data-character-limit]').each(function () {
			var characterLimit = $(this).data('character-limit');
			var charCountHtml = String.format(Resources.CHAR_LIMIT_MSG,
				'<span class="char-remain-count">' + characterLimit + '</span>',
				'<span class="char-allowed-count">' + characterLimit + '</span>');
			var charCountContainer = $(this).next('div.char-count');
			if (charCountContainer.length === 0) {
				charCountContainer = $('<div class="char-count"/>').insertAfter($(this));
			}
			charCountContainer.html(charCountHtml);
			// trigger the keydown event so that any existing character data is calculated
			$(this).change();
		});
	},
	/**
	 * @function
	 * @description Binds the onclick-event to a delete button on a given container,
	 * which opens a confirmation box with a given message
	 * @param {String} container The name of element to which the function will be bind
	 * @param {String} message The message the will be shown upon a click
	 */
	setDeleteConfirmation: function (container, message) {
		$(container).on('click', '.delete', function () {
			return window.confirm(message);
		});
	},
	/**
	 * @function
	 * @description Scrolls a browser window to a given x point
	 * @param {String} The x coordinate
	 */
	scrollBrowser: function (xLocation) {
		$('html, body').animate({scrollTop: xLocation}, 500);
	},

	isMobile: function () {
		var mobileAgentHash = ['mobile', 'tablet', 'phone', 'ipad', 'ipod', 'android', 'blackberry', 'windows ce', 'opera mini', 'palm'];
		var	idx = 0;
		var isMobile = false;
		var userAgent = (navigator.userAgent).toLowerCase();

		while (mobileAgentHash[idx] && !isMobile) {
			isMobile = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
			idx++;
		}
		return isMobile;
	},

	/**
	 * @function
	 * @description Check whether a script is loaded, and if not, loads it. Then execute callback function.
	 * @param {Object} script object with path and loaded state
	 * @param {Function} callback function
	 */
	checkScriptLoaded: function (script, callback) {
		if (script.loaded) {
			if (typeof callback === 'function') { callback(); }
		} else {
			var useMin = window.Scripts.useMin,
				path = script.path;
			// Check to see if we are minifing the files
			if (useMin && ! (/\.min\.js$/.test(script.path))) {
				path = script.path.replace(/.js$/g, '.min.js');
			}
			// Loads a script based on the given path
			var loadScript = function (path) {
				// Execute the ajax call on the given script path
				var xhr = $.ajax({
					url: path,
					dataType: 'script',
					cache: true
				});
				// If the Ajax call was successful then perform the callback function if given
				xhr.done(function () {
					if (typeof callback === 'function') { callback(); }
					script.loaded = true;
				});
				// Return the jQuery XHR object
				return xhr;
			};
			// Load the script
			var xhr = loadScript(path);
			// Fall back to the standard path unless the standard path was used and not found
			xhr.fail(function (jqXHR, textStatus, errorThrown) {
				if (path !== script.path && (jqXHR.status === 404 || errorThrown === 'Not Found')) {
					loadScript(script.path);
				}
			});
		}
	},

	/**
	 * @function
	 * @description Returns an URI-Object from a given element with the following properties:<br/>
	 * <p>protocol</p>
	 * <p>host</p>
	 * <p>hostname</p>
	 * <p>port</p>
	 * <p>path</p>
	 * <p>query</p>
	 * <p>queryParams</p>
	 * <p>hash</p>
	 * <p>url</p>
	 * <p>urlWithQuery</p>
	 * @param {Object} o The HTML-Element
	 */
	getUri: function (o) {
		var a;
		if (o.tagName && $(o).attr('href')) {
			a = o;
		} else if (typeof o === 'string') {
			a = document.createElement('a');
			a.href = o;
		} else {
			return null;
		}

		return {
			protocol: a.protocol, // http:
			host: a.host, // www.myexample.com
			hostname: a.hostname, // www.myexample.com'
			port: a.port, // :80
			path: a.pathname, // /sub1/sub2
			query: a.search, // ?param1=val1&param2=val2
			queryParams: a.search.length > 1 ? util.getQueryStringParams(a.search.substr(1)) : {},
			hash: a.hash, // #OU812,5150
			url: a.protocol + '//' + a.host + a.pathname,
			urlWithQuery: a.protocol + '//' + a.host + a.port + a.pathname + a.search
		};
	},
	/**
	 * @function
	 * @description Return the current browser viewport width. Equal to CSS @media (width), which includes scrollbars.
	 */
	getViewport: function () {
		var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
		return w;
	},
	/**
	 * @function
	 * @description Initializes birthday input.
	 */
	birthdayInit: function () {
		$('.birthday').on('keypress', function (e) {
			var keyCode = (e.keyCode ? e.keyCode : e.which);
			var value = $(this).val();
			if ((value.length == 1 || value.length == 4) && keyCode == 47) {
				var currentChar = value.charAt(value.length - 1);
				$(this).val(value.replace(/.$/, '0' + currentChar));
			}
			if ((value.length == 2 || value.length == 5) && keyCode != 47) {
				$(this).val(value + '/');
			}
		});
	},
	/**
	 * @private
	 * @function
	 * @description Animates opacity to 1 sequentially starting with the given $el and every .next() sibling element it finds.
	 */
	recursiveReveal: function thisfunction ($allElements) {
		if ($allElements.length > 0) {
			$allElements.css('opacity', '0');
			var $first = $allElements.first();
			(function recursiveReveal($el) {
				$el.animate({
					opacity: 1
				}, 0, 'linear', function () {
					thisfunction($el.next());
				});
			})($first);
		}
	},
	/**
	 * @function
	 * @description Updates the states options to a given country
	 * @param {String} countrySelect The selected country
	 */
	updateStateOptions: function (form) {
		var $form = $(form),
			$country = $form.find('select[id$="_country"]'),
			country = window.Countries[$country.val()];
		if ($country.length === 0 || !country) {
			return;
		}
		var arrHtml = [],
			$phoneField = $form.find('input[name$="_phone"]'),
			$stateField = $country.data('stateField') ? $country.data('stateField') : $form.find('select[name$="_state"]'),
			$postalField = $country.data('postalField') ? $country.data('postalField') : $form.find('input[name$="_postal"]'),
			$stateLabel = ($stateField.length > 0) ? $form.find('label[for="' + $stateField[0].id + '"] span').not('.required-indicator') : undefined,
			$postalLabel = ($postalField.length > 0) ? $form.find('label[for="' + $postalField[0].id + '"] span').not('.required-indicator') : undefined,
			$postalExample = ($postalField.length > 0) ? $postalField.parents('.form-row').find('div.form-caption') : undefined,
			$phoneExample = ($phoneField.length > 0) ? $phoneField.parents('.form-row').find('div.form-caption') : undefined,
			prevStateValue = $stateField.val();
		// set the label text
		if ($postalLabel) {
			$postalLabel.html(country.postalLabel);
		}
		if ($stateLabel) {
			$stateLabel.html(country.regionLabel);
		} else {
			return;
		}
		if ($postalExample) {
			$postalExample.html(country.postalExample);
		} else {
			$postalExample.html('');
		}
		if ($phoneExample) {
			$phoneExample.html(country.phoneExample);
		} else {
			$phoneExample.html('');
		}
		var s, i = 0;
		for (s in country.regions) {
			arrHtml.push('<option value="' + s + '">' + country.regions[s] + '</option>');
			i++;
		}
		// clone the empty option item and add to stateSelect
		var o1 = $stateField.children().first().clone();
		$stateField.html(arrHtml.join('')).removeAttr('disabled').children().first().before(o1);
		// if a state was selected previously, save that selection
		if (prevStateValue && typeof country.regions[prevStateValue] !== 'undefined') {
			$stateField.val(prevStateValue);
		} else if (i === 1) {
			// if there is only one region, make that region selected
			$stateField[0].selectedIndex = 1;
		} else {
			$stateField[0].selectedIndex = 0;
		}
	},
	/**
	 * @function
	 * @description Updates state dropdown.
	 */
	updateState: function (elem) {
		var $form = elem.closest('form');
		var $stateSelect = $form.find('.input-select.state');

		if ((Resources.CURRENT_SITE_ID == 'footjoy-jp' || Resources.CURRENT_SITE_ID == 'titleist-jp') && $form.hasClass('checkout-shipping')) {
			return;
		}
		if (['footjoy-emea', 'titleist-uk', 'footjoy-au-nz', 'footjoy-jp', 'kjus-eu', 'kjus-uk', 'titleist-jp'].indexOf(Resources.CURRENT_SITE_ID) > -1) {
			if ((($.inArray(elem.val(), Object.keys(window.Countries)) == -1) || (window.Countries[elem.val()].regions == undefined))) {
				var $country = $form.find('select[id$="_country"]'),
					country = window.Countries[$country.val()] ? window.Countries[$country.val()] : window.Countries["OTHER"] ,
					$phoneField = $form.find('input[name$="_phone"]'),
					$postalField = $country.data('postalField') ? $country.data('postalField') : $form.find('input[name$="_postal"]'),
					$phoneExample = ($phoneField.length > 0) ? $phoneField.parents('.form-row').find('div.form-caption') : undefined,
					$postalExample = ($postalField.length > 0) ? $postalField.parents('.form-row').find('div.form-caption') : undefined,
					$postalLabel = ($postalField.length > 0) ? $form.find('label[for="' + $postalField[0].id + '"] span').not('.required-indicator') : undefined;
				if ($postalLabel) {
					$postalLabel.html(country.postalLabel);
				}
				if ($postalExample && country) {
					$postalExample.html(country.postalExample);
				} else {
					$postalExample.html('');
				}
				if ($phoneExample && country) {
					$phoneExample.html(country.phoneExample);
				} else {
					$phoneExample.html('');
				}
				this.toggleTownField($form);
				if ($form.find('select[id$="_country"]').val() !== 'GB'
					&& $form.find('select[id$="_country"]').val() !== 'IE'
					&& $form.find('select[id$="_country"]').val() !== 'AU'
                    && $form.find('select[id$="_country"]').val() !== 'JP') {
					$form.find('.input-text.state').parents('.form-row').show();
					$stateSelect.parents('.form-row').hide();
					$stateSelect.prop('disabled', true);
					$stateSelect.val('');
				}
			} else {
				util.updateStateOptions($form);
				$stateSelect.prop('disabled', false);
				$stateSelect.parents('.form-row').show();
				$form.find('.input-text.state').parents('.form-row').hide();
			}
			if ($form.find('select[id$="_country"]').val() == 'US') {
				$form.find('input[id$="_postal"]').addClass('zip');
				$form.find('input[id$="_postal"]').removeClass('postal');
			} else {
				$form.find('input[id$="_postal"]').removeClass('zip');
				$form.find('input[id$="_postal"]').addClass('postal');
			}
			this.toggleTownField($form);
		} else {
			if (elem.val() != 'US' && elem.val() != 'CA' && elem.val() != 'JP' && elem.val() != 'KR' && elem.val() != 'TH' && elem.val() != 'AU') {
				$stateSelect.parents('.form-row').show();
				$form.find('select[name$="_stateCA"]').parents('.form-row').hide();
				$form.find('select[name$="_stateJP"]').parents('.form-row').hide();
				$form.find('select[name$="_stateKR"]').parents('.form-row').hide();
				$form.find('select[name$="_stateTH"]').parents('.form-row').hide();
				$stateSelect.val('OTHER');
				$stateSelect.prop('disabled', true);
			} else {
				if (elem.val() == 'US' || elem.val() == 'AU') {
					$stateSelect.parents('.form-row').show();
					$form.find('select[name$="_stateCA"]').parents('.form-row').hide();
					$form.find('select[name$="_stateJP"]').parents('.form-row').hide();
					$form.find('select[name$="_stateKR"]').parents('.form-row').hide();
					$form.find('select[name$="_stateTH"]').parents('.form-row').hide();
					$stateSelect.prop('disabled', false);
				} else if (elem.val() == 'JP') {
					$stateSelect.parents('.form-row').hide();
					$form.find('select[name$="_stateCA"]').parents('.form-row').hide();
					$form.find('select[name$="_stateKR"]').parents('.form-row').hide();
					$form.find('select[name$="_stateTH"]').parents('.form-row').hide();
					$form.find('select[name$="_stateJP"]').parents('.form-row').show();
					$stateSelect.prop('disabled', true);
				} else if (elem.val() == 'KR') {
					$stateSelect.parents('.form-row').hide();
					$form.find('select[name$="_stateCA"]').parents('.form-row').hide();
					$form.find('select[name$="_stateJP"]').parents('.form-row').hide();
					$form.find('select[name$="_stateTH"]').parents('.form-row').hide();
					$form.find('select[name$="_stateKR"]').parents('.form-row').show();
					$stateSelect.prop('disabled', true);
				} else if (elem.val() == 'TH') {
					$stateSelect.parents('.form-row').hide();
					$form.find('select[name$="_stateCA"]').parents('.form-row').hide();
					$form.find('select[name$="_stateJP"]').parents('.form-row').hide();
					$form.find('select[name$="_stateKR"]').parents('.form-row').hide();
					$form.find('select[name$="_stateTH"]').parents('.form-row').show();
					$stateSelect.prop('disabled', true);
				} else {
					//CANADA
					$stateSelect.parents('.form-row').hide();
					$form.find('select[name$="_stateTH"]').parents('.form-row').hide();
					$form.find('select[name$="_stateJP"]').parents('.form-row').hide();
					$form.find('select[name$="_stateKR"]').parents('.form-row').hide();
					$form.find('select[name$="_stateCA"]').parents('.form-row').show();
					$stateSelect.prop('disabled', true);
				}
			}
		}
	},
	smartResize: function (callback) {
        var timeout;

        window.addEventListener('resize', function () {
            clearTimeout(timeout);
            timeout = setTimeout(callback, 500);
        });

        return callback;
    },
	toggleTownField:  function ($form) {
		if (Resources.CURRENT_SITE_ID === 'footjoy-jp' || Resources.CURRENT_SITE_ID === 'titleist-jp') {
			if ($form.find('select[id$="_country"]').val() === 'JP') {
				$form.find('input[id$="_town"]').addClass('required').parents('.form-row').addClass('required').show();
			} else {
				$form.find('input[id$="_town"]').val('').parents('.form-row').hide();
			}
		}
	}
};

module.exports = util;
