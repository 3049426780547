'use strict';
exports.init = function () {
	// added to window for CQuotient, self-executing for fallback
	(window.horizontalCarouselInit = function () {
		var $hCarousel = $('.horizontal-carousel');
		// do not initialize twice
		if ($hCarousel.hasClass('slick-slider')) {
			return;
		}
		$hCarousel.slick({
			slidesToScroll: 1,
			slidesToShow: 4,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1
					}
				}
			]
		});
	})();
};
