'use strict';

var imagery = require('./imagery'),
	dialog = require('../../dialog'),
	page = require('../../page'),
	util = require('../../util'),
	imagesLoaded = require('imagesloaded'),
	TPromise = require('promise');

/**
 *
 */
var resources = 'ConfiguratorResources' in window ? window.ConfiguratorResources : {};
var textUpdateDelay = resources.PREF_TEXT_FIELD_DELAY || 1000;
var refreshControlHtmlUrl = resources.URL_REFRESH_CONTROLS;
var premiumMembershipRequiredHtmlUrl = resources.URL_PREMIUM_MEMBERSHIP_REQUIRED;
var updateJsonUrl = resources.URL_UPDATE_JSON;
var clearBallUrl = resources.URL_CLEAR_BALL;
var updateLogoGrid = resources.URL_LOGOGRID;
var $currentTab = $('.configurator-controls').find('.config-tab.selected');
var $currentTabContent = $('.configurator-controls').find('.config-tab-content.open');
var previousLogoOptionID = $('.configurator-controls').find('input[name="logoTypes"][checked]').attr('id');

var controls = {
	init: function () {
		// A means of changing the Cart-AddProduct url on init of this page

		if (!window.hasOwnProperty('Urls')) {
			window.Urls = {};
		}
		window.Urls.addProduct = resources.URL_ADD_PRODUCT;
		// If alert errors exist (even on page load), fire them now.
		launchConfigurationAlerts($('.configurator-controls'));

		switchAriaTab($('.config-master-tabs > li').eq(1).get(0));

		// Tab control
		$('.configurator-controls').on('click', '.config-master-tabs .config-tab:not(.selected)', function () {
			masterTabSwitch(this);
		});

		// Bind event handler to configurator option <a> click
		$('.configurator-controls').on('click', '.configurator-option-select:not(input):not(.selected)', function (e) {
			e.preventDefault();
			updateAndRefreshControls(this, true);
		});

		// Bind event handler to configurator option radio button change
		$('.configurator-controls').on('change', 'input.configurator-option-select:not([checked])', function () {
			updateAndRefreshControls(this, true);
		});

		// Bind event handler to text input blur
		$('.configurator-controls').on('focus', '.configurator-text-inputs input', function () {
			imagery.gotoSlide($(this).data('face'));
		});

		// Bind event handler to text input blur
		$('.configurator-controls').on('blur focusout', '.configurator-text-inputs input', function () {
			if (textFieldHasChanged(this) && validateMessageTextField(this)) {
				clearErrorMessage();
				if (validateCurrentTab()) {
					clearTabAwayError();
				}
				submitTextFieldUpdate(this);
			}
		});

		// Bind event handler to text input change
		$('.configurator-controls').on('keyup input paste mouseup', '.configurator-text-inputs input', function (e) {
			var _this = this;
			var uniqueID = $(_this).data('path');
			if (textFieldHasChanged(_this)) {
				handleCharacterLimit(_this, e);
				delay (function () {
					if (validateMessageTextField(_this)) {
						clearErrorMessage();
						if (validateCurrentTab()) {
							clearTabAwayError();
						}
						submitTextFieldUpdate(_this);
					}
				}, textUpdateDelay, uniqueID);
			}
		});

		// Bind event handler to logo type (radio button) selection
		$('.configurator-controls').on('change', 'input[name="logoTypes"]', function () {
			selectLogoOption($(this));
		});

		// Bind event handler for logo tab selection
		$('.configurator-controls').on('click', '.logo-top-folder', function () {
			if ($(this).hasClass('selected')) {
				return false;
			}
			switchAriaTab(this);
			refreshLogoGrid($(this).closest('.configurator-option-group'));
		});

		//Bind event handler for logo category selection
		$('.configurator-controls').on('change', 'select.logo-categories', function () {
			refreshLogoGrid($(this).closest('.configurator-option-group'));
		});

		//Bind event handler for actual logo selection
		$('.configurator-controls').on('click', '.logo-thumbnail:not(.selected)', function () {
			updateAndRefreshControls(this, true);
		});

		//Bind event handler for click of remove logo icon (x)
		$('.configurator-controls').on('click', '.logo-unselect', function () {
			updateAndRefreshControls(this, true, function () {
				selectLogoOption($('#' + previousLogoOptionID));
			});
		});

		// Bind event handler to configurator quantity change
		$('.configurator-controls').on('change', '#Quantity', function () {
			var json = buildRequestJSONFromElement(this);
			updateGolfBall(updateJsonUrl, json);
		});

		//Bind click event to remove Premium Membership link, if rendered
		$('.configurator-controls').on('click', '.removePremium', removePremiumOptions);

		//Bind click event to clearGolfBall link, if rendered
		$('.configurator-controls').on('click', '#clearGolfBall', function (e) {
			e.preventDefault();
			clearGolfBall(clearBallUrl, page.refresh());
		});

		//Bind click event to attemptRestoreGolfBall link, if rendered
		$('.configurator-controls').on('click', '#attemptRestoreGolfBall', function (e) {
			e.preventDefault();
			// remove the restore param first so we don't add multiple
			window.location.search = util.removeParamFromURL(window.location.search, 'restore') + '&restore=true';
		});

		// [#ASA-151] - My Pro V1 - completion of Ball Model Switch functionality
		// Handles events on moving back and forward by browser history
		// Here happens updating images, logo, product resources like URLs etc.
		window.onpopstate = history.onpushstate = function (e) {
			var pid = e.state.productID,
				el = $('[data-pid="' + pid + '"]').get(0),
				json = buildRequestJSONFromElement(el);
			validateCurrentTab(el);

			updateGolfBall(refreshControlHtmlUrl, json, function (response) {
				$('#configurator-refresh').html(response);
				stickyBall(); //just in case the control panel expanded/collapsed, reset ball sticky position
				var newimages = getNewImageryDataFromHTMLResponse(response);
				var gotoSlide = $(el).data('face') || $(el).closest('.configurator-option-group').data('face');
				imagery.replaceCaroImages(newimages, gotoSlide);
				if (el.name === 'ballModelSelect') {
					imagery.replaceLogo(el);
					updateAllResourceProductURLs(el)
				}
				launchConfigurationAlerts(response);
			});
		};

		//Create sticky div for ball main image on scroll, resize, and fire once on pageload.
		$(window).scroll(stickyBall);
		util.smartResize(function () { stickyBall; });
		stickyBall();
	}
};

/******************* Private Functions ********************/

/**
 * @description Send the AJAX request to update the golf ball.  Response will either be in JSON or HTML, depending on which url was called.
 * @param href {String} the URL to submit the request to
 * @param json {Object} the data object containing any request parameters
 * @param tab {String} (Optional) override which tab to force open on refresh of controls panel. ex: 'messages'
 * @param callback {Function} (Optional) a callback function to execute on AJAX response
 */
var updateGolfBall = function (href, json, tab, callback) {
	if (!json) {
		return false;
	}
	if (typeof tab === 'function' && !callback) { //allow callback to be the 3rd param if no tab is passed
		callback = tab;
		tab = null;
	}
	if (!!tab && typeof json === 'object') {
		json.tab = tab;
	}
	href = href || updateJsonUrl;
	// make the AJAX request
	return TPromise.resolve($.ajax({
		type: 'POST',
		url: href,
		data: json,
		success: function (response) {
			// after AJAX response...
			if (this.dataTypes.indexOf('json') > -1) {
				if (response.success) {
					updateTabsComplete(response.tabsComplete);
					if (response.isValid) {
						enableAddToCart();
					} else {
						disableAddToCart();
					}
					// execute the callback function, if defined
					if (typeof callback === 'function') {
						callback(response);
					}
				} else if (response.error) {
					showErrorMessage(response.error);
				} else {
					page.refresh();
				}
			} else { //assume HTML response
				var $html = $('<div></div>').html(response);
				var $isValid = $html.find('input#golfBallIsValid');
				var $updateError = $html.find('input#updateGolfBallError');
				//HTML response will always update Tabs Complete
				if ($isValid.val() === 'true') {
					enableAddToCart();
				} else {
					disableAddToCart();
				}
				if ($updateError.length) {
					showErrorMessage($updateError.val());
				} else {
					// execute the callback function, if defined
					if (typeof callback === 'function') {
						callback(response);
					}
				}
			}
		},
		fail: function () {
			page.refresh();
		}
	}));
};

/**
 * @description Send the AJAX request to clear the golf ball.
 * @param href {String} the URL to submit the request to
 * @param tab {String} (Optional) override which tab to force open on refresh of controls panel. ex: 'messages'
 * @param callback {Function} (Optional) a callback function to execute on AJAX response
 */
var clearGolfBall = function (href, tab, callback) {
	if (typeof tab === 'function' && !callback) { //allow callback to be the 3rd param if no tab is passed
		callback = tab;
		tab = null;
	}
	// make the AJAX request
	return TPromise.resolve($.ajax({
		type: 'POST',
		url: href,
		success: function (response) {
			// execute the callback function, if defined
			if (typeof callback === 'function') {
				callback(response);
			}
		},
		fail: function () {
			page.refresh();
		}
	}));
};


/**
 * @description Updates the golf ball object, then refreshes the configuration panel with the returned HTML
 * @param el {Element} the html element that changed or was clicked
 * @param updateImages {Boolean} (Optional) replace the main product image with new src's from Scene7
 * @param callback {Function} (Optional) a callback function to execute on AJAX response
 * @return {Boolean} success
 */
var updateAndRefreshControls = function (el, updateImages, callback) {

	//check validity based on classes or element state
	if (!isValidOption(el)) {
		return false;
	}

	//just in case, revalidate tab. this will clear any existing error data so it is not retained after the AJAX refresh
	validateCurrentTab(el);

	//extract the data-path and value from this element, or from its ancestors/children if grouped
	var jsondata = buildRequestJSONFromElement(el);

	//if a ball model was selected, update the refreshControlHtmlUrl with the new product id BEFORE we submit to it
	if (el.name === 'ballModelSelect') {
		updateAllResourceProductURLs(el);
	}

	// execute the AJAX call, expecting HTML response
	updateGolfBall(refreshControlHtmlUrl, jsondata, function (response) {
		// after successful response...
		$('#configurator-refresh').html(response);
		stickyBall(); //just in case the control panel expanded/collapsed, reset ball sticky position
		if (updateImages) {
			var newimages = getNewImageryDataFromHTMLResponse(response);
			var gotoSlide = $(el).data('face') || $(el).closest('.configurator-option-group').data('face');
			imagery.replaceCaroImages(newimages, gotoSlide);
		}

		// If ball model was selected, update the page logo, page URL, and PID for the addToCart button
		if (el.name === 'ballModelSelect') {
			imagery.replaceLogo(el);
			updatePageURL(el);
			updateAddToCartButton(el);
		}

		// If a premium logo option was selected, produce the required dialog
		if ($('.premium-logo-option').length) {
			launchPremiumOptionsDialog();
		}

		launchConfigurationAlerts(response);

		// execute the callback function, if defined
		if (typeof callback === 'function') {
			callback(response);
		}
	});
};


/**
 * Check if the given selection is valid, based on "disabled" states and classes of element and relative elements
 * @param el {Element} The element to check for validity
 * @returns success boolean
 */
var isValidOption = function (el) {
	// If the option is disabled, show error message and return
	if ($(el).hasClass('disabled') || $(el).is(':disabled') || $(el).parents('.disabled').length) {
		showErrorMessage('Invalid choice');
		return false;
	}
	return true;
};


/**
 * @description Build a data object of query string params to append to our AJAX request url by extracting
 * 	data attributes from the given element or its relatives
 * @param el {Element} the html element to extract a data-path and value from
 * @return {Boolean} success
 */
var buildRequestJSONFromElement = function (el) {

	// extract the data-path value from this element, or from an ancestor if this element is grouped
	var paths = findDataPathsForElement(el);

	// extract the "data-setvalue" or "value" attribute val from this element or a selected child element, if grouped
	var newval = findDataValueForElement(el);

	// with data-path and data-setvalue definitions, we can build the JSON object to make the update request
	// this will be merged into the existing GolfBall config object on the backend
	var configUpdateJSON = generateUpdateJSON(paths, newval);

	// a query string param JSON object containing the created JSON, stringified (and encoding for a query string?)
	var jsondata = {newjson: JSON.stringify(configUpdateJSON)};

	// retrieve the submission URL and add 'tab' parameter to JSON obj
	var activeTab = $('.config-master-tabs > li.selected').attr('id').split('-tab')[0];
	if (!!activeTab) {
		jsondata.tab = activeTab;
	}

	// Return the data object to be used in the actual AJAX call "data" param
	return jsondata;
};


/**
 * @description Find the applicable data-path(s) for this element, either on the element itself, or on an ancestor.
 * @param el {Element} the html element to extract a data-path value from (or get from an ancestor, if grouped)
 * @return {String}
 */
var findDataPathsForElement = function (el) {
	var paths = $(el).data('path') || '';
	// if this is part of a group of options, get the group parent
	var $ancestor = $(el).closest('.configurator-option-group');
	if ($ancestor.data('path')) {
		if (paths !== '') {
			paths += ',';
		}
		paths += $ancestor.data('path');
	}
	return paths;
};


/**
 * @description Get the value of the element, or the element group by searching through nested elements
 * @param el {Element} the form element that contains the value
 * @returns {String} the found value
 */
var findDataValueForElement = function (el) {
	// setting a data-value attribute trumps any other method
	var rtn;
	if ($(el).data('setvalue')) {
		rtn = $(el).data('setvalue');
		return rtn;
	}
	// return the jQuery .val() from element with a "value" attribute
	if (!!$(el).val()) {
		rtn = $(el).val();
		if ($(el).is('input[type="text"]')) {
			// This conversion to uppercase must only be done on submit of value, and only performed on the copied var "rtn",
			// because changing the actual .val() of the text field would result in resetting the user's cursor position.
			rtn = rtn.toUpperCase();
		}
		return rtn;
	}
	// look for any nested element with the class "selected", then try to get that element's value
	if ($(el).find('.selected').length) {
		rtn = findDataValueForElement($(el).find('.selected')[0]);
		return rtn;
	}
	// look for any nested option/radio element that is currently selected, then try to get that element's value
	if ($(el).find(':checked').length) {
		rtn = findDataValueForElement($(el).find(':checked')[0]);
		return rtn;
	}
	// fallback: return the text value of the current container, ex: "hello" for <p>hello</p>
	return $(el).text();
};

/**
 * @description Build the JSON object that will be merged into the existing Golf Ball config object on the backend.
 * @param paths {String} The string representation of the JSON path to the object property we wish to update/create. Can contain "=value".
 * 		ex1: 'shapes.square.height'
 * 		ex2: 'shapes.square.height="32ft"'
 * @param newval The new value to set in the last object property found in the path. Can be a String, Number, or even null, I guess.
 * 		ex1: '32ft'
 * 		ex2: 12
 * @returns {Object} the JSON update object
 */
var generateUpdateJSON = function (paths, newval) {
	var pathJSON = {};
	var propertyPaths = typeof paths === 'string' ? paths : null;
	var propertyValue = newval.toString();
	if (!!propertyPaths) {
		//split at comma to determine if multiple values are being set
		paths = propertyPaths.split(',');
		for (var i = 0; i < paths.length; i++) {
			var propertyPath = paths[i].trim();
			var thisPropertyValue = propertyValue;
			if (propertyPath.indexOf('=') > -1) {
				//check if the path contains '=', if so, split out path and property value. this overrides the passed in param for value
				thisPropertyValue = propertyPath.split('=')[1].trim();
				propertyPath = propertyPath.split('=')[0].trim();
			}
			// break this string into an array of property names
			var pathArr = propertyPath.split('.');

			if (pathArr.length > 0) {
				createNestedObject(pathJSON, pathArr, thisPropertyValue);
			}
		}
	}
	return pathJSON;
};

/**
 * @description Compare the current value of an input text with its previous value
 * @param el {Element} the element to check
 * @return {Boolean} true if input value has changed, false if not
 */
var textFieldHasChanged = function (el) {
	//If value has changed...
	if ($(el).data('oldvalue').toString() !== $(el).val().toString()) {
		// Updated stored value in data-oldvalue attribute
		$(el).data('oldvalue', $(el).val());
		return true;
	}
	return false;
};

/**
 * @description Update Golf Ball with the text field's new value
 * @param el {Element} the element containing the new value
 */
var submitTextFieldUpdate = function (el) {
	var json = buildRequestJSONFromElement(el);
	var gotoSlide = $(el).data('face');

	// execute AJAX call, expecting JSON response (because we not don't want a refresh)
	updateGolfBall(updateJsonUrl, json, function (response) {
		// we are not refreshing, but let's at least update the summary with the right text
		updateSummaryMsgText(response.config);
		imagery.replaceCaroImages(response.images, gotoSlide);
	});
};

/**
 * Extract the Golf Ball's new Scene7 imagery from an HTML response, parsing out hidden input fields.
 * @param response {String} the returned html from an AJAX call to be parsed for new Scene7 image urls
 */
var getNewImageryDataFromHTMLResponse = function (html) {
	//extract the new image urls from the html provided
	var $source = $(html);
	var images = [];
	var $hiddenInputs = $source.find('input#scene7url[type="hidden"]');
	$hiddenInputs.each(function () {
		images.push($(this).val());
		//if we need to treat this as an associative array, use the following instead:
		//var key = $(this).attr('name');
		//var val = $(this).val();
		//images[key] = val;
	});
	return images;
};

/**
 * Change handler for logo type radio buttons
 * @param $radio {jQuery} the radio button for the logo option to switch to
 */
var selectLogoOption = function ($radio) {
	var $logoOption = $radio.closest('.configurator-option');
	$logoOption.addClass('selected').siblings('.configurator-option').removeClass('selected');
	$radio.attr('checked', 'checked');
	//remember this newly selected logo option after the controls refresh
	previousLogoOptionID = $radio.attr('id');
	stickyBall(); //as control will have just changed height after collapse/expand, reste ball sticky position
};

/**
 * Refresh the logo grid with logo thumbnails applicable to this Logo Type selection
 * @param el {Element} the .configurator-option-group parent of the element that changed or was clicked
 */
var refreshLogoGrid = function (el) {

	// get the logo subcategory selector
	var $select = $(el).find('.config-tab-content.open .logo-categories');
	// logo type, taken from the selected logoType radio element (either single logo, double logo, or none)
	var type = $(el).closest('.configurator-option-config').siblings('input[name="logoTypes"]').val();
	// which logo grid (of the 3 in the page) to refresh
	var $grid = $(el).find('.logo-grid');

	if ($select.length && !!type && $grid.length) {
		$grid.addClass('loading');
		var href = updateLogoGrid;
		href = util.appendParamsToUrl(href, {'type': type, 'folder': $select.val(), 'selected': $grid.data('selectedlogo')});
		return TPromise.resolve($.ajax({
			type: 'POST',
			url: href,
			success: function (response) {
				if (response) {
					$grid.html(response);
					stickyBall(); //reset ball sticky position
					var imgLoad = imagesLoaded($grid);
					imgLoad.on('always', function () {
						$grid.removeClass('loading');
					});
				} else {
					$grid.removeClass('loading');
					showErrorMessage('Error retreiving this logo category.');
				}
			},
			fail: function () {
				page.refresh();
			}
		}));
	}
};

/**
 * Update the Message 1 and Message 2 lines' text in the bottom summary section
 * @param config {Object} the current configuration data from which to pull the new text values
 */
var updateSummaryMsgText = function (config) {
	$('#summary-playnumber span').text(config.playNumber.numberString);
	// I know these are throwing JS Lint errors, but the naming conventions here are defined in the backend ds file
	$('#summary-msg-N-line1').text(String.format(resources.TEXT_SUMMARY_LINE1, config.messages.message_1.line1.msg));
	$('#summary-msg-N-line2').text(String.format(resources.TEXT_SUMMARY_LINE2, config.messages.message_1.line2.msg));
	if (!!config.messages.message_1.line2.msg) {$('#summary-msg-N-line2').show();} else {$('#summary-msg-N-line2').hide();}
	$('#summary-msg-N-line3').text(String.format(resources.TEXT_SUMMARY_LINE3, config.messages.message_1.line3.msg));
	if (!!config.messages.message_1.line3.msg) {$('#summary-msg-N-line3').show();} else {$('#summary-msg-N-line3').hide();}
	$('#summary-msg-S-line1').text(String.format(resources.TEXT_SUMMARY_LINE1, config.messages.message_2.line1.msg));
	$('#summary-msg-S-line2').text(String.format(resources.TEXT_SUMMARY_LINE2, config.messages.message_2.line2.msg));
	if (!!config.messages.message_2.line2.msg) {$('#summary-msg-S-line2').show();} else {$('#summary-msg-S-line2').hide();}
	$('#summary-msg-S-line3').text(String.format(resources.TEXT_SUMMARY_LINE3, config.messages.message_2.line3.msg));
	if (!!config.messages.message_2.line3.msg) {$('#summary-msg-S-line3').show();} else {$('#summary-msg-S-line3').hide();}
};

/**
 * @description Mark tabs with a green checkmark by adding the class "complete".
 */
var updateTabsComplete = function (list) {
	if (typeof list !== 'object') {
		return false;
	}
	var $allTabs = $('.configurator-controls').find('.config-tab');
	//remove 'complete' class from all tabs
	$allTabs.removeClass('complete');
	//then add to only those that are officially complete, per server-side validation
	$allTabs.each(function () {
		for (var i = 0; i < list.length; i++) {
			if ($(this).is('#' + list[i] + '-tab')) {
				$(this).addClass('complete');
			}
		}
	});
	return true;
};

/**
 * @description Launch the Premium Membership Required alert into a custom lightbox.
 */
var launchPremiumOptionsDialog = function () {
	dialog.open({
		url: premiumMembershipRequiredHtmlUrl,
		options: {
			title: resources.PREMIUM_MEMBERSHIP_REQUIRED_TITLE,
			open: function () {
				$('.premium-membership-required-removal').on('click', 'button.close-dialog', function () {
					$('.ui-dialog-content').dialog('close');
				});
			}
		}
	});
};

/**
 * @description Pop up the Invalid Play Number warning, or any other custom configuration Alert coming from the pdict.
 */
var launchConfigurationAlerts = function (response) {
	var $content = $(response).find('#configuration-alert-to-dialog');
	if ($content.length > 0) {
		// Open alerts into dialog box(es) -
		// an option for displaying backend-delivered alerts (Close buttons should be added to content asset html)
		dialog.open({
			html: $content.html(),
			options: {
				title: $content.attr('title'),
				dialogClass: 'configuration-alert-dialog',
				open: function () {
					$('.configuration-alert-dialog').on('click', 'button.close-dialog', function () {
						$('.ui-dialog-content').dialog('close');
					});
				}
			}
		});
		// Show alerts as red error text -
		// another option to display the error (but the Close button should be removed from the content asset html)
		//showErrorMessage($content.html());
	}
};

/**
 * @description Trigger click event on any selected premium logos' unselect link (x).
 */
var removePremiumOptions = function (e) {
	e.preventDefault();
	var combinedDataPaths = {};
	$('#logos-content .configurator-option.selected .logo-unselect.premium').each(function () {
		var el = $(this);
		var paths = findDataPathsForElement(el);
		var newval = findDataValueForElement(el);
		var dataPathForLogo = generateUpdateJSON(paths, newval);
		// Merge into combinedDataPaths
		$.extend(true, combinedDataPaths, dataPathForLogo);
	});
	var jsondata = {newjson: JSON.stringify(combinedDataPaths)};
	// execute the AJAX call, expecting HTML
	updateGolfBall(refreshControlHtmlUrl, jsondata, 'logos', function (response) {
		$('#configurator-refresh').html(response);
		scrollTo($('#logos-content .configurator-option.selected'));
	});
};

/**
 * @description Fire an AJAX call to a pipeline that will remove the Premium Membership product and return success/fail
 * @param e {Event} the jQuery event, automatically passed in by JQ
 * @returns {JSON} success or fail
 */
var removePremiumFromCart = function (e) {
	e.preventDefault();
	var thislink = this;
	var href = $(thislink).attr('href');
	return TPromise.resolve($.ajax({
		type: 'POST',
		url: href,
		success: function (response) {
			if (response.success) {
				$(thislink).closest('.configurator-summary-premium').hide();
			} else if (response.error) {
				showErrorMessage('Error removing Premium Membership from basket: ' + response.error);
			} else {
				page.refresh();
			}
		},
		fail: function () {
			page.refresh();
		}
	}));
};

/**
 * @description Validate the message text field against existing pattern attribute
 * @return Boolean valid or not
 */
var validateCurrentTab = function (el) {
	var valid = true;
	if ($currentTabContent.is('#ballModel-content')) {
		valid = true;
	}
	if ($currentTabContent.is('#playNumber-content') && $('#playNumber-custom').is(':checked')) {
		valid = validateMessageTextField($('input.playnumber-text'));
	}
	if ($currentTabContent.is('#messages-content')) {
		$('.configurator-option.selected input.msgtxt').each(function () {
			if (!validateMessageTextField(this)) {
				valid = false;
				return false; //this just breaks the loop
			}
		});
		if (!validateAnyMessageTextExists(false, el)) {
			valid = false;
		}
	}
	if ($currentTabContent.is('#logos-content')) {
		switchAriaTab('.logo-top-folder.selected');
		refreshLogoGrid($('.logo-top-folder.selected').closest('.configurator-option-group'));
		valid = true;
	}
	if (valid) {
		clearErrorMessage();
		clearTabAwayError();
	}
	return valid;
};

/**
 * @description Validate the message text field against existing pattern attribute
 * @param el {Element} the text element to validate
 * @return Boolean valid or not
 */
var validateMessageTextField = function (el) {
	var val = $(el).val();
	if ($(el).data('missingerror') && (val === null || val === '')) {
		showErrorMessage($(el).data('missingerror'));
		setTabAwayError($(el).data('missingerror'));
		disableAddToCart();
		return false;
	}
	if (new RegExp($(el).attr('pattern')).test(val) === false) {
		showErrorMessage($(el).data('regexerror') || 'The text you entered was invalid.');
		setTabAwayError($(el).data('regexerror') || 'The text you entered was invalid.');
		disableAddToCart();
		return false;
	}
	return true;
};

/**
 * @description Validate that not all message text fields are empty
 * @param showError {Boolean} Optional - if true, will throw an error message if invalid, otherwise, only sets TabAway error message
 * @param el {Element} Optional - the recently clicked element, in case a radio button was clicked, it wouldn't register as "clicked" yet
 * @return Boolean valid or not
 */
var validateAnyMessageTextExists = function (showError, el) {
	var $msg1texts;
	var $msg2texts;
	var msg1invalid = false;
	var msg2invalid = false;
	var checkedOption;
	var err;

	if (!!el && $(el).length) {
		if ($(el).is('#messageType-single')) {
			checkedOption = 'single';
		} else if ($(el).is('#messageType-double')) {
			checkedOption = 'double';
		} else if ($(el).is('#messageType-none')) {
			checkedOption = 'none';
		}
	} else {
		if ($('#messageType-single').attr('checked')) {
			checkedOption = 'single';
		} else if ($('#messageType-double').attr('checked')) {
			checkedOption = 'double';
		} else if ($('#messageType-none').attr('checked')) {
			checkedOption = 'none';
		}
	}
	if (checkedOption === 'single') {
		$msg1texts = $('input.message-single-1');
		msg1invalid = true;
		$msg1texts.each(function () {
			msg1invalid = msg1invalid && $(this).val() === '';
		});
		if (msg1invalid) {
			err = 'You must enter text on at least one line, or select "None".';
			setTabAwayError(err);
			disableAddToCart();
			if (showError) {
				showErrorMessage(err);
			}
			return false;
		}
	} else if (checkedOption === 'double') {
		$msg1texts = $('input.message-double-1');
		$msg2texts = $('input.message-double-2');
		msg1invalid = true;
		msg2invalid = true;
		$msg1texts.each(function () {
			msg1invalid = msg1invalid && $(this).val() === '';
		});
		$msg2texts.each(function () {
			msg2invalid = msg2invalid && $(this).val() === '';
		});
		if (msg1invalid || msg2invalid) {
			if (msg1invalid && msg2invalid) {
				err = window.ConfiguratorResources.BOTH_MSGS_ARE_INVALID;
			} else {
				err = msg1invalid ? window.ConfiguratorResources.MSG1_INVALID : window.ConfiguratorResources.MSG2_INVALID;
			}
			setTabAwayError(err);
			disableAddToCart();
			if (showError) {
				showErrorMessage(err);
			}
			return false;
		}
	}

	return true;
};

/**
 * @description Disabled the Add To Cart button, as this tab is now invalid
 */
var disableAddToCart = function () {
	$('.add-to-cart').attr('disabled', 'disabled');
	$('#wishlist-link').addClass('hide');
};

/**
 * @description Enable the Add To Cart button, as this tab should now be valid
 */
var enableAddToCart = function () {
	$('.add-to-cart').removeAttr('disabled');
	$('#wishlist-link').removeClass('hide');
};

/**
 * @description Show an error message at the top of the tab section that is currently open
 * @param error {String} The error message to show
 * @returns {jQuery} the error message div
 */
var showErrorMessage = function (error) {
	return $('.configurator-controls').find('.config-tab-content.open .error-msg').html('<p>' + error + '</p>');
};

/**
 * @description Show an error message at the top of the tab section that is currently open
 * @returns {jQuery} the error message div
 */
var clearErrorMessage = function () {
	return $('.configurator-controls').find('.config-tab-content.open .error-msg').html('');
};

/**
 * @description Set an error message to show if a different tab is clicked.
 * @param error {String} The error message to show
 */
var setTabAwayError = function (error) {
	if (error !== null) {
		$currentTabContent.addClass('invalid');
		$currentTab.addClass('has-error');
		return $currentTabContent.data('validationerror', error);
	}
};

/**
 * @description Set an error message to show if a different tab is clicked.
 * @param error {String} The error message to show
 */
var clearTabAwayError = function () {
	$currentTabContent.removeClass('invalid');
	$currentTab.removeClass('has-error');
	return $currentTabContent.data('validationerror', null);
};

/**
 * @description Controls Tab-switching UI
 * @param el {Element} the tab element clicked
 * @param callback {Function} a function to execute after successful tab switch
 */
var masterTabSwitch = function (el, callback) {
	//execute validation checks and set error classes and messaging accordingly (if nec)
	validateCurrentTab();

	if ($currentTabContent.hasClass('invalid')) {
		showErrorMessage($currentTabContent.data('validationerror') || 'Please ensure your entry below is valid.');
		return false;
	}
	if ($(el).hasClass('disabled')) {
		showErrorMessage($(el).data('error') || $(el).data('validationerror') || 'Invalid choice');
		return false;
	}
	var $openedTabContent = switchAriaTab(el);
	if ($openedTabContent.length) {
		$currentTabContent = $openedTabContent;
		$currentTab = $(el);
		stickyBall(); //reset ball sticky position
		imagery.gotoSlide($(el).data('face'));
		validateCurrentTab(); //why not
		if (typeof callback === 'function') {
			callback(el);
		}
		return true;
	}
	return false;
};

/******************* Utility Functions **********************/


/**
 * @description Controls Tab-switching UI purely through ARIA attributes
 * @param el {Element} the tab element clicked
 * @return {Element} the newly opened tab content div
 */
var switchAriaTab = function (el) {
	var tabPanelId = $(el).attr('aria-controls'); //find out what tab panel this tab controls
	var $selectedTabContent = $('#' + tabPanelId);
	if ($selectedTabContent.length) {
		$(el).addClass('selected').attr('aria-selected', 'true').siblings('[role="tab"]').removeClass('selected').attr('aria-selected', 'false');
		$selectedTabContent.addClass('open').attr('aria-hidden', 'false').siblings('[role="tabpanel"]').removeClass('open').attr('aria-hidden', 'true');
	}
	return $selectedTabContent;
};

/**
 * @function createNestedObject(base, path, value)
 * @description create a hierarchy of nested objects mirroring the path structure in the given string
 * @example createNestedObject( geometry, ["shapes", "circle"] ); //Now geometry.shapes.circle is an empty object, ready to be used.
 * @example createNestedObject( geometry, ["shapes", "rectangle", "width"], 300 ); //Now we have: geometry.shapes.rectangle.width === 300
 * @param base {Object} the object on which to create the hierarchy
 * @param path {Array} an array of strings which contains the object path to be created, ex: ['messages','selectedMessageType']
 * @param value (optional): if given, will be the last object in the hierarchy. can be string, number, or probably object or null
 * @return the last object in the hierarchy
 */
var createNestedObject = function (base, path, value) {
	base = typeof base === 'object' ? base : {};
	if (path.length > 0) {
		// If a value is given, remove the last property in the path and keep it for later:
		var lastProp = arguments.length === 3 ? path.pop() : false;
		// Walk the hierarchy, creating new objects where needed.
		// If the lastProp was removed, then the last object is not set yet:
		for (var i = 0; i < path.length; i++) {
			var prop = path[i].trim();
			base = base[prop] = base[prop] || {};
		}
		// If a value was given, set it to the last name:
		if (lastProp) {
			base = base[lastProp] = value;
		}
		// Return the last object (or value) in the hierarchy
		return base;
	}
};

/**
 * Capitalize, and Capture keystrokes and alert number of characters remaining in max-length text field
 * @param el {jQuery} the jQuery object that was acted upon
 * @param e {Event} the jQuery event triggered
 */
var handleCharacterLimit = function (el) {
	// Character Limit
	var text = $(el).val(),
		charsLimit = $(el).attr('maxlength'),
		charsUsed = text.length,
		charsRemain = charsLimit - charsUsed;
	if (charsRemain < 0) {
		$(el).val(text.slice(0, charsRemain));
		charsRemain = 0;
	}
	$(el).next('div.char-count').find('.char-remain-count').html(charsRemain);
};

/**
 * @description Execute a function after the user has stopped typing for a specified amount of time
 * @param {Function} callback function to execute after a delay
 * @param {Number} delay in milliseconds
 */
var delay = (function () {
	var wait = 0;
	var lastUID;
	var functionQueue = [];
	var oneTimeFunction;
	return function (callback, ms, thisUID) {
		clearTimeout (wait);
		if (typeof callback !== 'function') {
			return;
		}
		if (typeof lastUID === 'undefined') {
			lastUID = thisUID;
		}
		if (lastUID !== thisUID) {
			lastUID = thisUID;
			functionQueue.push(callback);
		} else {
			oneTimeFunction = callback;
		}
		wait = setTimeout(function () {
			//execute all functions in the queue
			while (functionQueue.length > 0) {
				functionQueue[0]();
				functionQueue.shift();
			}
			if (typeof oneTimeFunction === 'function') {
				oneTimeFunction();
			}
		}, ms);
	};
})();

/**
 * @description Utility function to scroll to a certain element in the window
 * @param el {Element} the element to scroll to
 */
var scrollTo = function (el) {
	$(window).scrollTop(el.position().top);
};

function viewport() {
	var e = window, a = 'inner';
	if (!('innerWidth' in window)) {
		a = 'client';
		e = document.documentElement || document.body;
	}
	return {width: e[a + 'Width'] , height: e[a + 'Height']};
}

/**
 * @description Create a sticky carousel image while the user scrolls
 * @param el {Element} the element to scroll to
 */
var stickyBall = function () {
	var $anchor = $('#pdpMain'), $ball,
		vp = viewport(),
		$container = $('.mobile-sticky'),
		$controls = $('.configurator-controls'),
		$window = $(window),
		hh = $('.header-banner').outerHeight(true),
		st,
		ot,
		ob,
		bh;
	if (vp.width <= 767 && vp.height > vp.width) {
		$ball = $('.configurator-images, .mobile-sticky');
		$('.mobile-sticky').addClass('portrait');
	} else {
		$ball = $('.configurator-images');
		$('.mobile-sticky').removeClass('portrait');
	}
	if ($anchor.length && $window.length && $ball.length) {
		st = $window.scrollTop();
		ot = $anchor.offset().top;
		ob = ot + $anchor.height(); //offest.top of bottom of pdpMain
		if (st > ot) {
			$ball.addClass('sticky');
			bh = $ball.outerHeight();
			if (st < ob - bh) {
				$ball.css('top', '0px');
			} else {
				$ball.css('top', ob - st - bh);
			}
		} else {
			$ball.removeClass('sticky');
			$ball.css('top', '');
		}
	}
	if (vp.width <= 767 && vp.height > vp.width) {
		$controls.css({'margin-top': $('.primary-image').outerHeight(true) + hh + 40});
		$container.css({'margin-top': (st > hh) ? 0 : hh - st});
	} else {
		$controls.css({'margin-top': 'auto'});
		$container.css({'margin-top': 'auto'});
	}
};

/**
 * @description Update the addressbar PDP URL
 * @param el {Element} the element containing the new PDP URL value
 */
var updatePageURL = function (el) {
	var newURL = $(el).data('pdp-url');
	var productID = $(el).data('pid');

	if (el) {
		window.history.pushState(
			{productID: productID},
			$(document).find('title').text(),
			newURL
		);
	}
};

// Update all resource URLS containing a PID, except for the Clear Ball (Start Over) URL
var updateAllResourceProductURLs = function (el) {
	var newPID = $(el).data('pid');
	window.Urls.addProduct = resources.URL_ADD_PRODUCT = util.appendParamToURL(resources.URL_ADD_PRODUCT, 'pid', newPID, true);
	refreshControlHtmlUrl = resources.URL_REFRESH_CONTROLS = util.appendParamToURL(resources.URL_REFRESH_CONTROLS, 'pid', newPID, true);
	updateJsonUrl = resources.URL_UPDATE_JSON = util.appendParamToURL(resources.URL_UPDATE_JSON, 'pid', newPID, true);
};

/**
 * @description Update the addToCart to submit a new Product ID
 * @param el {Element} the element containing the new PID
 */
var updateAddToCartButton = function (el) {
	var newPID = $(el).data('pid');
	var $selectQty = $('.configurator-addtocart').find('select#Quantity'),
		$wishLink = $('.configurator-addtocart').find('#wishlist-link'),
		$hiddenInput = $('.configurator-addtocart').find('input#pid');
	var newSelectURL = util.appendParamToURL($selectQty.data('href'), 'pid', newPID, true),
		newWishURL = util.appendParamToURL($wishLink.attr('href'), 'pid', newPID, true);

	$selectQty.attr('data-href', newSelectURL);
	$wishLink.attr('href', newWishURL);
	$hiddenInput.val(newPID);
};

module.exports = controls;
