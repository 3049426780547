'use strict';
var productTile = require('../../product-tile');

function slickRecos() {
	var notifyMe = $('.ui-dialog.notifyme-dialog.notifyme-success');
	if (notifyMe.length > 0) {
		var $hCarousel = notifyMe.find('.product-listing-1x4 .tiles-container');
		//init slider
		// do not initialize twice
		if ($hCarousel.hasClass('slick-slider')) {
			return;
		}
		$hCarousel.slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			slide: '.grid-tile',
			vertical: false,
			infinite: false,
			swipe: true,
			arrows: true,
			dots: false,
			touchMove: true,
			focusOnSelect: false,
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				}
			]
		});
	} else {
		$('.product-listing-1x4 .tiles-container').each(function (index, element) {
			var $hCarousel = $(this);
			//init slider
			// do not initialize twice
			if ($hCarousel.hasClass('slick-slider')) {
				return;
			}
			$hCarousel.slick({
				slidesToShow: 3,
				slidesToScroll: 1,
				slide: '.grid-tile',
				vertical: false,
				infinite: false,
				swipe: true,
				arrows: true,
				dots: false,
				touchMove: true,
				focusOnSelect: false,
				responsive: [
					{
						breakpoint: 992,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
			});
		});
	}
}

exports.init = function () {
    slickRecos();
    productTile.init();
};
