'use strict';

var naPhone = /^\(?([2-9][0-8][0-9])\)?[\-\. ]?([2-9][0-9]{2})[\-\. ]?([0-9]{4})(\s*x[0-9]+)?$/;
var regex = {
	phone: {
		us: naPhone,
		ca: naPhone,
		cn: /.*/,
		gb: /.*/,
		de: /.*/,
		fr: /.*/,
		se: /.*/,
		ie: /.*/
		//removing phone number validation for all countries - ACU009-396
		//gb: /^((\(?0\d{4}\)?\s?\d{3}\s?\d{3})|(\(?0\d{3}\)?\s?\d{3}\s?\d{4})|(\(?0\d{2}\)?\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
		//de: /^([+]?\d{1,3})([ \.\-]{1})?(\d{3})?([ \.\-]{1})?(\d{3})([ \.\-]{1})?(\d{4})$/,
		//fr: /^(0[1-6]{1}(([0-9]{2}){4}|(\s[0-9]{2}){4}|(-[0-9]{2}){4}))|([+]?33(\s\d\s|\d)((\d{2}){4}|(\d{2}\s){3}(\d{2})))$/,
		//se: /^([+]?46)\s*((7[0236])\s*(\d{4})\s*(\d{3})|(\d(\s*\d{3}){2}\s*\d{2})|(\d(\s*\d{2}){3})|((\s*\d{2}){4})|(\s*\d{2}\s*\d{3}(\s*\d{2}){2})|(\d{3}(\s*\d{2}){3}))$/,
		//ie: /^([+]?353)\s*\d{2}\s*\d{3}\s*\d{4}$/
	},
	postal: {
		us: /^\d{5}(-\d{4})?$/,
		ca: /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/i,
		fr: /^((2[A|B])|[0-9]{2})[0-9]{3}$/i,
		it: /^([0-9]){5}$/,
		jp: /^([0-9]){3}[-]([0-9]){4}$/,
		cn: /^([0-9]){6}$/,
		gb: /(^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$)/,
		de: /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/,
		se: /^\d{3}[ ]?\d{2}$/,
		ie: /^([AC-FHKNPRTV-Y]*\d{2}|D6W) ?[0-9AC-FHKNPRTV-Y]{4}$/i,
		dk: /^[0-9]{4}$/,
		at: /^[0-9]{4}$/,
		be: /^[0-9]{4}$/,
		es: /^[0-9]{5}$/,
		fi: /^[0-9]{5}$/,
		it: /^[0-9]{5}$/,
		lu: /^[0-9]{4}$/,
		nl: /^([0-9]{4}[\s\-]{1}[A-Z]{2})|([0-9]{4}[A-Z]{2})$/,
		pt: /^([0-9]{4})|([0-9]{4}[\s\-]{1}[0-9]{3})$/,
		si: /^[0-9]{4}$/,
		sk: /^[0-9]{3}[\s\-]{1}[0-9]{2}$/
	},
	email: /^(?!.*\.@)(?!^\.)(?!.*\.{2})[\w.%+-]+@([\w\.\-]+)((\.(\w){2,}){1,})$/,
	notCC: /^(?!(([0-9 -]){13,19})).*$/,
	CC:   /^(?:(([0-9 *]{13,19})))$/,
	cvn: /^(?:(([0-9 *]{1,4})))$/,
	dob: /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[012])\/\d{4}$/,
	name: /^(?!(.*)www\.)(?!(.*)[:*!@#$%&.,\\])(?!(.*)\/)(.){1,25}$/,
	validGiftcardText: /^[a-zA-Z0-9~`!@#\$%\^&\*\(\)_\-\+={\[\}\]\|:;"'<,>\.\? ]*$/,
	validGiftcardSmile: /:\-\)|:\)|:\-\]|:\]|:\-3|:3|:\->|:>|8\-\)|8\)|:\-\}|:\}|:o\)|:c\)|:\^\)|=\]|=\)|:\-D|:D|8\-D|8D|x\-D|xD|X\-D|XD|=D|=3|B\^D|:\-\)\)|:\-\(|:\(|:\-c|:c|:\-< |:<|:\-\[|:\[|>:\[|:\\}|:@|>:\(|:'\-\(|:'\(|:'\-\)|:'\)|D\-':|D:<|D:|D8|D;|D=|DX|:\-O|:O|:\-o|:o|:\-0|8\-0|>:O|:\-\*|:\*|:�|;\-\)|;\)|\*\-\)|\*\)|;\-\]|;\]|;\^\)|:\-,|;D|:\-P|:P|X\-P|x\-p|:\-p|:p|:\-b|:b|d:|=p|>:P|:\-\.|:L|=L|:S|:\$|:\-X|:X|:\-#|:#|:\-&|:&|O:\-\)|O:\)|0:\-3|0:3|0:\-\)|0:\)|0;\^\)|>:\-\)|>:\)|\}:\-\)|\}:\)|3:\-\)|3:\)|>;\)|:\-J|#\-\)|%\-\)|%\)|:\-###\.\.|:###\.\.|',:\-l|<_<|>_>|@\};\- |@\}\->\-\- |@\}\-;\-'\-\-\- |@>\-\->\-\-|5:\-\)|~\(_8\^\(I\)|=:o\]|7:\^\]|,:\-\)|<3|><>|<\*\)\)\)\-\\}|><\(\(\(\*>|v\.v|O_O|o\-o|O_o|o_O|o_o|O\-O|>\.<|\^5|>_>\^ \^<_<|\( \^\^\)|_U~~|\( \^\^\)|\(Q\)\)|\)\)><<|<\*\)\)|>=<|\(>_<\)|\(>_<\)>|\(';'\)|\(\^_\^;\)|\(\-_\-;\)|\(~_~;\)|\^\^;|\^_\^;|\(#\^\.\^#\)|\(\^\^;\)|\(\-_\-\)zzz|\(\^_\-\)|\(\(\+_\+\)\)|\(\+o\+\)|\^_\^|\(\^o\^\)|\(__\)|_\(\._\.\)_|_\(_\^_\)_|<\(_ _\)>|<m\(__\)m>|m\(__\)m|m\(_ _\)m|\('_'\)|\(T_T\)|\(;_;\)|\(;_;|\(;_:\)|\(;O;\)|\(:_;\)|\(ToT\)|;_;|;\-;|;n;|;;|Q\.Q|T\.T|TnT|QQ|Q_Q|\(\-\.\-\)|\(\-_\-\)|\(=_=\)|\(=\^�\^=\)|\(=\^��\^=\)|=_\^=|\(\.\.\)|\(\._\.\)|\^m\^|\(\-"\-\)|\(\^0_0\^\)|\( \^\)o\(\^ \)|\(\^O\^\)|\(\^o\^\)|\(\^?\^\)|\)\^o\^\(|:O |o_O|o_0|o\.O|\(o\.o\)|oO|uwu|UwU|\(\?_\?\)|>\^_\^<|<\^!\^>|\(\*\^0\^\*\)|\(\*_\*\)|\(\*_\*;|\(\+_\+\)|\(@_@\)|!\(\^\^\)!|\(\*\^\^\)v|\(p_\-\)|\(\(d\[\-_\-\]b\)\)|\(\^\^\)v|\(\^_\^\)v|\(~o~\)|\(~_~\)/g,
	containsLetter: /[A-Za-z]+/
};
var pobox = /^ *((#\d+)|((box|bin)[-. \/\\]?\d+)|(.*p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b|(#|n|num|number)?\d+))|(p(ost|ostal)? *(o(ff(ice)?)?)? *((box|bin)|b)? *(#|n|num|number)*\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(#|n|num|number)? *\d+|(#|n|num|number) *\d+)/i;
// global form validator settings
var settings = {
	errorClass: 'error',
	errorElement: 'span',
	onkeyup: false,
	onfocusout: function (element) {
		if (!this.checkable(element)) {
			this.element(element);
		}
	},
	errorPlacement: function (error, element) {
		if (element.hasClass('input-checkbox') && element.next().is('label')) {
			error.insertAfter(element.next())
		} else {
			error.insertAfter(element)
		}
	}
};
/**
 * @function
 * @description Validates a given checking for emoji presence
 * @param {String} value The message which will be validated
 */
var validateEmoji = function (value) {
	var isValid = regex.validGiftcardText.test($.trim(value));
    if (!isValid) {
        return isValid;
    } else {
        if ($.trim(value).match(regex.validGiftcardSmile)) {
            var result = $.trim(value).match(regex.validGiftcardSmile);
            window.console.log(result);
            isValid = false;
            return isValid;
        } else {
            return isValid;
        }
    }
};

/**
 * @function
 * @description Validates a given phone number against the countries phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
var validatePhone = function (value, el) {
	var country = $(el).closest('form').find('.country');
	if (country.length === 0 || country.val().length === 0 || !regex.phone[country.val().toLowerCase()]) {
		return true;
	}

	var rgx = regex.phone[country.val().toLowerCase()];
	var isOptional = this.optional(el);
	var isValid = rgx.test($.trim(value));

	if ($(el).closest('form').hasClass('contactus-form')) {
		isValid = true;
	}

	return isOptional || isValid;
};

/**
 * @function
 * @description Validates a given email
 * @param {String} value The email which will be validated
 * @param {String} el The input field
 */
var validateEmail = function (value, el) {
	var isOptional = this.optional(el);
	var isValid = regex.email.test($.trim(value));
	return isOptional || isValid;
};
/**
 * @function
 * @description Validates that a credit card owner is not a Credit card number
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateOwner = function (value) {
	var isValid = regex.notCC.test($.trim(value));
	return isValid;
};

/**
 * @function
 * @description Validates credit card number
 * @param {String} value The owner field which will be validated
 */
var validateCreditCard = function (value, el) {
	if (value.length < 15) {
		return false;
	}
    value = value.replace(/\s/g, '');
	var sum = 0;
    var length = value.length;

    for (var i = 0; i < length; i++) {
        var digit = parseInt(value[length - i - 1]);

        if (i % 2 == 1) {
            digit *= 2;
        }

        sum += digit > 9 ? digit - 9 : digit;
    }

	var isValid = (sum % 10) == 0;
	if (!isValid) {
		//remove error message from credit card validation generated by back end code, so it doesn't show duplicated error messages
		$(el).parents('.form-row').find('.error-message').remove();
	}
	if (value.indexOf('*') === -1 && isValid) {
		//remove error class from credit card input parent
		$(el).parents('.form-row').removeClass('error');
	}

	if (value.indexOf('****') != -1) {
		isValid = true;
	}

	return isValid;
};

/**
 * @function
 * @description Validates first and last name
 * @param {String} value The owner field which will be validated
 */
var validateRegistrationName = function (value, el) {
    var isValid = $.trim(value);
    var textWordFilter = SitePreferences.FIRST_AND_LAST_TEXT_WORD_FILTER;
    if (!textWordFilter) {
        return isValid;
    }
    textWordFilter = textWordFilter.replace(/(?:\r\n|\r|\n)/g, '|');
    textWordFilter = textWordFilter.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$]/g, "\\$&");

    var wordsToFind = '\\b' + textWordFilter.replace(/\|/g, '\\b|\\b') + '\\b';
    var wordRegex = new RegExp(wordsToFind, 'gi');

    return !wordRegex.test(value);
};

/**
 * @function
 * @description Validates cvn
 * @param {String} value The owner field which will be validated
 */
var validateCVN = function (value, el) {
	var isValid = regex.cvn.test($.trim(value));
	if (!isValid) {
		//remove error message from cvn validation generated by back end code, so it doesn't show duplicated error messages
		$(el).parents('.form-row').find('.error-message').remove();
	}
	if (value.indexOf('*') === -1 && isValid) {
		//remove error class from credit card input parent
		$(el).parents('.form-row').removeClass('error');
	}
	return isValid;
};

/**
 * @function
 * @description Validates credit card expiration Month
 * @param {String} value The owner field which will be validated
 */
var validateCCExpirationMonth = function (value) {
	var isValid = validateExpiration(value, $('.ccexpiration.ccexpiration-year').val());
	if (isValid) {
		$('.ccexpiration.ccexpiration-year').removeClass('error');
	} else {
		$('.ccexpiration.ccexpiration-year').addClass('error');
	}
	return isValid;
};

/**
 * @function
 * @description Validates credit card expiration Year
 * @param {String} value The owner field which will be validated
 */
var validateCCExpirationYear = function (value) {
	var isValid = validateExpiration($('.ccexpiration.ccexpiration-month').val(), value);
	if (isValid) {
		$('.ccexpiration.ccexpiration-month').removeClass('error');
		$('.ccexpiration.ccexpiration-month').parent().find('span.error').hide();
	} else {
		$('.ccexpiration.ccexpiration-month').blur();
	}
	return isValid;
};

/**
 * @function
 * @description Validates a given postal code against the countries postal regex
 * @param {String} value The postal code which will be validated
 * @param {String} el The input field
 */
var validatePostal = function (value, el) {
	var country = $(el).closest('form').find('.country');
	if (country.length === 0 || country.val().length === 0 || !regex.postal[country.val().toLowerCase()]) {
		return true;
	}

	var rgx = regex.postal[country.val().toLowerCase()];
	var isOptional = this.optional(el);
	var isValid = rgx.test($.trim(value));

	return isOptional || isValid;
};

var validateExpiration = function (month, year) {
	var today = new Date();
	var currentMonth = today.getMonth() + 1;
	var currentYear = today.getFullYear();
	var isValid = !((parseInt(year) < currentYear) || (parseInt(month) < currentMonth && parseInt(year) === currentYear));
	return isValid;
};

var validateShippingAddress = function (value) {
	if (SitePreferences.ENABLE_PO_BOX && !SitePreferences.IS_MYJOYS_IN_CART) {
		var isValid = true;
	} else {
		var isValid = !(pobox.test($.trim(value)));
	}

	return isValid;
};

/**
 * @function
 * @description Validates date of birth
 * @param {String} value The date of birth field which will be validated
 * @param {String} el The input field
 */
var validateDOB = function (value) {
	var val = $.trim(value);
	var dob = val.split('/');

	if (val.length == 10 && dob.length > 2) {
		var bDate = new Date(dob[1] + '/' + dob[0] + '/' + dob[2]);
		var validDob = new Date() > bDate;
		var isValid = regex.dob.test(val);
		return isValid && validDob;
	} else {
		return regex.dob.test(val);
	}

};

/**
 * Add phone validation method to jQuery validation plugin.
 * Text fields must have 'phone' css class to be validated as phone
 */
$.validator.addMethod('phone', validatePhone, Resources.INVALID_PHONE);

/**
 * Add email validation method to jQuery validation plugin.
 * Text fields must have 'email' css class to be validated as email
 */
$.validator.addMethod('email', validateEmail, Resources.INVALID_EMAIL);

/**
 * Add postal code validation method to jQuery validation plugin.
 * Text fields must have 'postal' css class to be validated as phone
 */
$.validator.addMethod('zip', validatePostal, Resources.INVALID_POSTAL);

/**
 * Add CCOwner validation method to jQuery validation plugin.
 * Text fields must have 'owner' css class to be validated as not a credit card
 */
$.validator.addMethod('owner', validateOwner, Resources.INVALID_OWNER);

/**
 * Add gift cert amount validation method to jQuery validation plugin.
 * Text fields must have 'gift-cert-amont' css class to be validated
 */
$.validator.addMethod('gift-cert-amount', function (value, el) {
	var isOptional = this.optional(el);
	var isValid = (!isNaN(value)) && (parseFloat(value) >= 5) && (parseFloat(value) <= 5000);
	return isOptional || isValid;
}, Resources.GIFT_CERT_AMOUNT_INVALID);

$.validator.addMethod('gift-svs-amount', function (value, el) {
    var $giftSvsAmount = $('.gift-svs-select-amount');

    if (value && $giftSvsAmount && !$giftSvsAmount.val()) {
        $giftSvsAmount.attr('required', false);
    } else if (!value && $giftSvsAmount && !$giftSvsAmount.val()) {
        $giftSvsAmount.attr('required', true);
    }
    return true;
}, Resources.VALIDATE_REQUIRED);

/**
 * Add first name validation method to jQuery validation plugin.
 * Text fields must have 'customer-first-name' css class to be validated
 */
$.validator.addMethod('requiresLetter', function (value, el) {
	var isOptional = this.optional(el);
	var isValid = regex.containsLetter.test($.trim(value));
	return isOptional || isValid;
}, Resources.NO_LETTERS);

/**
 * Add first name validation method to jQuery validation plugin.
 * Text fields must have 'customer-first-name' css class to be validated
 */
$.validator.addMethod('customer-first-name', function (value, el) {
	var isOptional = this.optional(el);
	var isValid = regex.name.test($.trim(value));
	return isOptional || isValid;
}, Resources.VALIDATE_FIRST_NAME);

/**
 * Add last name validation method to jQuery validation plugin.
 * Text fields must have 'customer-last-name' css class to be validated
 */
$.validator.addMethod('customer-last-name', function (value, el) {
	var isOptional = this.optional(el);
	var isValid = regex.name.test($.trim(value));
	return isOptional || isValid;
}, Resources.VALIDATE_LAST_NAME);

/**
 * Add first name validation method to jQuery validation plugin.
 * Text fields must have 'prof-first-name' css class to be validated
 */
$.validator.addMethod('prof-first-name', validateRegistrationName, Resources.VALIDATE_FIRST_NAME);

/**
 * Add first name validation method to jQuery validation plugin.
 * Text fields must have 'prof-last-name' css class to be validated
 */
$.validator.addMethod('prof-last-name', validateRegistrationName, Resources.VALIDATE_LAST_NAME);

/**
 * Add positive number validation method to jQuery validation plugin.
 * Text fields must have 'positivenumber' css class to be validated as positivenumber
 */
$.validator.addMethod('positivenumber', function (value) {
	if ($.trim(value).length === 0) { return true; }
	return (!isNaN(value) && Number(value) >= 0);
}, ''); // '' should be replaced with error message if needed

/**
 * Add creditcard validation method to jQuery validation plugin.
 * Text fields must have 'creditcard' css class to be validated as creditcard (see inputField.js)
 */
$.validator.addMethod('creditcard', validateCreditCard, Resources.VALIDATE_CREDITCARD);

/**
 * Add cvn validation method to jQuery validation plugin.
 * Text fields must have 'cvn' css class to be validated as cvn (see inputField.js)
 */
$.validator.addMethod('cvn', validateCVN, Resources.VALIDATE_CVN);

/**
 * Add credit card expiration validation method to jQuery validation plugin.
 * Text fields must have 'ccexpiration-month' css classes to be validated (see inputField.js)
 */
$.validator.addMethod('ccexpiration-month', validateCCExpirationMonth, Resources.VALIDATE_CCEXPIRATION);

/**
 * Add credit card expiration validation method to jQuery validation plugin.
 * Text fields must have 'ccexpiration-year' css classes to be validated (see inputField.js)
 */
$.validator.addMethod('ccexpiration-year', validateCCExpirationYear, null);

$.validator.addMethod('shipping-address', validateShippingAddress, Resources.VALIDATE_SHIPPING_ADDRESS);

/**
 * Add date of birth validation method to jQuery validation plugin.
 * Text fields must have 'birthday' css class to be validated as date of birth
 */
$.validator.addMethod('birthday', validateDOB, Resources.INVALID_DOB);

/**
 * Add postal validation method to jQuery validation plugin.
 * Text fields must have 'postal' css class to be validated as postal code
 */
$.validator.addMethod('postal', validatePostal, Resources.INVALID_ZIP);

/**
 * Add emoji validation method to jQuery validation plugin.
 * Text fields must have 'emojiless' css class to be validated as having no emojis
 */
$.validator.addMethod('emojiless', validateEmoji, Resources.INVALID_EMOJI);

$.extend($.validator.messages, {
	required: Resources.VALIDATE_REQUIRED,
	remote: Resources.VALIDATE_REMOTE,
	email: Resources.VALIDATE_EMAIL,
	url: Resources.VALIDATE_URL,
	date: Resources.VALIDATE_DATE,
	dateISO: Resources.VALIDATE_DATEISO,
	number: Resources.VALIDATE_NUMBER,
	digits: Resources.VALIDATE_DIGITS,
	creditcard: Resources.VALIDATE_CREDITCARD,
	creditcardexpiration: Resources.VALIDATE_CCEXPIRATION,
	cvn: Resources.VALIDATE_CVN,
	equalTo: Resources.VALIDATE_EQUALTO,
	maxlength: $.validator.format(Resources.VALIDATE_MAXLENGTH),
	minlength: $.validator.format(Resources.VALIDATE_MINLENGTH),
	rangelength: $.validator.format(Resources.VALIDATE_RANGELENGTH),
	range: $.validator.format(Resources.VALIDATE_RANGE),
	max: $.validator.format(Resources.VALIDATE_MAX),
	min: $.validator.format(Resources.VALIDATE_MIN)
});

var validator = {
	regex: regex,
	settings: settings,
	init: function () {
		var self = this;
		$('form:not(.suppress)').each(function () {
			$(this).validate(self.settings);
		});
	},
	initForm: function (f) {
		$(f).validate(this.settings);
	}
};

module.exports = validator;
