'use strict';
var util = require('./util');

var pdComponents = {
    init: function () {
        var pdSliders = {
            init: function () {
                pdSliders.producRecsSlider();
                pdSliders.featuresSlider();
                pdSliders.reviewsSlider();
                pdSliders.collectionsBannersSlider();
            },
            producRecsSlider: function () {
                var $producRecsSlider = $('.product-recs-slider');

                $producRecsSlider.not('.slick-initialized').slick({
                    mobileFirst: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    slide: '.fj-rp-slide',
                    vertical: false,
                    infinite: true,
                    swipe: true,
                    arrows: true,
                    dots: false,
                    touchMove: true,
                    responsive: [
                        {
                            breakpoint: 879,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });
            },
            featuresSlider: function () {
                var $featuresSlider = $('.experience-featuresSlider');

                if (util.isMobile()) {
                    $featuresSlider.not('.slick-initialized').slick({
                        mobileFirst: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        slide: '.experience-assets-producttile',
                        vertical: false,
                        infinite: true,
                        swipe: true,
                        arrows: true,
                        dots: false,
                        touchMove: true
                    });
                } else {
                    $('.experience-featuresSlider.slick-initialized').slick('unslick');
                }
            },
            reviewsSlider: function () {
                var $reviewsSlider = $('.pd-reviews-banner');

                if (util.isMobile()) {
                    $reviewsSlider.not('.slick-initialized').slick({
                        mobileFirst: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        slide: '.pd-review-container',
                        vertical: false,
                        swipe: true,
                        arrows: true,
                        dots: false,
                        touchMove: true,
                        infinite: false
                    });

                    $reviewsSlider.find('.pd-review-container').height('auto').syncHeight();
                } else {
                    $('.pd-reviews-banner.slick-initialized').slick('unslick');
                }
            },
            collectionsBannersSlider: function () {
                var $collectionsBannersSlider = $('.pd-collection-tiles-container');

                if (util.isMobile()) {
                    $collectionsBannersSlider.not('.slick-initialized').slick({
                        mobileFirst: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        slide: '.pd-collection-tile',
                        vertical: false,
                        swipe: true,
                        arrows: true,
                        dots: false,
                        touchMove: true
                    });
                } else {
                    $('.pd-collection-tiles-container.slick-initialized').slick('unslick');
                }
            }
        };

        util.smartResize(function () {
            pdSliders.init();
        });

        $(document).ready(function () {
            pdSliders.init();

            $('.product-recs-slider').each( function () {
                $(this).find('.fj-rp-slide-title').height('auto').syncHeight();
            });

        });
    },
};

module.exports = pdComponents;
