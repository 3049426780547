'use strict';

var addToCart = require('./addToCart'),
	customize = require('./customize'),
	ajax = require('../../ajax'),
	image = require('./image'),
	progress = require('../../progress'),
	productStoreInventory = require('../../storeinventory/product'),
	tooltip = require('../../tooltip'),
	sizeguide = require('../../sizeguide'),
    embroidery = require('../../embroidery'),
	dialog = require('../../dialog'),
	util = require('../../util'),
    notifyme = require('../../notifyme');

/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} href - url of the new product variant
 **/
var updateContent = function (href, size) {
	var $pdpForm = $('.pdpForm');
	var qty = $pdpForm.find('select[name="Quantity"]').first().val();
	var params = {
		Quantity: isNaN(qty) ? '1' : qty,
		format: 'ajax',
		productlistid: $pdpForm.find('input[name="productlistid"]').first().val()
	};

	progress.show($('#pdpMain'));
	window.isUpdateContent = true;
	$('.product-main-info > .product-top-data').remove();
	if ($('.product-main-info .pdp-hero').length < 1) {
		$('.product-main-info .breadcrumbs-mobile').addClass('short');
	};

	ajax.load({
		url: util.appendParamsToUrl(href, params),
		target: $('#product-content'),
		callback: function () {
			if (util.getViewport() < 880) {
				var $productMainContainer = $('.product-main-info'),
					$productTopData = $('.product-detail .product-top-data');
				$productTopData.prependTo($productMainContainer);
			}
			if ($('.product-main-info .pdp-hero').length < 1) {
				$('.product-main-info .breadcrumbs-mobile').addClass('short');
			};
			addToCart();
			customize();
			if (SitePreferences.STORE_PICKUP) {
				productStoreInventory.init();
			}
			image.replaceImages();
			image.replaceActionImages();
			tooltip.init();
			image.setupCarousel();
			image.actionImagesInitialize();
            if($('.product-thumbnails .carousel').hasClass('slick-initialized')) {
                $('.product-thumbnails .carousel').slick('refresh');
            }

			$('#free-returns').on('click', function (e) {
				e.preventDefault();
				dialog.open({
					url: $(e.target).attr('href')
				});
			});
			$('#styleID').html($('#itemID').html());
			if ($('#add-to-cart').hasClass('disabled')) {
				if ($('.product-detail .select-size .custom-select li').length == 1 && !$('.product-detail .select-size  .custom-select li').hasClass('selected')) {
					$('.product-detail .select-size .custom-select li').trigger('click');
				}
				if ($('.product-detail .select-width .custom-select li').length == 1 && !$('.product-detail .select-width  .custom-select li').hasClass('selected')) {
					$('.product-detail .select-width .custom-select li').trigger('click');
				}
			}
            updateWishListURL();
            embBuildSwatch();
            showCompareDialog();
            toggleView();
            $('.size-chart-link a').on('click', function (e) {
                e.preventDefault();
                dialog.open({
                    url: $(e.target).attr('href'),
                    options: {
                        width: 768
                    }
                });
            });
            validateGiftCard();
			sizeguide.init();
            if (!$('#customizedConfiguration').length) {
                embroidery.embroideryInit();
            }
            if ($('.pdp-promo-container').val().trim() == '') {
                $('.pdp-promo-container').addClass('no-promos');
            }
            swatchPreSwitch();
            embroidery.embroideryActions();
			if ($('.cart-buttons-container .apple-pay-button').length && !window.ApplePaySession) {
				$('.apple-pay-button').remove();
			};
			if (size !== undefined) {
				$('.custom-li[data-size="' + size + '"]').trigger('click');
			}
            if ($('#customizedConfiguration').length > 0) {
                if ($('#embroiderystyleoptioninput').length > 0) {
                    var embroiderystyleoption =  $('#customizedConfiguration').attr('styleOption');
                    $('#embroiderystyleoptioninput').val(embroiderystyleoption);
                }
                if ($('#embroiderythreadcolorinput').length > 0) {
                    var embroiderythreadcolor =  $('#customizedConfiguration').attr('threadColor');
                    $('#embroiderythreadcolorinput').val(embroiderythreadcolor);
                }
                if ($('#embroiderytextinput').length > 0) {
                    var embroiderytext =  $('#customizedConfiguration').attr('text');
                    $('#embroiderytextinput').val(embroiderytext);
                }
                if ($('#embroiderytext2input').length > 0) {
                    var embroiderytext2 =  $('#customizedConfiguration').attr('text2');
                    $('#embroiderytext2input').val(embroiderytext2);
                }
                $('#editEmbroidery').show();
                $('#personalize').prop('checked', true);
            } else {
                $('#personalize').prop('checked', false);
            }
		}
	});
};

function expandCustomSelect(e) {
	$('.content:visible').slideUp('slow');
	$(this)
		.next('.content:hidden')
		.slideDown('slow');
}

function updateWishListURL() {
    if (typeof WISHLIST_URL !== 'undefined') {
        $('.secondary.add-to-wishlist').attr('href', WISHLIST_URL);
    }

    $('.add-to-wishlist').on('click', customize.addToWishlist);
}

function embBuildSwatch() {
    if (!$('#customizedConfiguration').length) {
        $('#personalize').prop('checked', false);
        $('.product-col-2').removeClass('has-embroidery');
    } else {
        $('#personalize').prop('checked', true);
        $('.product-col-2').addClass('has-embroidery');
    }
}

function swatchPreSwitch() {
    if ($('#customizedConfiguration').length && $('#swatch-preview').css('display') == 'block') {
        var newBgSwatch = $('ul.swatches .selected .swatchanchor').data('imageswatch');
        if (!!newBgSwatch) {
            $('.fab-img').attr('src', newBgSwatch);
        }
    }
}

function evaluateGCFields(requiredInputs, emojiField, requiresLetterInputs) {
	var enable = checkRequiredFields(requiredInputs);
	var regex = {
			containsLetter: /[A-Za-z]+/,
			validGiftcardText: /^[a-zA-Z0-9~`!@#\$%\^&\*\(\)_\-\+={\[\}\]\|:;"'<,>\.\? ]*$/,
			validGiftcardSmile: /:\-\)|:\)|:\-\]|:\]|:\-3|:3|:\->|:>|8\-\)|8\)|:\-\}|:\}|:o\)|:c\)|:\^\)|=\]|=\)|:\-D|:D|8\-D|8D|x\-D|xD|X\-D|XD|=D|=3|B\^D|:\-\)\)|:\-\(|:\(|:\-c|:c|:\-< |:<|:\-\[|:\[|>:\[|:\\}|:@|>:\(|:'\-\(|:'\(|:'\-\)|:'\)|D\-':|D:<|D:|D8|D;|D=|DX|:\-O|:O|:\-o|:o|:\-0|8\-0|>:O|:\-\*|:\*|:�|;\-\)|;\)|\*\-\)|\*\)|;\-\]|;\]|;\^\)|:\-,|;D|:\-P|:P|X\-P|x\-p|:\-p|:p|:\-b|:b|d:|=p|>:P|:\-\.|:L|=L|:S|:\$|:\-X|:X|:\-#|:#|:\-&|:&|O:\-\)|O:\)|0:\-3|0:3|0:\-\)|0:\)|0;\^\)|>:\-\)|>:\)|\}:\-\)|\}:\)|3:\-\)|3:\)|>;\)|:\-J|#\-\)|%\-\)|%\)|:\-###\.\.|:###\.\.|',:\-l|<_<|>_>|@\};\- |@\}\->\-\- |@\}\-;\-'\-\-\- |@>\-\->\-\-|5:\-\)|~\(_8\^\(I\)|=:o\]|7:\^\]|,:\-\)|<3|><>|<\*\)\)\)\-\\}|><\(\(\(\*>|v\.v|O_O|o\-o|O_o|o_O|o_o|O\-O|>\.<|\^5|>_>\^ \^<_<|\( \^\^\)|_U~~|\( \^\^\)|\(Q\)\)|\)\)><<|<\*\)\)|>=<|\(>_<\)|\(>_<\)>|\(';'\)|\(\^_\^;\)|\(\-_\-;\)|\(~_~;\)|\^\^;|\^_\^;|\(#\^\.\^#\)|\(\^\^;\)|\(\-_\-\)zzz|\(\^_\-\)|\(\(\+_\+\)\)|\(\+o\+\)|\^_\^|\(\^o\^\)|\(__\)|_\(\._\.\)_|_\(_\^_\)_|<\(_ _\)>|<m\(__\)m>|m\(__\)m|m\(_ _\)m|\('_'\)|\(T_T\)|\(;_;\)|\(;_;|\(;_:\)|\(;O;\)|\(:_;\)|\(ToT\)|;_;|;\-;|;n;|;;|Q\.Q|T\.T|TnT|QQ|Q_Q|\(\-\.\-\)|\(\-_\-\)|\(=_=\)|\(=\^�\^=\)|\(=\^��\^=\)|=_\^=|\(\.\.\)|\(\._\.\)|\^m\^|\(\-"\-\)|\(\^0_0\^\)|\( \^\)o\(\^ \)|\(\^O\^\)|\(\^o\^\)|\(\^?\^\)|\)\^o\^\(|:O |o_O|o_0|o\.O|\(o\.o\)|oO|uwu|UwU|\(\?_\?\)|>\^_\^<|<\^!\^>|\(\*\^0\^\*\)|\(\*_\*\)|\(\*_\*;|\(\+_\+\)|\(@_@\)|!\(\^\^\)!|\(\*\^\^\)v|\(p_\-\)|\(\(d\[\-_\-\]b\)\)|\(\^\^\)v|\(\^_\^\)v|\(~o~\)|\(~_~\)/g
	};
	requiresLetterInputs.each(function () {
		var testValue = $(this).val();
		if (!regex.containsLetter.test($.trim(testValue))) {
			enable = false;
		}
	});
	if (!enable) {
		return false;
	}
	var value = $(emojiField).val();
	var isValid = regex.validGiftcardText.test($.trim(value));
    if (!isValid) {
        return false;
    } else {
        if ($.trim(value).match(regex.validGiftcardSmile)) {
            var result = $.trim(value).match(regex.validGiftcardSmile);
            window.console.log(result);
            return false;
        }
    }
    return enable;
}

function checkRequiredFields(requiredInputs) {
	var isFormCompleted = true;
	requiredInputs.each(function () {
		if ($(this).val() === '') {
			isFormCompleted = false;
			return isFormCompleted;
		}
	});
	return isFormCompleted;
}
function validateGiftCard() {
	var $gcForm = $('#pdpMain .gcForm');
	if ($gcForm.length > 0) {
        var $addGCToCart = $('#add-gc-to-cart');
		var $requiredInputs = $gcForm.find('.required');
		var $requiresLetterInputs = $gcForm.find('.requiresLetter');
		var $perMsg = $gcForm.find('textarea.emojiless');
		// This checks anything entered into the textarea.
		$perMsg.on("input", function () {
			var enableA2C = evaluateGCFields($requiredInputs, $perMsg, $requiresLetterInputs);
			if (enableA2C) {
				$addGCToCart.removeClass('disabled');
			} else {
				$addGCToCart.addClass('disabled');
			}
		});
		$requiredInputs.on('change', function () {
			if (evaluateGCFields($requiredInputs, $perMsg, $requiresLetterInputs)) {
				$addGCToCart.removeClass('disabled');
			} else {
				$addGCToCart.addClass('disabled');
			}
		});
		$('#giftcardPurchaseAmount').on('change', function () {
			if ($(this).valid() && $(this).val() !== '') {
				$('#giftcardPurchaseAmountOption').removeAttr('required').removeClass('required').val('').blur();
				$requiredInputs = $gcForm.find('.required');
				if (evaluateGCFields($requiredInputs, $perMsg, $requiresLetterInputs)) {
					$addGCToCart.removeClass('disabled');
				} else {
					$addGCToCart.addClass('disabled');
				}
			} else {
				$('#giftcardPurchaseAmountOption').attr('required', 'required').addClass('required');
				$requiredInputs = $gcForm.find('.required');
				$addGCToCart.addClass('disabled');
			}
		});
		$('#giftcardPurchaseAmountOption').on('change', function () {
			$('#giftcardPurchaseAmount').val('');
			$(this).attr('required', 'required').addClass('required');
			$requiredInputs = $gcForm.find('.required');
			if (evaluateGCFields($requiredInputs, $perMsg, $requiresLetterInputs)) {
				$addGCToCart.removeClass('disabled');
			} else {
				$addGCToCart.addClass('disabled');
			}
		});
	}

}

function showCompareDialog() {
    $('.product-compare-link a').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                width: 768
            }
        });
    });
}

function toggleView() {
    var $tabsAcc = $('.product-info-tabs-accordion');

    if ($(window).width() < 879) {
        $tabsAcc.find('.accordion-header').attr('aria-expanded', 'false');
        $tabsAcc.find('.accordion-header').parent().siblings().find('.accordion-content').hide();
        // Mobile: Accordion behavior
        $tabsAcc.find('.accordion-header').off('click').on('click', function () {
            const content = $(this).next('.accordion-content');
            content.slideToggle();
            var attr = $(this).attr('aria-expanded');
            if(attr === 'true') {
                $(this).attr('aria-expanded', 'false');
            } else {
                $(this).attr('aria-expanded', 'true');
            }
            $(this).parent().siblings().find('.accordion-content').slideUp();
            $(this).parent().siblings().find('.accordion-header').attr('aria-expanded', 'false');
        });
    } else {
        $tabsAcc.find('.content').each(function () {
            $(this).find('.accordion-content').show();
        });

        // Desktop: Tabs behavior
        $tabsAcc.find('.tab').off('click').on('click', function () {
            const target = $(this).data('target');
            $tabsAcc.find('.tab').removeClass('active');
            $(this).addClass('active');
            $tabsAcc.find('.content').removeClass('active');
            $(target).addClass('active');
        });
    }
}

module.exports = function () {
	//notify me dialog
	notifyme.init();

	var $pdpMain = $('#pdpMain');
	// hover on swatch - should update main image with swatch image
	$pdpMain.on('mouseenter mouseleave', '.swatchanchor', function () {
		var largeImg = $(this).data('lgimg').url,
			$imgZoom = $pdpMain.find('.main-image'),
			$mainImage = $pdpMain.find('.primary-image');

		if (!largeImg) { return; }
		// store the old data from main image for mouseleave handler
		$(this).data('lgimg', {
			hires: $imgZoom.attr('href'),
			url: $mainImage.attr('src'),
			alt: $mainImage.attr('alt'),
			title: $mainImage.attr('title')
		});
		// set the main image
		image.setMainImage(largeImg);
	});

	// click on swatch - should replace product content with new variant
	$pdpMain.on('click', '.product-detail .swatchanchor', function (e) {
		e.preventDefault();
        var bgSwatch = $('ul.swatches .selected .swatchanchor').data('imageswatch');
        if (!!bgSwatch) {
            $('.fab-img').attr('src', bgSwatch);
        }
		if ($(this).parents('li').hasClass('unselectable')) { return; }
		var currSize = $('.product-detail .select-size .custom-select li.selected').data('size');
		updateContent(this.href, currSize);
		window.history.replaceState('', $(document).find("title").text(), $(this).attr('data-product-url'));
	});

	$pdpMain.on('click', '.product-detail .custom-select li:not(.inactive)', function () {
		updateContent($(this).attr('value'));
	});

	$pdpMain.on('click', '.product-detail .custom-select li.inactive', function (e) {
		e.preventDefault();
        if (SitePreferences.SOLD_OUT_PRODUCTS_ENABLED) {
            var elementCount = $(this).parent().find('li').length;
            var inactiveElementCount = $(this).parent().find('li.inactive').length;
            var isAllInactive = elementCount == inactiveElementCount;
            if (isAllInactive) {
                return;
            }
        }
		if ($('#add-to-cart').hasClass('locked')) {
			return;
		}
		$(this).closest('.warning-box').find('.custom-li.selected').removeClass('selected');
		$(this).closest('.warning-box').addClass('error');
		$('.low-inventory-msg').text('Please select a ' + $(this).closest('.must-select').attr('data-attribute'));
		$(document).find('.custom-select-field .select-header').addClass('error');
		if (!$('#add-to-cart').hasClass('disabled')) {
			$('#add-to-cart').text(Resources.SELECT_A_SIZE);
			$('#add-to-cart').addClass('disabled');
		}
	});

	$pdpMain.on('change', '#SpecialPlayNumber', function () {
		if ($(this).val() != '') {
			updateContent($(this).val());
		} else {
			$('#add-to-cart').addClass('disabled');
		}
	});

	$pdpMain.on('keydown', '.product-detail .custom-select li', function (e) {
		var key = e.keyCode ? e.keyCode : e.which;
		var KEY_CODE_ENTER = 13,
			KEY_CODE_SPACE = 32;
		if ([KEY_CODE_ENTER, KEY_CODE_SPACE].indexOf(key) > -1) {
			e.preventDefault();
			updateContent($(this).attr('value'));
		}
	});

	$pdpMain.on('click', '.product-detail #play-hand .button', function () {
		var btn = $(this);
		if (!btn.hasClass('disabled')) {
			$('#va-orientation option').each(function () {
				if ($(this).html().trim() === btn.data('attr-value')) {
					updateContent($(this).attr('value'));
				}
			});
		}
	});

	// change drop down variation attribute - should replace product content with new variant
	$pdpMain.on('change', '.variation-select', function (e) {
		e.preventDefault();
		if ($(this).val().length === 0) { return; }
		updateContent($(this).val());
	});

	$pdpMain.on('keydown', '.header', function (e) {
		var key = e.keyCode ? e.keyCode : e.which;
		var KEY_CODE_ENTER = 13,
		KEY_CODE_SPACE = 32,
		actionKeys = [KEY_CODE_ENTER, KEY_CODE_SPACE];
		if (actionKeys.indexOf(key) > -1) {
			e.preventDefault();
			expandCustomSelect.call(this);
		}
	});

	if ($('#add-to-cart').hasClass('disabled')) {
		if ($('.product-detail .select-size .custom-select li').length == 1 && !$('.product-detail .select-size  .custom-select li').hasClass('selected')) {
			$('.product-detail .select-size .custom-select li').trigger('click');
		}

		if ($('.product-detail .select-width .custom-select li').length == 1 && !$('.product-detail .select-width  .custom-select li').hasClass('selected')) {
			$('.product-detail .select-width .custom-select li').trigger('click');
		}
	}

   $('.size-chart-link a').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                width: 768
            }
        });
    });

    updateWishListURL();
    validateGiftCard();
    showCompareDialog();
    toggleView();

    util.smartResize(function () {
        toggleView();
    });
};
