'use strict';

var headerslider = {
    init: function () {
        var FJ_Header = {
			lastScrollTop: 0,

			marqueeSpeed: 1000,
			marqueeInterval: 5000,

			/**
			 * debounce - Use this like $( window ).resize( canvas.debounce( function(){}, 500 ) );
			 *
			 * @param function 	fn 		Pass in the function to call after the delay
			 * @param integer 	delay 	The time (in miliseconds) to delay the function from running
			 *
			 * @return function 		returns a function which calls passed in function when the delay has run out
			 */
			debounce: function (fn, delay) {
				var timer = null;

				return function () {
					var context = this,
						args = arguments;

					clearTimeout(timer);

					timer = setTimeout(function () {
						fn.apply(context, args);
					}, delay);
				};
            }
        }
        $('.js-content-marquee')
        .find('.header-promotion')
        .removeClass('current next')
        .eq(0)
        .css({top: '0'})
        .addClass('current');
        setInterval(function () {
        var $marquee = $('.js-content-marquee'),
            $current = $marquee.find('.header-promotion.current'),
            $next = $current.next();
        if ($next.length <= 0) {
            $next = $marquee.find('.header-promotion').eq(0);
        }
        // Slide the current text up, snap to bottom when done
        $current.removeClass('current').animate(
            {
                top: '-100%'
            },
            FJ_Header.marqueeSpeed,
            function () {
                $(this).css({top: '100%'});
            }
        );
        // Slide the next text up out of view
        $next.addClass('current').animate(
            {
                top: '0'
            },
            FJ_Header.marqueeSpeed
        );
        }, FJ_Header.marqueeInterval);
    }
};

module.exports = headerslider;


