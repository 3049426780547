'use strict';
var dialog = require('./dialog'),
    progress = require('./progress'),
    validator = require('./validator'),
    einsteinObserver = require('./pages/product/einstein');
/**
 * @function
 * @description Initializes notify me functionality
 */

function thankyouMobile () {
    var wheight = $(window).height();
    var scrollpos = $(window).scrollTop();
    var scrollpad = ($('.notifyme-success').height() / 2) - .5;
    scrollpos = scrollpos + scrollpad;
    if (wheight < 750) {
        $('.notifyme-success').css({'top': scrollpos + 'px'});
    }
}

exports.init = function () {
	$(document).on('click', '.notify-me-link-wrapper .notify-me-link', function (e) {
        e.preventDefault();
		dialog.open({
			url: $('#notify-me-lnk').data('notifylink'),
			options: {
				dialogClass: 'notifyme-dialog',
				open: function (e) {
                    e.preventDefault();
					$('.ui-dialog.notifyme-dialog').removeClass('notifyme-success');
                    $('.ui-widget-overlay').addClass('notify-overlay');
                    validator.init();
                    return false;
				}
			}
		});
	});
	$(document).on('submit', '.notify-me-form', function (e) {
        e.preventDefault();
        progress.show('.notify-me-form');
		var url = $(this).attr('action');
		$.ajax({
			type: 'POST',
			url: url,
			data: $(this).serialize(),
			success: function (response) {
				$('.ui-dialog.notifyme-dialog .dialog-content').empty();
				$('.ui-dialog.notifyme-dialog').addClass('notifyme-success');
				$('.ui-dialog.notifyme-dialog .dialog-content').html(response);
				einsteinObserver.init();
                thankyouMobile();
				progress.hide();
			}
		})
	});
    $(document).on('change', '.notify-me-form .notify-me-form-container .radio-buttons-group-wrapper.trigger-update input', function (e) {
        e.preventDefault();
        progress.show('.notify-me-form');

        // unselect all child attributes which are placed below selected attribute
        var attributeNumber = $(this).closest('div[data-number]').attr('data-number');
        var countAttribute = $('#attributeCountElement').attr('data-count-attributes');

        if (attributeNumber && countAttribute) {
            var attributeNumberValue = +attributeNumber;
            var countAttributeValue = +countAttribute;
            if (attributeNumberValue  <= countAttributeValue) {
                var startIndex = attributeNumberValue + 1;
                for (var i = startIndex; i <= countAttributeValue; i++) {
                    var elementSelector = '.notify-me-form div[data-number="' + i + '"]';
                    // unselect all radio input
                    $(elementSelector).find('input[type="radio"]').removeAttr('checked');
                }
            }
        }

        var url = Urls.notifyMeUpdateVariant;
        $.ajax({
            type: 'POST',
            url: url,
            data: $('.notify-me-form').serialize(),
            success: function (response) {
                $('.notify-me-form-container').empty();
                $('.notify-me-form-container').html(response);
                progress.hide();
            }
        })
    });

    // Hadnle swatch element
    $(document).on('click', '.notify-me-form .notify-me-form-container .radio-buttons-group-wrapper:not(.trigger-update) .swatchanchor-wrapper input', function (e) {
        // Remove selected attribute
        $('.notify-me-form .notify-me-form-container .radio-buttons-group-wrapper:not(.trigger-update) .swatchanchor-wrapper input').closest('label.radio-button').removeClass('selected');

        // Check selected attribute
        $(this).closest('label.radio-button').addClass('selected');
    });

    $(document).on('click', '.notifyme-submit', function () {
        $('#NotifyMeForm .radio-buttons-container').each(function () {
            if (!$(this).find('.radio-button input[type="radio"]').is(':checked')) {
                $(this).closest('.radio-buttons-group-wrapper').addClass('error-notify');
            }
        });
    });

    $(document).on('click', '.error-notify .radio-buttons-container .radio-button', function () {
        $(this).closest('.radio-buttons-group-wrapper').removeClass('error-notify');
    });

    $(document).on('click', '.notifyme-confirmation-continue-shopping .continue-shopping', function () {
        $('.notifyme-dialog .ui-dialog-titlebar-close').trigger('click');
    });
};
