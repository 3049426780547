'use strict';

var $subscription, $maskedFourDigit, $number;

exports.init = function () {
	$subscription = $('input[name$="creditCard_isSubscription"]');
	$maskedFourDigit = $('input[name$="creditCard_maskedFourDigit"]');
	$number = $('input[name*="creditCard_number"]');

	$subscription.val(false);

	$maskedFourDigit.parent().parent().hide();

	if ($maskedFourDigit.val() === 'undefined' || $maskedFourDigit.val() === '') {
		$maskedFourDigit.parent().parent().hide();
	} else {
		$maskedFourDigit.parent().parent().show();
		$number.parent().parent().hide();
		$subscription.val(true);
	}
};

exports.setFields = function (data) {
	$subscription.val(data.isSubscription);
	$maskedFourDigit.val(data.maskedFourDigit);
	$maskedFourDigit.parent().parent().show();
	$number.parent().parent().hide();
};