'use strict';

var header = {
	init: function () {
		// jscs:disable
		if (typeof FJ_Header == 'undefined') {
			//object for organizing code
			var FJ_Header = {
				lastScrollTop: 0,

				marqueeSpeed: 1000,
				marqueeInterval: 5000,

				/**
				 * debounce - Use this like $(window).resize(canvas.debounce(function (){}, 500));
				 *
				 * @param function 	fn 		Pass in the function to call after the delay
				 * @param integer 	delay 	The time (in miliseconds) to delay the function from running
				 *
				 * @return function 		returns a function which calls passed in function when the delay has run out
				 */
				debounce: function (fn, delay) {
					var timer = null;

					return function () {
						var context = this,
							args = arguments;

						clearTimeout(timer);

						timer = setTimeout(function () {
							fn.apply(context, args);
						}, delay);
					};
				},

				init: function () {
					FJ_Header.initMarquee();
					FJ_Header.searchFormValidation();
				},

				initMobile: function () {
					FJ_Header.initMarqueeMobile();
				},

				initMarquee: function () {
					$('.js-content-marquee')
						.find('.content-marquee-item')
						.removeClass('current next')
						.eq(0)
						.css({ top: '0' })
						.addClass('current');

					setInterval(function () {
						var $marquee = $('.js-content-marquee'),
							$current = $marquee.find('.content-marquee-item.current'),
							$next = $current.next();

						if ($next.length <= 0) {
							$next = $marquee.find('.content-marquee-item').eq(0);
						}

						// Slide the current text up, snap to bottom when done
						$current.removeClass('current').animate(
							{
								top: '-100%'
							},
							FJ_Header.marqueeSpeed,
							function () {
								$(this).css({ top: '100%' });
							}
						);

						// Slide the next text up out of view
						$next.addClass('current').animate(
							{
								top: '0'
							},
							FJ_Header.marqueeSpeed
						);
					}, FJ_Header.marqueeInterval);
				},

				initMarqueeMobile: function () {
					$('.js-content-marquee-mobile')
						.find('.content-marquee-item-mobile')
						.removeClass('current next')
						.eq(0)
						.css({ top: '0' })
						.addClass('current');

					setInterval(function () {
						var $marquee = $('.js-content-marquee-mobile'),
							$current = $marquee.find('.content-marquee-item-mobile.current'),
							$next = $current.next();

						if ($next.length <= 0) {
							$next = $marquee.find('.content-marquee-item-mobile').eq(0);
						}

						// Slide the current text up, snap to bottom when done
						$current.removeClass('current').animate(
							{
								top: '-100%'
							},
							FJ_Header.marqueeSpeed,
							function () {
								$(this).css({ top: '100%' });
							}
						);

						// Slide the next text up out of view
						$next.addClass('current').animate(
							{
								top: '0'
							},
							FJ_Header.marqueeSpeed
						);
					}, FJ_Header.marqueeInterval);
				},

				showDesktopMenu: function(menu) {
					var $drawer = $('.header .header-drawer-desktop');

					$drawer
						.find('.desktop-menu')
						.removeClass('active')
						.filter('.' + menu)
						.addClass('active');
					$drawer.height($drawer.find('.desktop-menu.active').outerHeight());
				},

				showMobileMenu: function($container, menu) {
					$('body').addClass('mobile-menu-active');
					$('.header-drawer-mobile').removeClass('active');
					$container
						.addClass('active')
						.find('.mobile-menu')
						.removeClass('active')
						.filter('.' + menu)
						.addClass('active');
					$('.header-drawer-mobile').animate({ scrollTop: 0 }, 200);
				},

				mobileNavScroll: function(scrollH) {
					if (scrollH < 0) {
						scrollH = 0;
					}

					if (scrollH > FJ_Header.lastScrollTop) {
						// Scrolling downward, hide secondary
						$('ul.nav-items').addClass('hidden-subnav');
						$('header.header').addClass('secondary-hidden');
					} else {
						// Scrolling upward, show secondary
						$('ul.nav-items').removeClass('hidden-subnav');
						$('header.header').removeClass('secondary-hidden');
					}

					if (scrollH > FJ_Header.lastScrollTop) {
						// Scrolling downward, hide secondary
						$('header.header').addClass('secondary-hidden-mobile');
					}

					FJ_Header.lastScrollTop = scrollH;
				},

				expandSearch: function () {
					var $search = $('.js-search'),
						$searchInput = $search.find('input[type="text"]'),
						$parent = $search.parents('.content-wrapper'),
						widthFull = $parent.outerWidth(),
						widthLeft = $parent.find('.header-main-left').outerWidth(),
						widthRight = $parent.find('.header-main-right').outerWidth(),
						roomToGrow = widthFull - widthLeft - widthRight - 10;

					$('body').addClass('mobile-menu-active');
					$('.js-search')
						.addClass('open')
						.width($search.outerWidth() + roomToGrow);
					$searchInput.focus();
				},

				searchFormValidation: function () {
					// Make sure the search field is not empty before submitting the form
					$('.js-search form').submit(function (e) {
						var $this = $(this),
							searchValue = $this.find('input[type="text"]').val().trim();

						if (searchValue === '') {
							e.preventDefault();
							return false;
						}
					});
				},

				expandAccountToggle: function($targ) {
					$('.account-toggle-wrapper').removeClass('active');
					$targ.addClass('active');
				},

				closeAccountToggles: function () {
					$('.account-toggle-wrapper').removeClass('active');
				},

				showHeaderDropdown: function(dropdown) {
					$('.header-dropdown.' + dropdown).addClass('active');
				},

				showHelpMenu: function () {
					$('.help-menu').addClass('active');
				},

				hideHelpMenu: function () {
					$('.help-menu').removeClass('active');
				},

				hideHeaderMenus: function(skipSearch) {
					// Desktop menus
					$('.header .header-drawer-desktop')
						.height(0)
						.find('.nav-menu')
						.removeClass('active');

					// Search Menus
					if (skipSearch !== true) {
						$('.js-search')
							.removeClass('open')
							.width(48);
					}

					// Generic Dropdown menus
					$('.js-dropdown-toggle').removeClass('active');
					$('.header-dropdown').removeClass('active');

					// Mobile menus
					$('body').removeClass('mobile-menu-active');
					$('.mobile-hamburger-toggle').removeClass('active');
					$('[data-mobile-menu-secondary]').removeClass('active');
					$('.header-drawer-mobile')
						.removeClass('active')
						.find('.active')
						.removeClass('active');
				}
			};

			$(document).ready(function () {
				FJ_Header.init();
				FJ_Header.initMobile();

			})

			// Desktop nav triggers
			.on('mouseenter', '.header .nav-items a', function () {
				FJ_Header.hideHeaderMenus(); // close other header menus

				FJ_Header.showDesktopMenu($(this).data('menu-trigger'));
			})
			.on('mouseleave', '.header', function () {
				FJ_Header.hideHeaderMenus();
			})

			// Mobile primary nav main triggers
			.on('click', '.mobile-hamburger-toggle:not(.active)', function(e) {
				e.preventDefault();
				$(this).addClass('active');
				FJ_Header.showMobileMenu($('.header-drawer-mobile.primary'), 'root');
			})
			.on('click', '.mobile-hamburger-toggle.active', function(e) {
				e.preventDefault();
				FJ_Header.hideHeaderMenus();
				$(".menu-category.level-1 .home-button").hide();
				$("li.active").removeClass("active");
				$("a.fa-chevron-up.active").removeClass("active");
				$("a.fa-chevron-up").removeClass("fa-chevron-up");
				$("a").addClass('fa-chevron-right');
				$("li:not(.active)").removeClass("hideme");
			})

			.on('click', '.js-hamburguer-mobile:not(.active)', function(e) {
				e.preventDefault();
				$('.mobile-hamburger-toggle').addClass('active');
			})

			.on('click', '.mobile-hamburger-toggle', function(e) {
				e.preventDefault();
				$('.account').removeClass('active');
				$('.mobile-hamburger-toggle').removeClass('open');
			})

			.on('click', '.mobile-hamburger-toggle', function(e) {
				e.preventDefault();
				$('.cart').removeClass('active');
				//$('.header-mobile-close.js-header-mobile-close.close-cart-button').removeClass('close-cart-button');
			})

			// Mobile secondary nav main triggers
			.on('click', '[data-mobile-menu-secondary]:not(.active)', function(e) {
				e.preventDefault();
				$('[data-mobile-menu-secondary]')
					.removeClass('active')
					.filter(this)
					.addClass('active');
				FJ_Header.showMobileMenu(
					$('.header-drawer-mobile.secondary'),
					$(this).data('mobile-menu-secondary')
				);
			})
			.on('click', '[data-mobile-menu-secondary].active', function(e) {
				e.preventDefault();
				FJ_Header.hideHeaderMenus();
			})

			// Mobile in-nav triggers
			.on('click', '[data-mobile-menu]', function(e) {
				e.preventDefault();
				FJ_Header.showMobileMenu(
					$(this).parents('.header-drawer-mobile'),
					$(this).data('mobile-menu')
				);
			})

			// Show / hide on mobile scroll
			.on('scroll', function () {
				if (window.location.hash == '#overview-anchor') {
					return;
				} else {
					FJ_Header.mobileNavScroll($(this).scrollTop());
				}
			})

			// Search expand / contract
			.on('click', '.js-expand-search', function(e) {
				e.preventDefault();
				FJ_Header.hideHeaderMenus(true);
				FJ_Header.expandSearch();
			})
			.on('click', '.js-close-search', function(e) {
				e.preventDefault();
				FJ_Header.hideHeaderMenus();
			})

			// Search suggestion toggles
			.on('focus', '.header-main .search input', function () {
				FJ_Header.hideHeaderMenus(true);
				FJ_Header.showHeaderDropdown('search');
			})
			.on('blur', '.header-main .search input', function () {
				FJ_Header.hideHeaderMenus();
			})


			// Account login / register toggles
			.on('click', '.account-toggle-wrapper:not(.active) .js-account-toggle-trigger', function(e) {
				e.preventDefault();
				FJ_Header.expandAccountToggle(
					$(this).parents('.account-toggle-wrapper')
				);
			})
			.on('click', '.account-toggle-wrapper.active .js-account-toggle-trigger', function(e) {
				e.preventDefault();
				FJ_Header.closeAccountToggles();
			})

			// Generic dropdown triggers
			.on('click', '.js-dropdown-toggle:not(.active)', function(e) {
				e.preventDefault();
				FJ_Header.hideHeaderMenus();
				$(this).addClass('active');
				FJ_Header.showHeaderDropdown($(this).data('dropdown'));
			})
			.on('click', '.js-dropdown-toggle.active', function(e) {
				e.preventDefault();
				FJ_Header.hideHeaderMenus();
			})

			// Help menu triggers
			.on('click', '.help-menu:not(.active) .js-help-menu-trigger', function(e) {
				e.preventDefault();
				FJ_Header.showHelpMenu();
			})
			.on('click', '.help-menu.active .js-help-menu-trigger', function(e) {
				e.preventDefault();
				FJ_Header.hideHelpMenu();
			})


			// Home/back button in Hamburger nav and LI toggling
			.on('click', '.menu-category.level-1 > li', function(e) {
				if (!$(this).hasClass('nav-mobile-only')) {
					$(this).siblings(":not(.active)").addClass("hideme");
					$(".menu-category.level-1 .home-button").show();
				}
			})
			.on('click', '.menu-category.level-1 .home-button', function(e) {
				$("li:not(.active)").removeClass("hideme");
				$(".menu-category.level-1 .home-button").hide();
				$("li.active").removeClass("active");
				$("a.fa-chevron-up.active").removeClass("active");
				$("a.fa-chevron-up").removeClass("fa-chevron-up");
				$("a").addClass('fa-chevron-right');
			});
			window.onload = function () {
			    document.getElementsByClassName('.menu-category.level-1 .home-button').onclick = function () {
					$("li:not(.active)").removeClass("hideme");
					$(".menu-category.level-1 .home-button").hide();
					$("li.active").removeClass("active");
					$("a.fa-chevron-up.active").removeClass("active");
					$("a.fa-chevron-up").removeClass("fa-chevron-up");
					$("a").addClass('fa-chevron-right');
				}
			}

			// hotspots
			$('body').on('click', '.fullpage-wrapper', function(e) {
				$('.js-hotspot-checkbox').prop('checked', false);
			})
			.on('click', '.fj-hotspot', function(e) {
				e.stopPropagation();
			}).on('click', '.js-hotspot-checkbox', function(e) {
				var $this = $(this)
					var $hotSpotRing = $this.closest('.fj-hotspot');

				if ($this.prop('checked') === true) {
					$hotSpotRing.addClass('hot-spot-active');
				} else {
					$hotSpotRing.removeClass('hot-spot-active');
				}
			});
		}
	}
};

module.exports = header;
