'use strict';

var util = require('../util');

/**
 * @private
 * @function
 * @description disable warranty specific form fields
 */
function disableWarrantySpecificFields() {
	$('[name="dwfrm_contactus_address_address1"]').val('NA');
	$('[name="dwfrm_contactus_address_address2"]').val('NA');
	$('[name="dwfrm_contactus_address_city"]').val('NA');
	$('[name="dwfrm_contactus_address_postal"]').val('11111');
	$('[name="dwfrm_contactus_address_phone"]').closest('.form-row').hide();
	$('#RegistrationForm .input-select.state').val('OTHER');
	$('.warranty-form-rows').hide();
}

/**
 * @private
 * @function
 * @description enable warranty specific form fields

 */
function enableWarrantySpecificFields() {
	var cCountry = $.trim($('.contact-us-country select').val());
	if ((cCountry == 'FR') || (cCountry == 'GB') || (cCountry == 'SE') || (cCountry == 'IE') || (cCountry == 'DE')) {
		$('[name="dwfrm_contactus_address_address1"]').val('');
		$('[name="dwfrm_contactus_address_address2"]').val('');
		$('[name="dwfrm_contactus_address_city"]').val('');
		$('[name="dwfrm_contactus_address_postal"]').val('');
		$('#RegistrationForm .input-select.state').val('');
		$('.warranty-form-rows').show();
		$('.form-field-tooltip').hide();
	} else {
		// Hide warranty specific fields
		disableWarrantySpecificFields();
	}
}

exports.init = function init () {
	// Hide warranty specific fields
	disableWarrantySpecificFields();
	$('.warranty-form-rows .country').parent().parent().remove();
	$('.warranty-form-rows .country').remove();
	$('.contact-us-country select option[label="' + Scripts.storeLocator.vars.defaultlocation.toUpperCase() + '"]').prop('selected', true);
	// Watch for subject 'Warranty Issues' to toggle warranty-form-rows
	$('.subject-selector select').on('change', function () {
		if ($(".subject-selector select").prop('selectedIndex') == 4) {
			// Show warranty specific fields
            // Do not show extra fields for all KJUS sites according to the AKEI-94
			// enableWarrantySpecificFields();
		} else {
			// Hide warranty specific fields
			disableWarrantySpecificFields();
		}
	});
	// Handle 'state' field based on country
	$('#RegistrationForm .input-select.country').on('change', function () {
		if ($(".subject-selector select").prop('selectedIndex') == 4) {
			enableWarrantySpecificFields();
			util.updateState($(this));
		} else {
			disableWarrantySpecificFields();
		}
	});
	$('#RegistrationForm .input-select.country').trigger('change');
};
