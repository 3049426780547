'use strict';
var imagesLoaded = require('imagesloaded'),
	util = require('../../util');

var recprod = {
    init: function () {
    	// jscs:disable
    	/***** Get The Stack Cranking *****/
		if ( typeof FJ_RProducts == 'undefined' ) {

			//object for organizing code
			var FJ_RProducts = {
				/**
				 * debounce - Use this like $( window ).resize( canvas.debounce( function(){}, 500 ) );
				 *
				 * @param function 	fn 		Pass in the function to call after the delay
				 * @param integer 	delay 	The time (in miliseconds) to delay the function from running
				 *
				 * @return function 		returns a function which calls passed in function when the delay has run out
				 */
				debounce: function( fn, delay ) {
					var timer = null;

					return function() {
						var context = this,
							args = arguments;

						clearTimeout( timer );

						timer = setTimeout( function() {
							fn.apply( context, args );
						}, delay );
					};
				},

				init: function() {
					FJ_RProducts.initSliders();
				},

				initSliders: function() {
                    $('.fj-rp-slider').not('.slick-initialized, .product-recs-slider').each(function() {
						var $this = $(this);
						var isCartRecommendation = $this.closest('.cart-recommendations').length > 0;
				
						$this.on('init', FJ_RProducts.equalizeSliderTitles)
							.slick({
								slidesToShow: 4,
								slidesToScroll: 1,
								arrows: true,
								prevArrow: '<button class="fj-rp-slider-arrow left" type="button"></button>',
								nextArrow: '<button class="fj-rp-slider-arrow right" type="button"></button>',
								dots: false,
								infinite: !isCartRecommendation,
								cssEase: 'linear',
								responsive: isCartRecommendation ? [
									{
										breakpoint: 768,
										settings: {
											slidesToShow: 2
										}
									}
								] : [
									{
										breakpoint: 1200,
										settings: {
											slidesToShow: 3
										}
									},
									{
										breakpoint: 768,
										settings: {
											slidesToShow: 2
										}
									},
									{
										breakpoint: 480,
										settings: {
											slidesToShow: 1,
											centerMode: true
										}
									}
								]
							});
					});
				},

				equalizeSliderTitles: function() {
					imagesLoaded( '.fj-rp-slider', { background: true }, function() {
						$('.fj-rp-slider').each( function () {
							$(this).find('.fj-rp-slide-title').height('auto').syncHeight();
						});
					});
				}
			};

			util.smartResize(function () {
				FJ_RProducts.equalizeSliderTitles();
			});

			$( document ).ready( function() {
				FJ_RProducts.init();
			} );
		}
    }
};

module.exports = recprod;
