'use strict';
var carousel = require('../../pd-components');

/**
 * @private
 * @function
 * @description Addresses timing of carousel init due to Einstein being loaded dynamically after load
 */

var einsteinTabs = {
    init: function () {
        var targets = $('.fj-recommended-products');
        if (!targets.length) return;
        var config = {attributes: false, childList: true, characterData: false};
        targets.each(function () {
            var observer = new MutationObserver(function () {
				carousel.init();
                observer.disconnect();
            });
			observer.observe($(this)[0], config);
        });

        $('.tabbed-recommendations .config-tab').on('click touchstart', function (e) {
            e.preventDefault();
            var tabTog = $(this).data("toggle");

            // reinit carousel on tab click
            carousel.init()

            //Toggle tab link
            $(this).children().addClass('active').parent().siblings().find('.nav-link-rec').removeClass('active');

            //Toggle target tab
            $('div.' + tabTog).addClass('active').siblings().removeClass('active');
            $('.tabbed-recommendations .fj-rp-slider').slick('refresh');


            $(this).parents().eq(1).siblings().find('.active').find('.fj-rp-slide-title').height('auto').syncHeight();
        });

        var $tabs = $('.tabs-wrapper .content-asset');
        if ($tabs.length > 1) {
            $('.tabs-wrapper').show();
        }

        $tabs.each(function() {
            $(this).parents().eq(1).addClass('tab-show');
        });

        var liCount = $('.nav-tabs-rec').find('.nav-tabs li').length;
        if (liCount > 0) {
            var count = 0;
            $('.nav-tabs-rec').find('.nav-tabs li').each(function () {
                var currentTabName = $(this).attr('data-toggle');
                var currentTabHtmlLength = $('.tabs-rec-content').find('.' + currentTabName).children().length;
                if (currentTabHtmlLength === 0) {
                    $(this).remove();
                    $('.tabs-rec-content').find('.' + currentTabName).remove();
                    count++;
                }
            });
            if (count === liCount) {
                $('.tabbed-layout-heading-rec').remove();
            }

            var firstVisibleElement = $('.nav-tabs-rec').find('.nav-tabs li:visible:first');

            if (!firstVisibleElement.find('a').hasClass('active')) {
                var tabTog = $(firstVisibleElement).data("toggle");
                //Toggle tab link
                $(firstVisibleElement).find('a').addClass('active').parent().siblings().find('a').removeClass('active');

                //Toggle target tab
                $('div.' + tabTog).addClass('active').siblings().removeClass('active');
            }
        }
    }
};


module.exports = einsteinTabs;
