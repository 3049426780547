'use strict';

var dialog = require('./dialog'),
	util = require('./util');

/**
 * @description Assign and handle interaction with the email subscription form
 * @param {Object} wrapper container with email sign up form
 * @param {Object} errorContainer div DOM node placeholder for error messages
 * @param {Boolean} override Accept original email or not
 */
var customdialog = {
	/**
	 * @function
	 * @description Looks for/Creates CookieDialog cookie. If it does not exist
	 *				it makes it set to seen and inits.
	 */
	init: function () {
		if (SitePreferences.PRIVACY_HINT === true && document.cookie.indexOf('cookiepolicy_accepted') < 0) {
			dialog.open({
				target: $('<div/>').attr('id', 'cookie-dialog').appendTo(document.body),
				url: Urls.privacyHint,
				options: {
					dialogClass: 'dialog-module-cookie do-not-close',
					closeText: Resources.ACCEPT,
					modal: false,
					closeOnEscape: false,
					width: '100%',
					resizable: false,
                    draggable: false,
					position: {
						my: 'left bottom',
						at: 'left bottom',
						of: window
					},
					close: enableCookies
				},
				callback: function () {
					$('.selectable.selected').children('a').focus();
				}
			});
		}

		function enableCookies() {
			if (document.cookie.indexOf('cookiepolicy=1') < 0) {
				document.cookie = 'cookiepolicy=1; path=/';
			}
			if (document.cookie.indexOf('cookiepolicy_accepted') < 0) {
				document.cookie = 'cookiepolicy_accepted=1; path=/';
			}
		}

        $('.pt_myjoys').on('click', '.current-locale', function (e) {
            e.preventDefault();
            var linkUrl = $(this).attr('href');
            dialog.open({
				url: Urls.localeWarning,
				options: {
					buttons: [{
						text: Resources.I_AGREE,
						click: function () {
							$(this).dialog('close');
							window.location.href = linkUrl;
						}
					}]
				}
			});
        });
	}
};

module.exports = customdialog;