'use strict';

var dialog = require('./dialog');

/**
 * @function youtube: Used to init the youtube API, if needed.
 *
 * Usage:	Within a content asset, a YouTube video can be added in two ways, shown below.
 * 			In both methods, replace the "XXXXXX" with the YouTube video ID, for ex: "yo7_qW4pPrc".
 *
 * 	Method #1:	adding a blank div with id of "youtube_XXXXXX"
 *
 *		<div class="ytvideo-container">
 *			<div id="youtube_XXXXXX"></div>
 *
 *			<div class="bannerContent videoOverlay">
 *				<p>Some promotional text</p>
 *				<a class="button white" id="XXXXXX-play-button">Learn More</a>
 *			</div>
 *		</div>
 *
 *	Method #2:	adding the full iframe snippet. must assign the iframe an id of "XXXXXX" (video id), and
 *				add the query string parameter "enablejsapi=1" to the src attribute.
 *
 *		<div class="ytvideo-container">
 *			<iframe width="560" height="315" src="https://www.youtube.com/embed/XXXXXX?enablejsapi=1" id="XXXXXX" frameborder="0" allowfullscreen></iframe>
 *
 *			<div class="bannerContent videoOverlay">
 *				<p>Some promotional text</p>
 *				<a class="button white" id="XXXXXX-play-button">Learn More</a>
 *			</div>
 *		</div>
 *
 *	Method #3:	insert a button that will launch a youtube video in a modal dialog on click.
 *				the button must have a class of "launch-youtube-modal", and at least one data
 *				attribute of data-videoid="XXXXXX". optional other data attributes are
 *				data-width="[nubmer]" and data-height="[number]"
 *
 *		<div class="button black launch-youtube-modal" data-videoid="_gBnmKOixDM" data-width="500" data-height="340">PLAY VIDEO</div>
 *
 **/
//Constants
var videoIdParam = 'id';
var videoContainerParam = 'container';

//Variable to check if function was initialised in case not to duplicate it
var youTubeApiInjected = false;

var init = function () {
	// To load the API script, or not to load the API script...
	if (youTubeApiInjected === false) {
		var $modalButtons = $('.launch-youtube-modal');
		if ($modalButtons.length !== 0 || 'youtubes' in window === true && window.youtubes.length > 0) {
			injectYouTubeAPI();
			$modalButtons.addClass('disabled'); //disable until YouTube API is ready.
			$(document).on('click', '.launch-youtube-modal', function () {
				modalLauncher($(this));
			});
		}
	}
};

//After the YouTube API script is loaded, create player events for each video found
var apiReady = function () {
	$('.launch-youtube-modal').removeClass('disabled');
	var players = {};
	for (var i in window.youtubes) {
		var videoID = window.youtubes[i][videoIdParam];
		var containerID = window.youtubes[i][videoContainerParam] === 'div' ? 'youtube_' + videoID : videoID;
		// create the a global player for each iframe
		players[videoID] = new window.YT.Player(containerID, {
			videoId: videoID,
			playerVars: {
				'rel': 0
			},
			events: {
				// call this function when player is ready to use
				'onReady': onPlayerReady
			}
		});
	}
};

//Insert YouTube API script at the top of the page
function injectYouTubeAPI () {
	var tag = document.createElement('script');
	tag.src = '//www.youtube.com/iframe_api';
	var firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

	youTubeApiInjected = true;
}

function modalLauncher ($this) {
	if ($this.hasClass('disabled')) {
		return;
	}
	var vidID = $this.data('videoid');
	if (vidID) {
		var thisPlayer;
		var vwidth = $this.data('width') || 738; //640;
		var vheight = $this.data('height') || 415; // 390; //vwidth !== 760 ? 200 : 415;
		var params = $this.data('params') || {};
		var playerDivID = 'youtube_modal_player_' + vidID;
		var $pl = $('<div>').attr('title', '').append($('<div>').attr('id', playerDivID).css({width: vwidth + 'px', height: vheight + 'px'}).html('<center>Loading video...</center>'));
		dialog.open({
			html: $pl,
			options: {
				autoOpen: true,
				width: vwidth + 30, // compensate for padding
				dialogClass: 'video', // or 'youtube-modal-dialog' is styled with black bg and no padding
				open: function () {
					var youtubeInitialization = setInterval(function () {
						thisPlayer = new window.YT.Player(playerDivID, {
							videoId: vidID,
							width: vwidth,
							height: vheight,
							playerVars: params,
							events: {
								'onReady': function () {
									clearInterval(youtubeInitialization);
								}
							}
						});
					}, 1000);
				},
				close: function () {
					var playerContainer = $('#' + playerDivID);
					playerContainer[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
					playerContainer.remove();
				}
			}
		});
	}
}
function onPlayerReady (e) {
	// bind events
	var vid = e.target.h.j.videoId;
	var playButton = document.getElementById(vid + '_play_button');
	if (playButton !== null) {
		playButton.addEventListener('click', function () {
			if (!this.classList.contains('isplaying')) {
				e.target.playVideo();
				this.classList.add('isplaying');
			} else {
				e.target.pauseVideo();
				this.classList.remove('isplaying');
			}
		});
	}
	var pauseButton = document.getElementById(vid + '_pause_button');
	if (pauseButton !== null) {
		pauseButton.addEventListener('click', function () {
			e.target.pauseVideo();
			this.classList.remove('isplaying');
		});
	}
}


module.exports = init;
window.onYouTubeIframeAPIReady = apiReady;
