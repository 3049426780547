'use strict';

var imagesLoaded = require('imagesloaded'),
	util = require('./util'),
	quickview = require('./quickview');

function productTileHover() {
    var $searchResults = $('.pt_product-search-result');
    $searchResults.on('mouseenter mouseleave', '.thumb-link', function (event) {
        var secondaryImg = $(this).data('secondary-image'),
            mainImage = $(this).find('img').attr('src');
        if (!secondaryImg) { return; }
        $(this).data('secondary-image', mainImage);
        $(this).find('img').attr('src', secondaryImg);
        event.stopImmediatePropagation();
    });
	$searchResults.on('touchstart', '.thumb-link', function () {
		var link = $(this).attr("href");
		window.location.href = link;
	});
}

function gridViewToggle() {
	$('.toggle-grid').on('click', function () {
		$('.search-result-content').toggleClass('wide-tiles');
		$(this).toggleClass('wide');
	});
}

function swatchImageCkick() {
	$('.swatch-list').on('mouseleave', function () {
		// Restore current thumb image
		var $tile = $(this).closest('.product-tile'),
			$thumb = $tile.find('.product-image .thumb-link img').eq(0),
			data = $thumb.data('current');

		if (data && data.hasOwnProperty('src')) {
			$(this).find('.swatch.selected').removeClass('selected');
			$(this).find('.swatch.original').addClass('selected');

			$thumb.attr({
				src: data.src,
				alt: data.alt,
				title: data.title
			});
		}
	});
	$('.swatch-list .swatch').on('click', function (e) {
		if (!$(this).closest('.product-listing').length) { return; }
		e.preventDefault();
		if ($(this).hasClass('selected')) { return; }

		var $tile = $(this).closest('.product-tile');
		var $list = $(this).closest('.swatch-list');
		$list.find('.swatch.selected').removeClass('selected');
		$list.find('.swatch.original').removeClass('original');
		$(this).addClass('selected original');
		$tile.find('.thumb-link').attr('href', $(this).attr('href'));
		$tile.find('.name-link').attr('href', $(this).attr('href'));

		var data = $(this).children('img').filter(':first').data('thumb');
		var $gridTile = $(this).closest('.grid-tile');
		var productName = data.productName.length < 28 ? data.productName : data.productName.slice(0, 28) + '...';
		$gridTile.find('.name-link').text(productName);

		$.ajax({
			dataType: 'html',
			url: Urls.getProductTilePrice,
			data: 'pid=' + data.productID,
			xhrFields: {
				withCredentials: true
			},
			success: function (data) {
				$gridTile.find('.product-pricing').empty().html(data);
			}
		});

		var $thumb = $tile.find('.product-image .thumb-link img').eq(0);
		var currentAttrs = {
			src: data.src,
			alt: data.alt,
			title: data.title
		};
		$thumb.attr(currentAttrs);
		$thumb.data('current', currentAttrs);

        var $a = $tile.find('.product-image .thumb-link').eq(0);
        $a.data('secondary-image', data.secondaryImage);

	}).on('mouseenter', function () {
		// get current thumb details
		var $tile = $(this).closest('.product-tile'),
			$thumb = $tile.find('.product-image .thumb-link img').eq(0),
			data = $(this).children('img').filter(':first').data('thumb'),
			$selected = $tile.find('.swatch.selected'),
			selectedData = $selected.data('thumb') || data,
			current = $thumb.data('current'),
            mainsrc = data.src;
            if (util.getViewport() > 1680) {
                mainsrc = data.srcxl;
            } else if (util.getViewport() > 1160 && util.getViewport() < 1679) {
                mainsrc = data.srclg;
            } else {
                mainsrc = data.src;
            }

		// If this is the first time, then record the current img
		if (!current) {
			$(this).addClass('original');
			$thumb.data('current', {
				src: selectedData.src,
				alt: selectedData.alt,
				title: selectedData.title
			});
		}

		// move the outline to the hovered swatch
		$selected.removeClass('selected');
		$(this).addClass('selected');

		// Set the tile image to the values provided on the swatch data attributes
		$thumb.attr({
			src: data.mainsrc,
			srcset: mainsrc,
			alt: data.alt,
			title: data.title
		});
	});
}

/**
 * @private
 * @function
 * @description Toggles full swatch visibility on product tile, as default is collapsed, only showing 4 swatches max
 */
function showMoreSwatch() {
	$('.product-swatches').off('click', 'ul li.open-close');
	$('.product-swatches').on('click', 'ul li.open-close', function (e) {
		if (!$(this).parents('.pt_product-search-result').length) {
			e.preventDefault();
			$(this).parents('.product-swatches').toggleClass('expanded');
		}
	});
}

function initUnselectedSwatches() {
	$('.swatch-list').each(function () {
		if ($(this).find('.swatch').length > 0 && $(this).find('.swatch.selected').length === 0) {
			$(this).find('.swatch').eq(0).trigger('click');
		}
	});
}

/**
 * @private
 * @function
 * @description Initializes events on the product-tile for the following elements:
 * - swatches
 * - thumbnails
 */
function initializeEvents() {
    productTileHover();
	gridViewToggle();
	showMoreSwatch();
	swatchImageCkick();
	initUnselectedSwatches();
	dataLayer.push({'event': 'optimize.activate'});
	console.log('optimize.activate');
}

/**
 * @private
 * @function
 * @description Toggle title filter panel on PLP
 */
function toggleRefineTitle() {
	var isHideRefinements = $('body').hasClass('hide-refinements');
	var isMobileRefinementsActive = $('body').hasClass('mobile-refinements-active');
	var refinementsToggle = $('#refinementsToggle');
	var buttonTextDesktop = isHideRefinements ? refinementsToggle.data('buttonTextShow') : refinementsToggle.data('buttonTextHide');
	var buttonTextMobile = isMobileRefinementsActive ? refinementsToggle.data('buttonTextHide') : refinementsToggle.data('buttonTextShow');
	refinementsToggle.find('.toggle-refine-title.display-desktop-only').html(buttonTextDesktop);
	refinementsToggle.find('.toggle-refine-title.display-mobile-only').html(buttonTextMobile);
}

exports.init = function () {
	var $tiles = $('.tiles-container .product-tile');
	if ($tiles.length === 0) { return; }
	initializeEvents();
	toggleRefineTitle();
};
module.exports.showMoreSwatch = showMoreSwatch;
module.exports.swatchImageCkick = swatchImageCkick;
