'use strict';

/**
 * @function
 * @description Initializes the tooltip-content and layout
 */
exports.init = function () {
	if ($(document).tooltip) {
		$(document).tooltip({
			items: '.tooltip',
			track: true,
			content: function () {
				return $(this).find('.tooltip-content').html();
			}
		});
		$('body').on('click', '.close-tooltip' , function (e) {
			e.preventDefault();
			$('.tooltop').tooltip('close');
		});
		// if tooltip link is clicked, do not send user to the page
		$('body').on('click', '.pt_checkout .tooltip' , function (e) {
			e.preventDefault();
		});
		$('.share-link').on('click', function (e) {
			e.preventDefault();
			var target = $(this).data('target');
			if (!target) {
				return;
			}
			$(target).toggleClass('active');
		});
		$('.pdp-spec-list-item-title').tooltip({
			items: '.tooltip-item',
			tooltipClass: "custom-tooltip-item",
			position: {
				my: "left+10 bottom-8",
				at: "right+10 bottom-8"
			},
			track: true,
			content: function () {
				return $(this).find('.tooltip-content').html();
			}
		});
		var vw = window.innerWidth;
		if (vw > 879) {
			$('.tooltip-item').addClass('desktop');
			$('.tooltip-item').removeClass('mobile');
		} else {
			$('.tooltip-item').removeClass('desktop');
			$('.tooltip-item').addClass('mobile');
		}
		$(document).on('click', '.pdp-spec-list .tooltip-item-icon', function () {
			var vw = window.innerWidth;
			if (vw < 880) {
				$(this).closest('.tooltip-item').addClass('show');
			}
		});
		$(document).on('click', '.pdp-spec-list .tooltip-item-close', function () {
			$(this).closest('.tooltip-item').removeClass('show');
		});
		$(document).on('click', '.pdp-spec-list .tooltip-content-layer', function () {
			$(this).closest('.tooltip-item').removeClass('show');
		});
		$(window).on('resize', function () {
			var vw = window.innerWidth;
			if (vw > 879) {
				$('.tooltip-item').addClass('desktop');
				$('.tooltip-item').removeClass('mobile show');
			} else {
				$('.tooltip-item').removeClass('desktop');
				$('.tooltip-item').addClass('mobile');
			}
		});
	}
};
