'use strict';

var addToCart = require('./addToCart'),
	ajax = require('../../ajax'),
	image = require('./image'),
	tooltip = require('../../tooltip'),
	util = require('../../util');
// function toggle () {
// 	$('.must-select').each(function () {
// 		$(this).find('.header-toggle').html($(this).find('li.selected').html());
// 		$(this).find('.content').hide();
// 	});
// 	$('.must-select li.selected').closest('.must-select').removeClass('.not-selected').addClass('selected');
// 	$('.header').click(function () {
// 		var that = this;
// 		//$('.content:visible').slideUp('slow');
// 		$('.header span.expanded')
// 			.removeClass('expanded')
// 			.addClass('collapsed');
// 		$(this)
// 			.next('.content:hidden')
// 			.slideDown('slow', function () {
// 				$('span.collapsed', that)
// 					.removeClass('collapsed')
// 					.addClass('expanded');
// 			});
// 	});
// }
module.exports = function () {
	var $addToCart = $('#add-to-cart'),
		$addAllToCart = $('#add-all-to-cart'),
		$productSetList = $('#product-set-list');

	var updateAddToCartButtons = function () {
		if ($productSetList.find('.add-to-cart[disabled]').length > 0) {
			$addAllToCart.attr('disabled', 'disabled');
			// product set does not have an add-to-cart button, but product bundle does
			$addToCart.attr('disabled', 'disabled');
		} else {
			$addAllToCart.removeAttr('disabled');
			$addToCart.removeAttr('disabled');
		}
	};

	if ($productSetList.length > 0) {
		updateAddToCartButtons();

		// delete for product sets
		$('#design-your-own-swatch').remove();
	}

	var $pdpMain = $('.pdp-main');

	var updateContent = function (href, container, thisElement) {
		var qty = container.find('form input[name="Quantity"]').first().val();
		ajax.load({
			url: util.appendParamToURL(href, 'Quantity', isNaN(qty) ? '1' : qty),
			target: container,
			callback: function (data) {
				if (data) {
					updateAddToCartButtons();
					addToCart(container);
					tooltip.init();
					// toggle();
					if (thisElement) {
						var largeImg = thisElement.data('lgimg');
						if (!largeImg) { return; }
						image.setMainImage(largeImg);
					}
					// delete for product sets
					$('#design-your-own-swatch').remove();
				}
			}
		});
	};



	// click on swatch for product set
	$productSetList.on('click', '.product-set-item .swatchanchor', function (e) {
		e.preventDefault();
		if ($(this).parents('li').hasClass('unselectable')) { return; }
		var container = $(this).closest('.product-set-item');
		if (util.getViewport() >= 880) {
			var $thumbnails = $('#thumbnails');
			$thumbnails.find('.thumb').removeClass('selected');
		}
		var res = $(this).attr('href').split('Product-Variation')[1];
		var url = Urls.getSetItem + res;
		// set the main image
		updateContent(url, container, $(this));
	});
	$productSetList.on('click', '.product-set-details .custom-select li', function () {
		var container = $(this).closest('.product-set-item');
		var res = $(this).attr('value').split('Product-Variation')[1];
		var url = Urls.getSetItem + res;
		updateContent(url, container);
	});
};
