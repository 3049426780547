'use strict';

function initializeEvents() {
	$(document).on('click', '#havs-edit-address', function (e) {
		e.preventDefault();
		$('.newAddress').slideToggle('slow');
		$('.oldAddress').slideToggle('slow');
	});
}

exports.init = function () {
	initializeEvents();
};