'use strict';

var footer = {
    init: function () {
    	// jscs:disable
    	if ( typeof FJ_Footer == 'undefined' ) {
			//object for organizing code
			var FJ_Footer = {
				/**
				 * debounce - Use this like $( window ).resize( canvas.debounce( function(){}, 500 ) );
				 *
				 * @param function 	fn 		Pass in the function to call after the delay
				 * @param integer 	delay 	The time (in miliseconds) to delay the function from running
				 *
				 * @return function 		returns a function which calls passed in function when the delay has run out
				 */
				debounce: function( fn, delay ) {
					var timer = null;

					return function() {
						var context = this,
							args = arguments;

						clearTimeout( timer );

						timer = setTimeout( function() {
							fn.apply( context, args );
						}, delay );
					};
				},

				init: function() {
					$( '.fj-main-slider' ).click( function( e ) {
						if ( e.target !== $( '.js-footer-newsletter' )[0] ) {
							$( '.js-footer-newsletter' ).blur();
						}
					} );
				}
			};

			$( document ).ready( function() {
				FJ_Footer.init();
			} );
		}
		$(document).on('click', '.js-footer__links__title__collapse-button', function() {
			$(this).toggleClass('collapsed');
			$(this).parents('.footer__links__wrapper').find('.footer__links').toggleClass('collapsed');
		});
    }
};

module.exports = footer;