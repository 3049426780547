'use strict';

var dialog = require('../../dialog'),
	minicart = require('../../minicart'),
	bonusProductsView = require('../../bonus-products-view'),
	page = require('../../page'),
	util = require('../../util'),
	addToCartPopup = require('./addToCartPopup'),
	membersOnlyModal = require('./membersOnlyModal'),
	TPromise = require('promise'),
	_ = require('lodash');

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
var addItemToCart = function (form) {
	var $form = $(form),
		$qty = $form.find('select[name="Quantity"]');
	if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
		$qty.val('1');
	}
	var addProductUrl = Urls.addProduct;
	// Get url for adding GC custom product
	if ($('#personalize').length > 0 && $('#personalize').data('url')) {
		addProductUrl = $('#personalize').data('url');
	}
	return TPromise.resolve($.ajax({
		type: 'POST',
		url: util.ajaxUrl(addProductUrl),
		data: $form.serialize()
	}));
};

/**
 * @description Make the AJAX request to add a MyJoys item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
var addMyJoysItemToCart = function (form) {
	var $form = $(form),
		$qty = $form.find('select[name="Quantity"]');
	if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
		$qty.val('1');
	}
	return TPromise.resolve($.ajax({
		type: 'POST',
		url: util.ajaxUrl(Urls.addMyJoysFromWishlist),
		data: $form.serialize()
	}));
};


/**
 * @description Handler to handle the add to cart event
 */
var addToCart = function (e) {
	e.preventDefault();
	var $this = $(this);

	if ($this.hasClass('members-only')) {
		membersOnlyModal();
		return false;
	}

	if ($this.hasClass('disabled') || $this.is(':disabled')) {
        var $warningBoxes = $this.closest('.product-detail').find('ul.warning-box');
        if ($warningBoxes.length) {
            var unselectedValues = [];
            $warningBoxes.each(function () {
                if (!$(this).find('.selected').length) {
                    unselectedValues.push($(this).closest('.must-select').attr('data-attribute'));
                    $(this).addClass('error');
                    if ($(this).closest('.custom-select-field').find('.select-header').length) {
                        $(this).closest('.custom-select-field').find('.select-header').addClass('error');
                    }
                }
            });
            $('.low-inventory-msg').text('Please select a ' + (unselectedValues.length === 2 ? unselectedValues.join(' and ') : unselectedValues.join(', ')));
            $(this).blur();
        }

		if ($this.is('#add-gc-to-cart')) {
			var $form = $this.parents('form');
			$form.valid();
		}
		return false;
	}

	if ($this.hasClass('wedgeworks')) {
		window.location.href = $('input[name="customizeURL"]').val();
		return false;
	}

	var $form = $this.closest('form');
	if ($form.length === 0) {
		$form = $(this.form);
	}
	addItemToCart($form).then(function (response) {
		var $uuid = $form.find('input[name="uuid"]');
		// for A/B testing
		if ($('.mobile-a2c-participant').length && util.getViewport() < 768) {
			mobileAddToCartIntercept();
			return;
		}
		if ($uuid.length > 0 && $uuid.val().length > 0 || window.pageContext.ns === 'cart' || window.pageContext.ns === 'checkout' || window.pageContext.ns === 'configurator' || window.pageContext.ns === 'customizer') {
			page.redirect(Urls.cartShow);
		} else {
			// do not close quickview if adding individual item that is part of product set
			// @TODO should notify the user some other way that the add action has completed successfully
			if (!$(this).hasClass('sub-product-item')) {
				dialog.close();
			}
			if (response.indexOf('max-quantity-error') >= 0) {
				dialog.open({
					url: Urls.maxQuantityError
				});
			} else {
				var showMiniCard = true;
                if ($this.hasClass('showAddToCartPopup')) {
					$this.html(response);
					var pliuuid = $this.find('.pliuuid');
					var pliqty = $this.find('.pliqty');
					if (pliuuid.length > 0) {
						addToCartPopup.show(pliuuid.text(), pliqty.text());
					}
					showMiniCard = false;
                }
				if (util.getViewport() >= 768 || $('body.enable-slideout-cart').length) {
					minicart.show(response, showMiniCard);
				}
				if (util.getViewport() < 768 && !document.querySelector('.bonus-discount-container') && showMiniCard && !$('body.enable-slideout-cart').length) {
					document.cookie = 'addedtocart=1; path=/';
					page.redirect(Urls.cartShow);
				}
			}
		}
	}.bind(this));
};

/**
 * @description Open mobile cart intercept dialog
 */
var mobileAddToCartIntercept = function () {
	dialog.open({
		url: Urls.mobileAddToCartIntercept,
		options: {
			dialogClass: 'cart-intercept',
			open: function () {
				$('.mobile-a2c-intercept-continue').on('click', function () {
					dialog.close();
				});
				$('.mobile-a2c-intercept-cart').on('click', function () {
					page.redirect(Urls.cartShow);
				});
			}
		}
	});
};

/**
 * @description Handler to handle the add to cart event
 */
var addMyJoysToCart = function (e) {
	e.preventDefault();
	var $form = $(this).closest('form');
	if ($form.length === 0) {
		$form = $(this.form);
	}
	addMyJoysItemToCart($form).then(function (response) {
		var $uuid = $form.find('input[name="uuid"]');
		if ($uuid.length > 0 && $uuid.val().length > 0 || util.getViewport() < 768) {
			page.redirect(Urls.cartShow);
		} else {
			// do not close quickview if adding individual item that is part of product set
			// @TODO should notify the user some other way that the add action has completed successfully
			if (!$(this).hasClass('sub-product-item')) {
				dialog.close();
			}
			minicart.show(response);
		}
	}.bind(this));
};


/**
 * @description Handler to handle the add all items to cart event
 */
var addAllToCart = function (e) {
	e.preventDefault();
	var $productForms = $('#product-set-list').find('form').toArray();
	TPromise.all(_.map($productForms, addItemToCart))
		.then(function (responses) {
			dialog.close();
			if (util.getViewport() < 768) {
				page.redirect(Urls.cartShow);
			} else {
				// show the final response only, which would include all the other items
				minicart.show(responses[responses.length - 1]);
			}
		});
};

/**
 * @function
 * @description Binds the click event to a given target for the add-to-cart handling
 * @param {Element} target The target on which an add to cart event-handler will be set
 */
module.exports = function (target) {
	$('.add-to-cart[disabled], .add-to-cart.disabled').attr('title', $('.availability-msg').text());

	if (target) {
		target.off('click');
		target.on('click', '.add-to-cart', addToCart);
		target.on('click', '.add-myjoys-to-cart', addMyJoysToCart);
	} else {
		$('.add-to-cart').off('click').on('click', addToCart);
		$('.add-myjoys-to-cart').on('click', addMyJoysToCart);
	}

	$('#add-all-to-cart').off('click').on('click', addAllToCart);
};
