'use strict';

/**
 * @private
 * @function
 * @description Binds the events
 */
function initializeEvents() {
    // FI checkboxes handler
    $('#FIInterests input[type="checkbox"]').change(function () {
        var isAnyChecked = $('#FIInterests input[type="checkbox"]:checked').length > 0;
        if ($('input[name*="_addtoemaillist"]').length > 0) {
            $('input[name*="_addtoemaillist"]').prop('checked', isAnyChecked);
            $('input[name*="_addtoemaillist"]').attr('checked', isAnyChecked);
        }
    });
    $('input[name*="_addtoemaillist"]').change(function () {
           var checked = $(this).is(':checked');
           if ($('#FIInterests input[type="checkbox"]').length > 0) {
               $('#FIInterests input[type="checkbox"]').prop('checked', checked);
               $('#FIInterests input[type="checkbox"]').attr('checked', checked);
           }
    });
}

var orderconfirmation = {
    init: function () {
        initializeEvents();
    }
};

module.exports = orderconfirmation;
