'use strict';

require('../../touch')(); //touch events

var dialog = require('../../dialog'),
	imagesLoaded = require('imagesloaded'),
	util = require('../../util');

var resources = 'ConfiguratorResources' in window ? window.ConfiguratorResources : {};
var scene7TemplateNames = resources.PREF_SLIDE_NAMES || null; //['Nameplate','Standard','NorthPole','SouthPole'];
var $slickContainer = $('#primary .configurator-main-image');
var startOnSlide = 0;
var updateImagesQueue = [];
var brokenImageURL = $('.configurator-main-image').data('brokenimgurl');
var $pageLogoCtnr = $('.configurator-productname');
var logoSelector = '.ball-logo';

var init = function (callback) {
	var imgLoad = imagesLoaded($slickContainer);
	imgLoad.on('fail', function (imgs) {
		replaceBrokenImages(imgs);
	});
	imgLoad.on('always', function () {
		//init imagery
		initCaro(startOnSlide, function () {
			//init zoom after finished slicking, just in case
			initZoom(function (success) {
				if (typeof callback === 'function') {
					callback(success);
				}
			});
		});
	});
};

var initCaro = function (initialSlide, callback) {
	$slickContainer.on('init', function (e, slick) {
		if (typeof callback === 'function') {
			callback(e, slick);
		}
	});
	$slickContainer.slick({
		arrows: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		swipe: true,
		touchMove: true,
		initialSlide: initialSlide,
		waitForAnimate: false
	});
	$slickContainer.on('afterChange', function (e, slick, index) {
		startOnSlide = index; //remember where the user last left off
		initZoom();
	});
};

var initZoom = function (callback) {
	callback = (typeof callback === 'function') ? callback : function () {};
	var zoomMediaQuery = matchMedia('(min-width: 960px)');
	var $imgZoom = $('#primary .main-image.slick-active'),
		hiresUrl;
	if ($imgZoom.length === 0 || dialog.isActive() || util.isMobile() || !zoomMediaQuery.matches) {
		// remove zoom
		$imgZoom.trigger('zoom.destroy');
		callback(false);
		return;
	}
	hiresUrl = $imgZoom.attr('href');

	if (hiresUrl && hiresUrl !== 'null' && hiresUrl.indexOf('noimagelarge') === -1 && zoomMediaQuery.matches) {
		$imgZoom.zoom({
			url: hiresUrl,
			callback: function () {
				callback(true);
			}
		});
	} else {
		callback(false);
	}

	zoomMediaQuery.addListener(initZoom);
};

var gotoSlide = function (slideName) {
	if (typeof slideName === 'number') {
		// if a number was passed in, go to that number index in the carousel
		$slickContainer.slick('slickGoTo', slideName);
	} else if (typeof slideName === 'string' && scene7TemplateNames.indexOf(slideName) > -1) {
		//if string, set slide index for a given template name
		$slickContainer.slick('slickGoTo', scene7TemplateNames.indexOf(slideName));
	}
};

var replaceAndInit = function (newImages, slideName) {
	if (!!newImages || newImages.length > 0) {

		//if we are currently in the middle of an image refresh, queue this update
		if ($slickContainer.hasClass('loading-indicator')) {
			updateImagesQueue = newImages;
			return;
		}

		//show loading indicator
		$slickContainer.addClass('loading-indicator');

		//set up a temporary, hidden div, inject all Scene7 images, and allow them fully load before swapping out carousel images.
		var $preloadDiv = $('#preload-scene7-images').length ? $('#preload-scene7-images') : $('<div/>').attr('id', 'preload-scene7-images').appendTo(document.body);
		var width = $slickContainer.find('img.primary-image').width();
		if (!width || width === 0) {
			width = 840;
		}
		for (var i = 0; i < newImages.length; i++) {
			//set the carousel image width, compensating for hi-res screens
			var src = util.appendParamToURL(newImages[i], 'wid', width * 2);
			//set the zoom image size
			var hires = util.appendParamToURL(newImages[i], 'wid', 1400);
			var title = !!scene7TemplateNames && !!scene7TemplateNames[i] ? scene7TemplateNames[i] : '';
			var tag = '<a href="' + hires + '" target="_blank" class="product-image main-image" title="' + title + '"><img class="primary-image" src="' + src + '" title="' + title + '"/></a>';
			$preloadDiv.append(tag);
		}
		/* if we need to digest an associative array, use the following code
		for (var imgType in newImages) {
			var tag = '<a href="' + newImages[imgType] + '" target="_blank" class="product-image main-image" title="' + imgType + '"><img class="primary-image" src="' + newImages[imgType] + '"/></a>';
			$slickContainer.append(tag);
		}
		*/
		var imgLoad = imagesLoaded($preloadDiv);
		imgLoad.on('always', function () {

			//replace all images and re-init slick carousel
			$slickContainer.slick('unslick');
			$slickContainer.html($preloadDiv.find('a').remove());

			//set startOnSlide to the index a given template name
			if (!!slideName && scene7TemplateNames.indexOf(slideName) > -1) {
				startOnSlide = scene7TemplateNames.indexOf(slideName);
			}

			//re-initialize the carousel and zoom functionality
			init();

			//remove loading indicator
			$slickContainer.removeClass('loading-indicator');

			//if we had an image update queued, execute this now
			if (updateImagesQueue.length) {
				var newImages = updateImagesQueue;
				updateImagesQueue = []; //reset queue
				replaceAndInit(newImages);
			}
			return;
		});
		imgLoad.on('fail', function (imgs) {
			replaceBrokenImages(imgs);
		});
	}
};

var replaceBrokenImages = function (imgs) {
	if (!imgs) {
		return;
	}
	var $errortxt = $('.configurator-controls').find('.config-tab-content.open .error-msg').html('');
	for (var i = 0; i < imgs.images.length; i++) {
		var img = imgs.images[i];
		if (!!img && !img.isLoaded) {
			$(img.img).attr('src', brokenImageURL);
			var $error = $('<p></p>').html('Error loading preview image for <em>' + $(img.img).attr('title') + '</em> face');
			$errortxt.append($error);
		}
	}
};

var replaceLogo = function (selection) {
	if (!!selection && $(selection).length) {
		var $newlogoimg = $(selection).siblings('.config-option-label').find(logoSelector);
		var $pagelogo = $pageLogoCtnr.find(logoSelector);
		if ($newlogoimg.length && $pagelogo.length) {
			$pagelogo.replaceWith($newlogoimg);
		}
	}
};

module.exports = {
	init: init,
	replaceCaroImages: replaceAndInit,
	gotoSlide: gotoSlide,
	replaceLogo: replaceLogo
};
