'use strict';

var dialog = require('../../dialog'),
	productStoreInventory = require('../../storeinventory/product'),
	tooltip = require('../../tooltip'),
	sizeguide = require('../../sizeguide'),
    embroidery = require('../../embroidery'),
	util = require('../../util'),
	addToCart = require('./addToCart'),
	customize = require('./customize'),
	availability = require('./availability'),
	image = require('./image'),
	productNav = require('./productNav'),
	productSet = require('./productSet'),
	recommendations = require('./recommendations'),
	membersOnlyModal = require('./membersOnlyModal'),
	variant = require('./variant'),
	imagesLoaded = require('imagesloaded'),
	ajax = require('../../ajax');

/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom () {
	productNav();
	recommendations();
	tooltip.init();
	membersOnlyModal();
	$('.pdp-power-reviews').tabs();
}

function showMoreSwatchPDP () {
	$('.product-swatches').off('click', 'ul li.open-close');
	$('.product-swatches').on('click', 'ul li.open-close', function (e) {
		if (!$(this).parents('.pt_product-search-result').length) {
			e.preventDefault();
			$(this).parents('.product-swatches').toggleClass('expanded');
		}
	});
}

function stickyElementsInit () {
	if (util.getViewport() >= 880 && $('#pdpMain .product-set-container').length) {
		var window_top = $(window).scrollTop();
		var footer_top = $('#main').siblings('footer').offset().top;
		var div_top = $('.product-col-2.product-set').offset().top - 118;
		var div_height = $('.product-col-1.product-set').height();

		if (window_top + div_height + 118 > footer_top) {
			$('.product-col-1.product-set').removeClass('sticky-image');
		} else if (window_top > div_top) {
			$('.product-col-1.product-set').addClass('sticky-image');
		} else {
			$('.product-col-1.product-set').removeClass('sticky-image');
		}
	}

	if (util.getViewport() <= 880) {
		var offsetTop = $('.product-add-to-cart').offset().top;
		var windowTop = $(window).scrollTop();
		if (util.elementInViewport($('.product-add-to-cart').get(0), 70) || offsetTop > windowTop) {
			$('.product-add-to-cart-sticky').removeClass('product-add-to-cart-sticky-visible')
		} else {
			$('.product-add-to-cart-sticky').addClass('product-add-to-cart-sticky-visible')
		}
	}
}

function initProductTopData () {
    var $productMainContainer = $('.product-main-info'),
        $productTopData = $('.product-detail .product-top-data');
    if ($('.product-main-info .pdp-hero').length < 1) {
        $('.product-main-info .breadcrumbs-mobile').addClass('short');
    };
    if ($productMainContainer.length) {
        if (util.getViewport() < 880) {
            if ($productTopData.length) {
                $productTopData.prependTo($productMainContainer);
            }
        } else {
            if (!$productTopData.length) {
                $productTopData = $('.product-top-data');
                $productTopData.prependTo($('#product-content'));
            }
        }
    }
}

// product content slot horizonal-carousels
// added to window for CQuotient, self-executing for fallback
(window.pdpRecommendationsInit = function () {
	var $hCarousel = $('.product-listing-1x4 .search-result-items');
	// do not initialize twice
	if ($hCarousel.hasClass('slick-slider')) {
		return;
	}
	var $slideItems = $hCarousel.find('.grid-tile').length;
	var $slidesToShow;
	if (window.innerWidth < 768) {
		$slidesToShow = 2;
		$('.progress').addClass('mobile').removeClass('desktop');
	}
	if (window.innerWidth > 767) {
		$slidesToShow = 4;
		$('.progress').addClass('desktop').removeClass('mobile');
	}

	$hCarousel.slick({
		slidesToScroll: 1,
		slide: '.grid-tile',
		slidesToShow: 4,
		swipeToSlide: true,
		touchThreshold: 20,
		touchMove: true,
		infinite: false,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2
				}
			}
		]
	});

	$('.product-listing-1x4 .search-result-items').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
		if (nextSlide >= currentSlide && nextSlide != (slick.slideCount - 1)) {
			$(".progress").slider("option", "value", (currentSlide + 1));
		} else {
			$(".progress").slider("option", "value", nextSlide);
		}
	});
	$('.product-listing-1x4 .search-result-items').on('afterChange', function (event, slick, currentSlide) {
		if (currentSlide !== $(".progress").slider("value")) {
			$(".progress").slider("option", "value", currentSlide);
		}
	});

	function initProgressSlider($slideItems, $slidesToShow) {
		$('.progress').slider({
			min: 0,
			max: ($slideItems - $slidesToShow),
			slide: function (event, ui) {
				var slick = $('.product-listing-1x4 .search-result-items').slick('getSlick');
				var goTo = ui.value * slick.slideCount / $slideItems;
				$('.product-listing-1x4 .search-result-items').slick('goTo', goTo);
			}
		});
	}

	initProgressSlider($slideItems, $slidesToShow);

	$(window).on('resize', function () {
		if (window.innerWidth < 768) {
			$slidesToShow = 2;
			$slideItems = $('.product-listing-1x4 .search-result-items').find('.grid-tile').length;
			if (!$('.progress').hasClass('mobile')) {
				var slick = $('.product-listing-1x4 .search-result-items').slick('getSlick');
				$(".progress").slider("option", "value", 0);
				$('.product-listing-1x4 .search-result-items').slick('goTo', 0);
				$('.progress').slider('destroy');
				$('.progress').addClass('mobile').removeClass('desktop');
				initProgressSlider($slideItems, $slidesToShow);
				$(".progress").slider("option", "value", 0);
			}
		}
		if (window.innerWidth > 767) {
			$slidesToShow = 4;
			$slideItems = $('.product-listing-1x4 .search-result-items').find('.grid-tile').length;
			if (!$('.progress').hasClass('desktop')) {
				var slick = $('.product-listing-1x4 .search-result-items').slick('getSlick');
				$(".progress").slider("option", "value", 0);
				$('.product-listing-1x4 .search-result-items').slick('goTo', 0);
				$('.progress').slider('destroy');
				$('.progress').addClass('desktop').removeClass('mobile');
				initProgressSlider($slideItems, $slidesToShow);
				$(".progress").slider("option", "value", 0);
			}
		}
    })
	// bind show more functionality
	showMoreSwatchPDP();
})();

function actionCarouselInitialize () {
	var $actionCarousel = $('.action-gallery .action-gallery-list');
	// do not initialize twice
	if ($actionCarousel.hasClass('slick-slider')) {
		return;
	}
	var $slideItems = $actionCarousel.find('.action-gallery-item').length;
	var $slidesToShow;
	if (window.innerWidth < 880) {
		$slidesToShow = 1;
		$('.progress').addClass('mobile').removeClass('desktop');
	}
	if (window.innerWidth > 879) {
		$slidesToShow = 2;
		$('.progress').addClass('desktop').removeClass('mobile');
	}

	if ($slideItems > 1) {
		$actionCarousel.slick({
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
		});

		$('.action-gallery .action-gallery-list').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
			if (nextSlide >= currentSlide && nextSlide != (slick.slideCount - 1)) {
				$('.action-progress').slider('option', 'value', (currentSlide + 1));
			} else {
				$('.action-progress').slider('option', 'value', nextSlide);
			}
		});
		$('.action-gallery .action-gallery-list').on('afterChange', function (event, slick, currentSlide) {
			if (currentSlide !== $('.action-progress').slider("value")) {
				$('.action-progress').slider('option', 'value', currentSlide);
			}
		});

		function initProgressSlider($slideItems, $slidesToShow) {
			$('.action-progress').slider({
				min: 0,
				max: ($slideItems - $slidesToShow),
				slide: function (event, ui) {
					var slick = $('.action-gallery .action-gallery-list').slick('getSlick');
					var goTo = ui.value * slick.slideCount / $slideItems;
					$('.action-gallery .action-gallery-list').slick('goTo', goTo);
				}
			});
		}

		initProgressSlider($slideItems, $slidesToShow);

		$(window).on('resize', function () {
            $slideItems = $('.action-gallery .action-gallery-list').find('.action-gallery-item:not(.slick-cloned)').length;
			if (window.innerWidth < 880) {
				$slidesToShow = 1;
			} else {
				$slidesToShow = 2;
			}
            initProgressSlider($slideItems, $slidesToShow);
		});
	}
};

function swatchImageSwap() {
    var bgSwatch = $('ul.swatches .selected .swatchanchor').data('imageswatch');
    if (!!bgSwatch) {
        $('.fab-img').attr('src', bgSwatch);
    }
}

function swatchPreview() {
    $('#swatch-preview').html('').removeClass('diamond-wrap');
    var $swatchClone = $('.embroidery-image').clone();
    var customEmbText2 = $('#customizedConfiguration').attr('text2');
    if ($swatchClone) {
        $swatchClone.appendTo('#swatch-preview');
        if ($('.embdiamond.emb-selected').length) {
            $('#swatch-preview, .product-col-2').addClass('diamond-wrap');
        } else {
            $('#swatch-preview, .product-col-2').removeClass('diamond-wrap');
        }
        $('.product-col-2').addClass('has-embroidery');
        if (!customEmbText2) {
            $('.embroidery-image').removeClass('emb-2lines line2-only');
        } else {
            $('.embroidery-image').addClass('emb-2lines');
        }
        $('.emb-overlay.emb-selected, #swatch-preview').show();
    }
}

function initPRCaro() {
    if ($('#pr-review-display').length) {
        $('#pr-review-display').slick({
            dots: false,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            slide: '.pr-review',
            responsive: [{
                breakpoint: 767,
                settings: {
                    slidesToShow: 1
                }
            }]
        });
    }

    $('#pr-review-display .slick-list').wrapAll("<div class='reviews-carousel'></div>").empty();
    $('#pr-review-display').wrapAll("<div class='reviews-wrapper'></div>");
}

function openCustomizableDialog() {
    $(".modal-embroidery").dialog({
        closeOnEscape: false,
        dialogClass: 'ui-modal-config',
        fluid: true,
        modal: true,
        width: 'auto', // overcomes width:'auto' and maxWidth bug
        open: function () {
            embroidery.embroideryInit();
            embroidery.embroideryActions();
        },
        close: function () {
            $('#embroiderytext').val('');
            $('#embroiderytext2').val('');
            $('.emb-overlay, #embroideryFilteringError').hide();
            if (!$('#customizedConfiguration').length) {
                $('#personalize').prop('checked', false);
            } else {
                var customEmbText = $('#customizedConfiguration').attr('text');
                var customEmbText2 = $('#customizedConfiguration').attr('text2');
                $('.embscript, .embarial').text(customEmbText);
                $('.embscript2, .embarial2').text(customEmbText2);
                $('#personalize').prop('checked', true);
                swatchPreview();
            }
            $(this).dialog('destroy');
        }
    });
}

/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents () {
	var $pdpMain = $('#pdpMain');
	//scrollpane parts
	var scrollPane = $(".scroll-pane"),
	scrollContent = $(".scroll-content");
    var bgSwatch = $('ul.swatches .selected .swatchanchor').data('imageswatch');

	initProductTopData();
    addToCart();
	customize();
	availability();

	if ($pdpMain.find('.product-detail.product-set').length > 0) {
		productSet();
	} else {
		variant();
	}
    if (!$('#customizedConfiguration').length) {
        $('#personalize').prop('checked', false);
        $('#product-col-2').removeClass('has-embroidery');
    } else {
        $('#personalize').prop('checked', true);
        $('#product-col-2').addClass('has-embroidery');
    }
	image();
	sizeguide.init();
    $('.fab-img').attr('src', bgSwatch);

    if ($('#pdp-promo-msg.promo-notempty').length) {
        $('.promotion').addClass('promotion-notempty');
    }

    // Init embroidery option in modal with existing of ProductLineItem on PDP in "Edit" mode
    if (window.IsEmbroideryUpdate) {
        embroidery.embroideryInit();
    }

	if (SitePreferences.STORE_PICKUP) {
		productStoreInventory.init();
	}

	// Add to Wishlist and Add to Gift Registry links behaviors
	$pdpMain.on('click', '[data-action="wishlist"], [data-action="gift-registry"]', function () {
		var data = util.getQueryStringParams($('.pdpForm').serialize());
		if (data.cartAction) {
			delete data.cartAction;
		}
		var url = util.appendParamsToUrl(this.href, data);
		this.setAttribute('href', url);
	});

	// product options
	$pdpMain.on('change', '.product-options select', function () {
		var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
		var selectedItem = $(this).children().filter(':selected').first();
		salesPrice.text(selectedItem.data('combined'));
	});

	// product details toggle
	$pdpMain.on('click', '.pdp-expand .tlt-asset-grid', function () {
		$(this).parent().toggleClass('expanded');
	});

	// prevent default behavior of thumbnail link and add this Button
	$pdpMain.on('click', '.thumbnail-link, .unselectable a', function (e) {
		e.preventDefault();
	});
	$pdpMain.on('click', '.pr-snippet-stars, .pr-snippet-review-count', function (e) {
		e.preventDefault();
		$('html, body').animate({
			scrollTop: $('.reviews-container').offset().top
		}, 1000);
	});

	$pdpMain.on('click', '#egcShowExamples', function (e) {
		e.preventDefault();
		dialog.open({
			url: $(e.target).attr('href'),
			options: {
				width: 652
			}
		});
	});

	$(document).on('click', '#configurator-cta', function (e) {
		swatchImageSwap();
		if ($('#editEmbroidery').is(":visible")) {
			$("#editEmbroidery").trigger('click');
		} else if ($('#configurator-cta').hasClass('commerce-widget')) {
			CustomizerWidget.createWidget(embroidery.preparePayload(false));
		} else {
			openCustomizableDialog();
		}
	});

	$(document).on('click', '#editEmbroidery, .checkedAddEmbroidery', function (e) {
		e.preventDefault();
		if ($('#configurator-cta').hasClass('commerce-widget')) {
			CustomizerWidget.createWidget(embroidery.preparePayload(true));
		} else if ($('#customizedConfiguration').length > 0) {
			var styleOption = $('#customizedConfiguration').attr('styleOption');
			var threadColor = $('#customizedConfiguration').attr('threadColor');
			var text = $('#customizedConfiguration').attr('text');
			var text2 = $('#customizedConfiguration').attr('text2');

			var styleOptionElement = '.embroidery-options input#' + styleOption;
			$(styleOptionElement).trigger('click');
			$('.option-color select#embroiderycolor').val(threadColor);
			$('.option-text input#embroiderytext').val(text);
			$('.option-text input#embroiderytext2').val(text2);

			openCustomizableDialog();
		}
	});

	$(window).on('scroll', function () {
		stickyElementsInit();
	});

    $(window).on('load', function () {
        setTimeout(function () {
            // set up carousel
            $('#pr-review-display').on('init', function () {
                var $reviews = $('#reviews');
                // add read full review link if review is longer than six lines
                setTimeout(function () {
                    var $viewall = $('.product-top-data').data('viewall');

                    $('.pr-rd-review-total').after('<a href="javascript:" id="viewAll" class="view-all-reviews">' + $viewall + '</a>');

                    $reviews.find('.pr-rd-description-text').each(function () {
                        var $reviewText = $(this);
                        var sixLines = $reviewText.css('lineHeight').replace(/[^-\d\.]/g, '') * 6;
                        var readMore = $('.product-top-data').data('readmore');

                        if ($reviewText.height() > sixLines) {
                            $reviewText.addClass('truncated');
                            $reviewText.append('<span class="read-full-review">... <a href="javascript:">' + readMore + '</a></span>');
                        }
                    });

                    // open view all reviews modal and scroll to review on read full review link click
                    $('.read-full-review, .view-all-reviews').click(function () {
                        var searchTxt = $('.product-top-data').data('searchtxt');
                        var reviewsTxt = $('.product-top-data').data('reviewstxt');
                        // add one because the filters header offsets the index
                        window.reviewIndex = $(this).closest('.pr-review').index();

                        // move Q&A section
                        $('#answers').detach().insertAfter($('#modalQuestionsTab').find('.reviews-header'));

                        // render reviews modal
                        $('.reviews-modal').dialog({
                            dialogClass: 'reviews-dialog',
                            draggable: false,
                            position: 'center',
                            close: function () {
                                // reinitialize main pdp reviews and Q&A
                                //$('#answers').detach().insertAfter($('#pdpQuestionsTab').find('.reviews-header'));
                                //window.pwr = window.pwr || function () {
                                    //(pwr.q = pwr.q || []).push(arguments);
                                //};
                                //pwr('render', PRConfig);
                                $('.reviews-modal').dialog('destroy');
                                if ($('#pr-review-display.slick-initialized').length) {
                                    // reinit slick
                                    $('#pr-review-display').slick('refresh');
                                }
                                $('#pr-review-display .slick-list').empty();
                            },
                            modal: true,
                            resizable: false
                        });
                        // $('.reviews-modal').tabs();

                        // initialize modal reviews display
                        var modalConfig = Object.assign({}, PRConfig);
                        modalConfig.components = {ReviewDisplay: 'modal-reviews'};
                        modalConfig.on_render = function (config, data) {
                        console.log(config, data);
                            if (config.component === "ReviewList") {
                                var $modalReviews = $('#modal-reviews');
                                initReviewSnapshot($modalReviews, data.review_count);

                                $modalReviews.find('.pr-rd-search-reviews-input input').attr('placeholder', searchTxt);
                                $modalReviews.find('.pr-rd-review-total').text(data.review_count + reviewsTxt)

                                // move more details into review side content
                                $modalReviews.find('.pr-review').each(function () {
                                    var $review = $(this);
                                    var $moreDetails = $review.find('.pr-accordion');
                                    if ($review.find('.pr-rd-review-disclosure').length > 0) {
                                        $moreDetails.insertBefore($review.find('.pr-rd-review-disclosure'));
                                    } else {
                                        $review.find('.pr-rd-side-content-block').append($moreDetails);
                                    }
                                });

                                // add reviews count to modal
                                var $revCount = $('.pr-snippet-review-count').html();
                                $modalReviews.closest('.product-tabs').find('.reviews-tab-header').html(reviewsTxt + $revCount);

                                setTimeout(function () {
                                    // scroll review into view if user clicked read full review link
                                    if (window.reviewIndex > -1) {
                                        $modalReviews.find('.pr-review').get(window.reviewIndex).scrollIntoView();
                                        //window.reviewIndex = -1;
                                    }
                                }, 500);

                            }
                        };
                        window.pwr = window.pwr || function () {
                            (pwr.q = pwr.q || []).push(arguments);
                        };
                        pwr('render', modalConfig);
                    });

                    if ($reviews.find('.p-w-r').length > 1) {
                        $("#reviews .p-w-r:not(:first)").remove();
                    }

                    if ($('#answers').find('.p-w-r').length > 1) {
                        $('#answers').find('.p-w-r:not(:first-child)').remove();
                    }
                }, 0);
            });
            initPRCaro();
        }, 1500);
	});

	util.smartResize(function () {
		if (util.getViewport() < 880 && $('#pdpMain .product-col-1.sticky-image').length) {
			$('#pdpMain .product-col-1').removeClass('sticky-image');
		}
        initProductTopData();
	});

	$('#free-returns').on('click', function (e) {
		e.preventDefault();
		dialog.open({
			url: $(e.target).attr('href')
		});
	});
	$('.pdpLeftCarousel ul').slick();
	$('.pdpPlayers ul').slick({
		infinite: true,
		slidesToShow: 5,
		slidesToScroll: 5,
		arrows: true,
		responsive: [
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			}
		]
	});
	$('.pdp-piu .content-asset').slick({
		infinite: true,
		slidesToShow: 2,
		slidesToScroll: 2,
		centerMode: true,
		centerPadding: '15%',
		arrows: true,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerPadding: '40px'
				}
			}
		]
	});
	showMoreSwatchPDP();
	if (!$('#pdpMain').closest("#QuickViewDialog").length) {
		var searchPage = $('.pt_product-search-result');
		if (searchPage.length === 0) {
			$('html, body').animate({scrollTop: 0}, 'slow');
		}
	}

	if (window.location.hash == '#overview-anchor') {
		$('html, body').animate({scrollTop: $('#overview-anchor').offset().top - 100}, 'slow');
		return;
	}
	imagesLoaded('.action-gallery-list').on('always', function () {
		actionCarouselInitialize();
    });

	$('body').on('click', '#add-to-cart-sticky', function () {
		$('#add-to-cart').trigger('click');
	});

    var a = $('<a>').prop('href', window.location);
    var queryString = a.prop('search').substring(1);
    var queryParams = {};
    $.each(queryString.split('&'), function(_, param) {
        var parts = param.split('=');
        if (parts[0]) {
            queryParams[parts[0]] = decodeURIComponent(parts[1]);
        }
    });
    if (queryParams['source'] === 'cart' && queryParams['uuid'] && queryParams['custom'] === 'true') {
		$("#editEmbroidery").trigger('click');

		// Remove visually-hidden class after loading DC Customizer widget.
		// NOTE: This class is applied via the pt_productdetails template.
		$(document).ready(function() {
			var checkWidget = setInterval(function() {
				if ($('.cz3').length) {
					if ($('#wrapper').hasClass('visually-hidden')) {
						$('#wrapper').removeClass('visually-hidden');
					}
					clearInterval(checkWidget);
				}
			}, 100);
		});
    }
}

var product = {
	initializeEvents: initializeEvents,
	init: function () {
		initializeDom();
		initializeEvents();
	}
};

module.exports = product;

